import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';
import { getProductRequests } from '../../store/actions/products';
import MaterialTable from 'material-table';
import { STATUS_PENDING } from '../../common/constants';
import { formatDate, accessProductCatalog } from './../../common/utils/utility';

const MyItemRequests = (props) => {
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [productRequests, setProductRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageValue, setPageValue] = useState({
        offset: 0,
        perPage: 6,
        currentPage: 0,
        pageCount: 0
    });
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        setPageValue({
            ...pageValue,
            pageCount
        })
    }, [pageCount]);

    const getRequestsList = () => {
        props.getProductRequestsStart();
        getProductRequests(userInfo.supplierId).then(response => {
            setIsLoading(false);
            setProductRequests(response.data);
            props.getProductRequestsSuccess(response.data);
            setPageCount(Math.ceil(response.data?.length / pageValue.perPage));
        }).catch((err) => {
            const errMsg = 'Something went wrong';
            props.getProductRequestsFail(err?.response?.data?.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        });
    };

    useEffect(() => {
        if (userInfo) {
            getRequestsList();
        }
    }, [userInfo]);

    const renderRows = () => {
        return (
            <React.Fragment>
                <MaterialTable
                    columns={[
                        { title: 'Product Name', field: 'storeDisplayName' },
                        { title: 'Status', field: 'status' },
                        { title: 'Requested By', field: 'createdBy' },
                        {
                            title: 'Date Requested', field: 'createdTimestamp',
                            render: row => <span>{formatDate(row["createdTimestamp"])}</span>
                        },
                    ]}
                    data={productRequests}
                    onRowClick={(event, rowData) => { props.history.push('/home/itemRequest/' + rowData.productRequestId, rowData) }}
                    options={{
                        filtering: false,
                        actionsColumnIndex: -1,
                        search: false,
                        paging: true,
                        toolbar: false,
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: 'gray',
                            color: '#FFF'
                        },
                    }}
                    isLoading={isLoading}
                    localization={{ body: { emptyDataSourceMessage: ""} }}
                />

            </React.Fragment>
        );       
    }

    return (
        <Container>
            {accessProductCatalog(userInfo)}
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Change Requests</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    {renderRows()}
                </Col>
            </Row>
        </Container>
    )
};

const mapStateToProps = state => {
    return {
        userInfo: state.auth.userInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProductRequests: (supplierId) => dispatch(actions.getProductRequests(supplierId)),
        getProductRequestsStart: () => dispatch(actions.getProductRequestsStart()),
        getProductRequestsSuccess: (data) => dispatch(actions.getProductRequestsSuccess(data)),
        getProductRequestsFail: () => dispatch(actions.getProductRequestsFail()),
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyItemRequests);