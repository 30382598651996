export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const RECOVER_PASSWORD_START = 'RECOVER_PASSWORD_START';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAIL = 'RECOVER_PASSWORD_FAIL';

export const PRODUCT_DETAILS_START = 'PRODUCT_DETAILS_START';
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export const GET_PRODUCTS_START = 'GET_PRODUCTS_START';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';

export const USER_ACCOUNT_DETAILS_START = 'USER_ACCOUNT_DETAILS_START';
export const USER_ACCOUNT_DETAILS_SUCCESS = 'USER_ACCOUNT_DETAILS_SUCCESS';
export const USER_ACCOUNT_DETAILS_FAIL = 'USER_ACCOUNT_DETAILS_FAIL';
export const AUTH_UPDATE_USERINFO = 'AUTH_UPDATE_USERINFO';

export const GET_PRODUCTS_REQUEST_START = 'GET_PRODUCTS_REQUEST_START';
export const GET_PRODUCTS_REQUEST_SUCCESS = 'GET_PRODUCTS_REQUEST_SUCCESS';
export const GET_PRODUCTS_REQUEST_FAIL = 'GET_PRODUCTS_REQUEST_FAIL';

export const GET_PURCHASE_ORDERS_START = 'GET_PURCHASE_ORDERS_START';
export const GET_PURCHASE_ORDERS_SUCCESS = 'GET_PURCHASE_ORDERS_SUCCESS';
export const GET_PURCHASE_ORDERS_FAIL = 'GET_PURCHASE_ORDERS_FAIL';

export const GET_PURCHASE_ORDER_DETAIL_START = 'GET_PURCHASE_ORDER_DETAIL_START';
export const GET_PURCHASE_ORDER_DETAIL_SUCCESS = 'GET_PURCHASE_ORDER_DETAIL_SUCCESS';
export const GET_PURCHASE_ORDER_DETAIL_FAIL = 'GET_PURCHASE_ORDER_DETAIL_FAIL';

export const PURCHASE_ORDERS_START = 'PURCHASE_ORDERS_START';
export const PURCHASE_ORDERS_SUCCESS = 'PURCHASE_ORDERS_SUCCESS';
export const PURCHASE_ORDERS_FAIL = 'PURCHASE_ORDERS_FAIL';

export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const GET_MASTERDATA_START = 'GET_MASTERDATA_START';
export const GET_MASTERDATA_SUCCESS = 'GET_MASTERDATA_SUCCESS';
export const GET_MASTERDATA_FAIL = 'GET_MASTERDATA_FAIL';

export const UPDATE_MASTERDATA_START = 'UPDATE_MASTERDATA_START';
export const UPDATE_MASTERDATA_SUCCESS = 'UPDATE_MASTERDATA_SUCCESS';
export const UPDATE_MASTERDATA_FAIL = 'UPDATE_MASTERDATA_FAIL';

export const UPLOAD_FILE_START = 'UPLOAD_FILE_START';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

export const GET_DOCUMENTS_BY_PRODUCT_START = 'GET_DOCUMENTS_BY_PRODUCT_START';
export const GET_DOCUMENTS_BY_PRODUCT_SUCCESS = 'GET_DOCUMENTS_BY_PRODUCT_SUCCESS';
export const GET_DOCUMENTS_BY_PRODUCT_FAIL = 'GET_DOCUMENTS_BY_PRODUCT_FAIL';

export const GET_POWER_BI_EMBED_START = 'GET_POWER_BI_EMBED_START';
export const GET_POWER_BI_EMBED_SUCCESS = 'GET_POWER_BI_EMBED_SUCCESS';
export const GET_POWER_BI_EMBED_FAIL = 'GET_POWER_BI_EMBED_FAIL';

/*admin*/
export const GET_PENDING_SUPPLIER_START = 'GET_PENDING_SUPPLIER_START';
export const GET_PENDING_SUPPLIER_SUCCESS = 'GET_PENDING_SUPPLIER_SUCCESS';
export const GET_PENDING_SUPPLIER_FAIL = 'GET_PENDING_SUPPLIER_FAIL';

export const GET_SUPPLIERS_START = 'GET_SUPPLIERS_START';
export const GET_SUPPLIERS_SUCCESS = 'GET_SUPPLIERS_SUCCESS';
export const GET_SUPPLIERS_FAIL = 'GET_SUPPLIERS_FAIL';

export const GET_ALLSUPPLIERS_START = 'GET_ALLSUPPLIERS_START';
export const GET_ALLSUPPLIERS_SUCCESS = 'GET_ALLSUPPLIERS_SUCCESS';
export const GET_ALLSUPPLIERS_FAIL = 'GET_ALLSUPPLIERS_FAIL';

export const UPDATE_PENDING_SUPPLIER_START = 'UPDATE_PENDING_SUPPLIER_START';
export const UPDATE_PENDING_SUPPLIER_SUCCESS = 'UPDATE_PENDING_SUPPLIER_SUCCESS';
export const UPDATE_PENDING_SUPPLIER_FAIL = 'UPDATE_PENDING_SUPPLIER_FAIL';

export const GET_ALL_PRODUCTS_REQUEST_START = 'GET_ALL_PRODUCTS_REQUEST_START';
export const GET_ALL_PRODUCTS_REQUEST_SUCCESS = 'GET_ALL_PRODUCTS_REQUEST_SUCCESS';
export const GET_ALL_PRODUCTS_REQUEST_FAIL = 'GET_ALL_PRODUCTS_REQUEST_FAIL';

export const GET_USER_ROLE_START = 'GET_USER_ROLE_START';
export const GET_USER_ROLE_SUCCESS = 'GET_USER_ROLE_SUCCESS';
export const GET_USER_ROLE_FAIL = 'GET_USER_ROLE_FAIL';

export const EDIT_USER_ACCOUNT_START = 'EDIT_USER_ACCOUNT_START';
export const EDIT_USER_ACCOUNT_SUCCESS = 'EDIT_USER_ACCOUNT_SUCCESS';
export const EDIT_USER_ACCOUNT_FAIL = 'EDIT_USER_ACCOUNT_FAIL';

export const DELETE_USER_ACCOUNT_START = 'DELETE_USER_ACCOUNT_START';
export const DELETE_USER_ACCOUNT_SUCCESS = 'DELETE_USER_ACCOUNT_SUCCESS';
export const DELETE_USER_ACCOUNT_FAIL = 'DELETE_USER_ACCOUNT_FAIL';

export const EDIT_PRODUCT_START = 'EDIT_PRODUCT_START';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_FAIL = 'EDIT_PRODUCT_FAIL';

export const GET_ALLDOCUMENT_START = 'GET_ALLDOCUMENT_START';
export const GET_ALLDOCUMENT_SUCCESS = 'GET_ALLDOCUMENT_SUCCESS';
export const GET_ALLDOCUMENT_FAIL = 'GET_ALLDOCUMENT_FAIL';

export const GET_SUPPLIERDOCUMENT_START = 'GET_SUPPLIERDOCUMENT_START';
export const GET_SUPPLIERDOCUMENT_SUCCESS = 'GET_SUPPLIERDOCUMENT_SUCCESS';
export const GET_SUPPLIERDOCUMENT_FAIL = 'GET_SUPPLIERDOCUMENT_FAIL';

export const GET_PDFDOCUMENT_START = 'GET_PDFDOCUMENT_START';
export const GET_PDFDOCUMENT_SUCCESS = 'GET_PDFDOCUMENT_SUCCESS';
export const GET_PDFDOCUMENT_FAIL = 'GET_PDFDOCUMENT_FAIL';