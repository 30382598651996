import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Ratio } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';
import { getPowerBIEmbed } from '../../store/actions/powerBI';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { accessInventory} from '../../common/utils/utility';

const Inventory = (props) => {
    const [powerBIParams, setPowerBIParams] = useState([]);
    const [powerBINav, setPowerBINav] = useState([]);
    const userInfo = useSelector((state) => state.auth.userInfo);

    useEffect(function effectFunction() {
        if (!powerBIParams.length && userInfo) {
            var embedParams = {
                workSpaceId: "4e447d57-7ed8-4040-a8ff-573537ddf6b2",
                reportId: "TBD",
                roleName: "Vendor",
                rlsId: userInfo.email
            };
            actions.getPowerBIEmbedStart();
            getPowerBIEmbed(embedParams.workSpaceId, embedParams.reportId, embedParams.roleName, embedParams.rlsId).then(response => {
                setPowerBIParams({
                    id: embedParams.reportId,
                    type: "report",
                    embedUrl: response.data.EmbedReport[0].EmbedUrl,
                    accessToken: response.data.EmbedToken.Token,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        background: models.BackgroundType.Transparent,
                        panes: {
                            bookmarks: {
                                visible: false
                            },
                            fields: {
                                expanded: false
                            },
                            filters: {
                                expanded: false,
                                visible: false
                            },
                            pageNavigation: {
                                visible: false
                            },
                            selection: {
                                visible: true
                            },
                            syncSlicers: {
                                visible: true
                            },
                            visualizations: {
                                expanded: false
                            }
                        },
                        bars: {
                            actionBar: {
                                visible: false
                            },
                            statusBar: {
                                visible: false
                            }
                        }
                    }
                });
            }).catch((err) => {
                const errMsg = 'Something went wrong';
                actions.getPowerBIEmbedFail(err?.response?.data?.error);
                props.showMessage(err?.response?.data?.error || errMsg);
            });
        }
    }, userInfo);

    function renderReport() {
        if (powerBIParams.id) {
            return (
                <PowerBIEmbed
                    embedConfig={powerBIParams}
                    eventHandlers={
                        new Map([
                            ['loaded', function () {
                            }],
                            ['rendered', async function () {
                                try {
                                    let reportPages = await window.report.getPages();
                                    setPowerBINav(reportPages);
                                }
                                catch (e) {
                                    console.log(e);
                                }
                            }],
                            ['pageChanged', function () {
                            }],
                            ['error', function (event) { console.log(event.detail); }]
                        ])
                    }
                    cssClassName={"powerBIReportEmbed embed-responsive embed-responsive-16by9"}
                    getEmbeddedComponent={(embeddedReport) => {
                        window.report = embeddedReport;
                        }
                    }
                />
            );
        } else {
            return (<div className="powerBIReportEmbed"></div>);
        }
    }

    return (
        <Container>
            {accessInventory(userInfo)}
            <Row className="page-content-container">
                <Col className="page-content-container-inner" sm={12} md={12} lg={12}>
                    <Row>
                        <Col>
                            <Row className="powerbi-nav-container">
                                {powerBINav.map((page, index) => {
                                    return (
                                        <Col key={index} className={`powerbi-nav-col ${page.isActive ? "powerbi-nav-col-active" : ""}`} onClick={() => { if (!page.isActive) { window.report.setPage(page.name) } }}>
                                                <span className="powerbi-nav-item" >{page.displayName}</span>
                                        </Col>
                                    );
                                })}
                            </Row>
                            {renderReport()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        userInfo: state.auth.userInfo,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getPowerBIEmbed: (supplierId) => dispatch(actions.getPowerBIEmbed(supplierId)),
        getPowerBIEmbedStart: () => dispatch(actions.getPowerBIEmbedStart()),
        getPowerBIEmbedSuccess: (data) => dispatch(actions.getPowerBIEmbedSuccess(data)),
        getPowerBIEmbedFail: () => dispatch(actions.getPowerBIEmbedFail()),
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Inventory);