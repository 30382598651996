import "../../App.css";
import React, { useEffect, useState } from "react";
import { Route, NavLink, BrowserRouter, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Navbar, Nav, NavDropdown, Container, Row, Col } from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import ProductCatalog from "../productCatalog/productCatalog";
import AccountInfo from "../accountInfo/accountInfo";
import Documentation from "../documentation/documentation";
import InsightEngine from "../DataAndInsights/InsightEngine";
import SalesOut from "../DataAndInsights/SalesOut";
import Inventory from "../DataAndInsights/Inventory";
import TerritoryManagement from "../territoryManagement/TerritoryManagement";
import PurchaseOrderManagement from "../purchaseOrderManagement/purchaseOrderManagement";
import PurchaseOrderDetails from "../purchaseOrderDetails/purchaseOrderDetails";
import ProductsSummary from "../productsSummary/productsSummary";
import ProductDetails from "../productDetails/productDetails";
import MyItemRequests from "../myItemRequests/myItemRequests";
import RequestDetails from "../requestDetails/requestDetails";
import eStore from "../eStore/eStore";
import SalesRepMap from "../Directory/SalesRepMap";
import SalesRepDirectory from "../Directory/SalesRepDirectory";

function Home(props) {

    const userInfo = useSelector((state) => state.auth.userInfo);
    const origURL = props.originalURL;

    useEffect(() => {
        if (userInfo) {
            if (userInfo.accessProductCatalog) {
                props.getProducts(userInfo.supplierId);
                props.getMasterData();
            }
                //props.getNotifications(userInfo.supplierId);
        }
    }, [userInfo]);

    function useScrollDirection() {
        const [scrollDirection, setScrollDirection] = useState(null);

        useEffect(() => {
            let lastScrollY = window.pageYOffset;

            const updateScrollDirection = () => {
                const scrollY = window.pageYOffset;
                const direction = scrollY > lastScrollY ? "down" : "up";
                if (direction !== scrollDirection && (scrollY - lastScrollY > 3 || scrollY - lastScrollY < -3)) {
                    setScrollDirection(direction);
                }
                lastScrollY = scrollY > 0 ? scrollY : 0;
            };
            window.addEventListener("scroll", updateScrollDirection); // add event listener
            return () => {
                window.removeEventListener("scroll", updateScrollDirection); // clean up
            }
        }, [scrollDirection]);

        return scrollDirection;
    };

    function Header() {
        const scrollDirection = useScrollDirection();

        return (
            <Navbar bg="light" className={`top-nav ${scrollDirection === "down" ? "nav-hide" : "nav-show"}`} sticky="top" collapseOnSelect expand="custom">
                <div className="top-nav-top">
                    <Navbar.Brand href="/home">
                        <br />
                    </Navbar.Brand>
                    <Nav className="mr-auto mobile-hide">
                        <Nav className="site-title">Supplier Portal</Nav>
                    </Nav>
                    <Navbar.Toggle />
                    <NavDropdown className="supplier-name mobile-hide" title={userInfo?.fullName}>
                        <NavDropdown.Item href="/home/accountInfo">ACCOUNT INFORMATION</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => {props.onLogout();}}>SIGN OUT</NavDropdown.Item>
                    </NavDropdown>
                </div>
                <Navbar.Collapse variant="dark" className="bottom-nav">
                    <Nav>
                        {userInfo?.accessProductCatalog &&
                            <NavDropdown title="PRODUCTS" bg="dark">
                                <NavDropdown.Item href="/home/catalog">PRODUCT CATALOG</NavDropdown.Item>
                                <NavDropdown.Item href="/home/myItemRequests">CHANGE REQUESTS</NavDropdown.Item>
                            </NavDropdown>
                        }
                        <Nav.Link as={NavLink} to="/home/documentation">DOCUMENTATION</Nav.Link>
                        {userInfo?.accessTerritoryManagement &&
                            <Nav.Link as={NavLink} to="/home/TerritoryManagement">TERRITORY MANAGEMENT</Nav.Link>
                        }
                        {userInfo?.accessPurchaseOrderManagement &&
                            <Nav.Link as={NavLink} to="/home/purchaseOrderManagement">ORDER MANAGEMENT</Nav.Link>
                        }
                        {(userInfo?.accessSupplierAnalytics || userInfo?.accessSalesOut || userInfo?.accessInventory) &&
                            <NavDropdown title="REPORTING" bg="dark">
                                {userInfo?.accessSupplierAnalytics &&
                                    <NavDropdown.Item href="/home/InsightEngine" target="_blank">INSIGHT ENGINE</NavDropdown.Item>
                                }
                                {userInfo?.accessSalesOut &&
                                    <NavDropdown.Item href="/home/SalesOut" target="_blank">SALES OUT</NavDropdown.Item>
                                }
                                {userInfo?.accessInventory &&
                                    <NavDropdown.Item href="/home/Inventory" target="_blank">INVENTORY</NavDropdown.Item>
                                }
                            </NavDropdown>
                        }
                        <NavDropdown title="DIRECTORY" bg="dark">
                            <NavDropdown.Item href="/home/SalesRepDirectory">SALES REP DIRECTORY</NavDropdown.Item>
                            <NavDropdown.Item href="/home/SalesRepMap">SALES REP MAP</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="ACCOUNT" className="desktop-hide">
                            <NavDropdown.Item href="/home/accountInfo">ACCOUNT INFORMATION</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => { props.onLogout(); }}>SIGN OUT</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    };

    return (
        <BrowserRouter>
            {props.location.pathname != '/home/InsightEngine' && props.location.pathname != '/home/SalesOut' && props.location.pathname != '/home/Inventory' && Header()}
            <div className={props.location.pathname.replace('/home/', '').replace('/','') + " content-container-outer"}>
                <div className="content-container">
                    <Route exact path="/home" component={ProductsSummary} />
                    <Route path="/home/e-store/:id" component={eStore} />
                    <Route path="/home/product/:id" component={ProductDetails} />
                    <Route path="/home/catalog" component={ProductCatalog} />
                    <Route path="/home/myItemRequests" component={MyItemRequests} />
                    <Route path="/home/itemRequest/:id" component={RequestDetails} />
                    <Route path="/home/accountInfo" component={AccountInfo} />
                    <Route path="/home/documentation" component={Documentation} />
                    <Route path="/home/InsightEngine" component={InsightEngine} />
                    <Route path="/home/SalesOut" component={SalesOut} />
                    <Route path="/home/Inventory" component={Inventory} />
                    <Route path="/home/TerritoryManagement" component={TerritoryManagement} />
                    <Route path="/home/purchaseOrderManagement" component={PurchaseOrderManagement} />
                    <Route path="/home/purchaseOrderDetail/:purchaseOrderID" component={PurchaseOrderDetails} />
                    <Route path="/home/SalesRepMap" component={SalesRepMap} />
                    <Route path="/home/SalesRepDirectory" component={SalesRepDirectory} />
                </div>
            </div>
        </BrowserRouter>
    );
}

const mapStateToProps = state => {
    return {
        userInfo: state.auth.userInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout()),
        getProducts: (supplierId) => dispatch(actions.getProducts(supplierId)),
        //getNotifications: (supplierId) => dispatch(actions.getNotifications(supplierId)),
        getMasterData: () => dispatch(actions.getMasterData())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Home));