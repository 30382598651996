import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image} from 'react-bootstrap';
import EStoreImage from "../../assets/images/PES.PNG";
import ButtonCustom from '../../common/components/ui/button/button';
import { useSelector, connect } from 'react-redux';
import { CONTAINER_DATA, DOCUMENT_SDS, DOCUMENT_SPEC, IMAGE_BASE } from '../../common/constants';
import { pdfDocument } from '../../store/actions/admin';
import * as actions from '../../store/actions/index';
import { getDocumentsByProduct } from '../../store/actions/products';
import { accessProductCatalog } from './../../common/utils/utility';

const EStore = (props) => {

    const userInfo = useSelector((state) => state.auth.userInfo);
    const product = props.location.state;
    const [documents, setDocuments] = useState([]);

    const getContainerName = (id) => {
        if (id) {
            return CONTAINER_DATA.find((item) => {
                return item.internalId === Number(id);
            }).name;
        }
        return '';
    }

    const getOnWebStore = (value) => {
        if (value) {
            return 'On-WebStore';
        }
        return 'Off-WebStore';
    }

    useEffect(() => {
        if (product) {
            props.getDocumentsByProductStart();
            getDocumentsByProduct(product.internalId).then(response => {
                setDocuments(response.data);
                props.getDocumentsByProductSuccess();
            }).catch((err) => {
                const errMsg = 'Something went wrong';
                props.getDocumentsByProductFail(err.response.data.error);
                props.showMessage(err?.response?.data?.error || errMsg);
            });
        }
    }, [product]);

    const openPDF = (type) => {
      const id = documents.find(item => item.documentCategory === type)?.documentId;
      if(id) {
        props.getPDFDownloadByIDStart();
        pdfDocument(id).then(response => {
            if (response && response.data.documentImage) {
                const arrayBuffer = base64ToArrayBuffer(response.data.documentImage);
                createAndDownloadBlobFile(arrayBuffer, response.data.documentName);
                props.getPDFDownloadByIDSuccess(response.data);

            } else {
                props.showMessage('No Document Found');
                props.getPDFDownloadByIDSuccess(response.data);
            }
        }).catch((err) => {
            const errMsg = 'Something went wrong';
            props.getPDFDownloadByIDFail(err.response.data.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        });
    }
  }

  const base64ToArrayBuffer = (base64) => {
      const binaryString = window.atob(base64);
      const bytes = new Uint8Array(binaryString.length);
      return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    const createAndDownloadBlobFile = (body, filename) => {
      const blob = new Blob([body]);
      const fileName = `${filename}`;
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }

    let img = "";
    if (product.images.length) {
        img = IMAGE_BASE + "/SSP Applications/NetSuite Inc. - SCA 2019.2/Development/img/Item Images/" + product.images[0].fileName;
    }
    else {
        img = IMAGE_BASE + product.placeholder
    }

    return (
        <Container>
            {accessProductCatalog(userInfo)}
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">eStore Product</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    <Row>
                        <Col className="estore-product-details-image-container" sm={12} md={6} lg={7}>
                            <Image src={img} />
                        </Col>
                        <Col className="estore-product-details" sm={12} md={6} lg={5}>
                            <h3>{product.storeDisplayName}</h3>
                            <br />
                            <p>
                                Item: {product.name}
                                <br />
                                Description: {product.storeDescription}
                                <br />
                                Supplier: {userInfo?.supplierName}
                                <br/>
                                Container Type: {getContainerName(product.containerType)}
                                <br/>
                                Package(lbs): {product.packageSize}
                                                <br />
                                Bags Per Pallet: {product.bagsPerPallet}
                                <br />
                                {getOnWebStore(product.onwebstore)}
                                </p>
                                <p className="e-store-pdf">
                                <span className="cursor-pointer" onClick={() => {openPDF(DOCUMENT_SDS)}}>
                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i> SDS Sheet
                                </span>
                                <br/>
                                <span className="cursor-pointer" onClick={() => {openPDF(DOCUMENT_SPEC)}}>
                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i> Spec Sheet
                                </span>
                                </p>
                                <p className="e-store-amount">
                                <br/>
                                <br/>
                                <b>$00.00</b>/bag
                                <br/>
                                ($0.0000/lb)
                                <br/>
                                </p>
                                <p className="quantity-bag">
                                <b>Quantity (Bag)</b>
                                <br/>
                                <input type = "text" value="1" disabled/>
                                <br/>
                                Total Weight: 50.00 lbs
                                <br/>
                                <ButtonCustom disabled className="secondaryOne button" variant="contained">ADD TO CART</ButtonCustom>
                                <br/>
                                <ButtonCustom disabled className="secondaryOne button" variant="contained">ADD TO QUOTE</ButtonCustom>
                            </p>
                        </Col>
                    </Row>
                </Col>
             </Row>
             <Row className="Product-details-bottom">
                <Col>
                    <ButtonCustom className="button primary" variant="contained" type='submit' onClick={() => { props.history.push('/home/product/' + product.internalId, { data: product, isEdit: false }) }}>
                        Product Details
                    </ButtonCustom>
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = state => {
  return {
      loading: state.auth.loading,
      error: state.auth.error,
  };
};
const mapDispatchToProps = dispatch => {
  return {
      getPDFDownloadByIDStart: () => dispatch(actions.getPDFDownloadByIDStart()),
      getPDFDownloadByIDSuccess: (data) => dispatch(actions.getPDFDownloadByIDSuccess(data)),
      getPDFDownloadByIDFail: () => dispatch(actions.getPDFDownloadByIDFail()),
      showMessage: (msg, type) => dispatch(actions.showMessage(msg, type)),
      getDocumentsByProductStart: () => dispatch(actions.getDocumentsByProductStart()),
      getDocumentsByProductSuccess: () => dispatch(actions.getDocumentsByProductSuccess()),
      getDocumentsByProductFail: () => dispatch(actions.getDocumentsByProductFail())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EStore);

