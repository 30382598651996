// import './App.css'; 
import React, { useEffect, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import login from './containers/login/login';
import Home from './containers/home/home';
import Admin from './containers/admin/admin';
import { connect, useSelector, useDispatch } from 'react-redux';
import * as actions from './store/actions/index';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { AUTH_TOKEN, ADMIN_TOKEN } from './common/constants';
import localStore from './common/services/localstorage.service';
import { Spinner } from 'react-bootstrap';

function App(props) {
  const { onTryAutoSignup } = props;
	useEffect(() => {
		onTryAutoSignup();
	}, [onTryAutoSignup]);

	const dispatch = useDispatch();
	//show Message
	const MSG = useSelector((state) => state.message);
	const isAuthenticated = localStore.get(AUTH_TOKEN);
	const isAdminAuthenticated = localStore.get(ADMIN_TOKEN);

	const location = useLocation();
    const originalURL = location.pathname;
	var redirectPath =
		isAuthenticated || isAdminAuthenticated
			? location.pathname
			: '/login';

	if (redirectPath == '/') {
		redirectPath = '/home';
	} 
	if (props.originalURL) {
		redirectPath = props.originalURL;
	}

	let routes = (
		<> 
			<Switch>
				<Route exact path={['/', '/login']} {...props} originalURL={originalURL} component={login} />
				<Redirect to="/" />
			</Switch>
		</>
	);

	if (isAuthenticated) {
		routes = (
			<Suspense fallback={<p>Loading...</p>}>
				<Switch>
					<Route path="/home" render={props => <Home {...props} />} />
					<Redirect to={redirectPath} />
				</Switch>
			</Suspense>
		);
	}
	else if (isAdminAuthenticated) {
		routes = (
			<Suspense fallback={<p>Loading...</p>}>
				<Switch>
					<Route path="/admin" render={props => <Admin {...props} originalURL={originalURL} />} />
					<Redirect to="/admin" />
				</Switch>
			</Suspense>
		);
	}

	return <>
		{/*props.loading && (
			<div className="overlay">
				<div className="spinner">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</div>
			</div>
		)*/}
		{MSG && MSG?.showMessage && (
		<Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			open={true}
			autoHideDuration={MSG.duration}
			onClose={() => {
				dispatch(actions.hideMessage());
			}}
		>
			<MuiAlert elevation={6} variant="filled" severity={MSG.type}>
				{MSG.message}
			</MuiAlert>
		</Snackbar>
	)}
		{routes}</>;
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.token !== null,
		isAdminAuthenticated: state.auth.adminToken !== null,
		loading: state.auth.loading || state.products.loading || state.userAccountInfo.loading || state.suppliers.loading || state.admin.loading || state.masterData.loading,
		originalURL: state.auth.originalURL
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onTryAutoSignup: () => dispatch(actions.authCheckState()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
