import * as actions from './actions';
import { SUPPLIER_API } from '../../common/configs/path.config';
import { http } from '../../common/services/http.service';
import { showMessage } from './message-notification';

export const getPurchaseOrdersStart = () => {
	return {
		type: actions.GET_PURCHASE_ORDERS_START,
	};
};

export const getPurchaseOrdersSuccess = (purchaseOrders) => {
	return {
		type: actions.GET_PURCHASE_ORDERS_SUCCESS,
		purchaseOrders: purchaseOrders,
	};
};

export const getPurchaseOrdersFail = error => {
	return {
		type: actions.GET_PURCHASE_ORDERS_FAIL,
		error: error,
	};
};

export const getPurchaseOrderDetailStart = () => {
	return {
		type: actions.GET_PURCHASE_ORDER_DETAIL_START,
	};
};

export const getPurchaseOrderDetailSuccess = (purchaseOrderDetail) => {
	return {
		type: actions.GET_PURCHASE_ORDER_DETAIL_SUCCESS,
		purchaseOrderDetail: purchaseOrderDetail,
	};
};

export const getPurchaseOrderDetailFail = error => {
	return {
		type: actions.GET_PURCHASE_ORDER_DETAIL_FAIL,
		error: error,
	};
};

export const savePurchaseOrderDetailStart = () => {
	return {
		type: actions.PURCHASE_ORDERS_START,
	};
};

export const savePurchaseOrderDetailSuccess = () => {
	return {
		type: actions.PURCHASE_ORDERS_SUCCESS,
	};
};

export const savePurchaseOrderDetailFail = error => {
	return {
		type: actions.PURCHASE_ORDERS_FAIL,
		error: error,
	};
};

export const getPurchaseOrders = (supplierId) => {
	const url = SUPPLIER_API.GET_PURCHASE_ORDERS + supplierId;
	return http.get(url);
};

export const getPurchaseOrderDetail = (supplierId, purchaseOrderId) => {
	const url = SUPPLIER_API.GET_PURCHASE_ORDER_DETAIL + supplierId + "&purchaseOrderId=" + purchaseOrderId;
	return http.get(url);
};

export const purchaseOrderSave = (supplierId, item) => {
	const url = SUPPLIER_API.UPDATE_PURCHASE_ORDER_DETAIL + supplierId;
	return http.post(url, item);
};