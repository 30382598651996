import * as actions from './actions';
import { POWER_BI_API } from '../../common/configs/path.config';
import { http } from '../../common/services/http.service';

export const getPowerBIEmbedStart = () => {
    return {
        type: actions.GET_POWER_BI_EMBED_START,
    };
};

export const getPowerBIEmbedSuccess = (powerBIEmbed) => {
    return {
        type: actions.GET_POWER_BI_EMBED_SUCCESS,
        powerBIEmbed: powerBIEmbed,
    };
};

export const getPowerBIEmbedFail = error => {
    return {
        type: actions.GET_POWER_BI_EMBED_FAIL,
        error: error,
    };
};

export const getPowerBIEmbed = (workSpaceId, reportId, roleName, rlsId) => {
    const url = POWER_BI_API.GET_POWER_BI_EMBED + "?workSpaceId=" + workSpaceId + "&reportId=" + reportId + "&roleName=" + roleName + "&rlsId=" + rlsId;
    return http.get(url);
};