import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import ButtonCustom from '../../common/components/ui/button/button';
import { useSelector } from "react-redux";
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

// styling for Textfield
const useStyles = makeStyles((theme) => ({
}));

const AccountInfo = (props) => {
    const initialInfo = {
        id: '',
        userName: '',
        emailId: '',
        fullName: '',
        phoneNum: '',
        officeNum: '',
        addrss1: '',
        addrss2: '',
        cityName: '',
        stateName: '',
        zip: '',
        newPass: '',
        confirmPass: ''
    };
    const [userAccountInfo, setUserAccountInfoData] = useState(initialInfo);

    const userDetail = useSelector((state) => state.auth.userInfo);

    useEffect(() => {
        if (userDetail) {
            setUserAccountInfoData({
                ...userAccountInfo,
                id: userDetail.id,
                userName: userDetail.userName,
                emailId: userDetail.email,
                fullName: userDetail.fullName,
                phoneNum: userDetail.phoneNumber,
                officeNum: userDetail.officeNumber,
                addrss1: userDetail.addressLine1,
                addrss2: userDetail.addressLine2,
                cityName: userDetail.city,
                stateName: userDetail.state,
                zip: userDetail.zip,
                newPass: '',
                confirmPass: ''
            });
        }
    }, [userDetail]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== userAccountInfo.newPass) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('isCharacter', (value) => {
      if (!value.replace(/[^A-Za-z]/ig, '')) {
        return false;
      }
      return true;
    });

  }, [userAccountInfo.newPass, userAccountInfo.confirmPass, userAccountInfo.cityName, userAccountInfo.stateName, userAccountInfo.fullName]);

  const changeHandlerAccountInfo = id => (event) => {
      if (id == 'userName'){
      if (event.currentTarget.value.includes(" ")) {
        event.currentTarget.value = event.currentTarget.value.replace(/\s/g, "");
      }
    }
      setUserAccountInfoData({
        ...userAccountInfo,
      [id]: event.target.value
    })
  }

    const accountInfoSubmit = (event) => {
        if (event) event.preventDefault();
        const userInfoObj = {
            Id: userAccountInfo.id,
            UserName: userAccountInfo.userName,
            OpenID: userAccountInfo.userName,
            Password: userAccountInfo.newPass ? userAccountInfo.newPass : '',
            Email: userAccountInfo.emailId,
            FullName: userAccountInfo.fullName,
            PhoneNumber: userAccountInfo.phoneNum,
            OfficeNumber: userAccountInfo.officeNum,
            AddressLine1: userAccountInfo.addrss1,
            AddressLine2: userAccountInfo.addrss2,
            City: userAccountInfo.cityName,
            State: userAccountInfo.stateName,
            Zip: userAccountInfo.zip
        }
        props.onUserInfoUpdate(userInfoObj);
  }

  const classes = useStyles();
    return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Account Information</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    <ValidatorForm noValidate autoComplete="off" onSubmit={accountInfoSubmit}>
                        <Row>
                            <Col sm={12} lg={6}>
                            <TextValidator disabled id="userName" className={classes.textField}
                                label="Username" variant="outlined"
                                validators={['required', 'minStringLength:8']} errorMessages={['The username is required', 'Minimum 8 characters are required']}
                                value={userAccountInfo.userName || ''}  onChange={changeHandlerAccountInfo('userName')} />
                            </Col>
                            <Col sm={12} lg={6}>
                            <TextValidator id="emailId" className={classes.textField}
                                label="Email" variant="outlined"
                                validators={['required', 'isEmail']}
                                errorMessages={['Email is required', 'Email is not valid']}
                                value={userAccountInfo.emailId || ''} onChange={changeHandlerAccountInfo('emailId')} />
                            </Col>
                            <Col sm={12} lg={6}>
                            <TextValidator id="fullName" className={classes.textField}
                                label="Name" variant="outlined"
                                validators={['required', 'isCharacter']}
                                errorMessages={['Username is required', 'Character only']}
                                value={userAccountInfo.fullName || ''} onChange={changeHandlerAccountInfo('fullName')} />
                            </Col>
                            <Col sm={12} lg={6}>
                            <TextValidator id="phoneNum" className={classes.textField}
                                label="Phone Number" variant="outlined"
                                validators={['required', 'isNumber']} errorMessages={['Phone Number is required', 'Number only']}
                                value={userAccountInfo.phoneNum || ''} onChange={changeHandlerAccountInfo('phoneNum')} />
                            </Col>
                            <Col sm={12} lg={6}>
                            <TextValidator id="officeNum" className={classes.textField}
                                label="Office Number" variant="outlined"
                                validators={['isNumber']} errorMessages={['Office Number is required', 'Number Only']}
                                value={userAccountInfo.officeNum || ''} onChange={changeHandlerAccountInfo('officeNum')} />
                            </Col>
                            <Col sm={12} lg={6}>
                            <TextValidator id="addrss1" className={classes.textField}
                                label="Address Line 1" variant="outlined"
                                validators={['required']} errorMessages={['Address Line 1 is required']}
                                value={userAccountInfo.addrss1 || ''} onChange={changeHandlerAccountInfo('addrss1')} />
                            </Col>
                            <Col sm={12} lg={6}>
                            <TextValidator id="addrss2" className={classes.textField}
                                label="Address Line 2" variant="outlined"
                                value={userAccountInfo.addrss2 || ''} onChange={changeHandlerAccountInfo('addrss2')} />
                            </Col>
                            <Col sm={12} lg={6}>
                            <TextValidator id="cityName" className={classes.textField}
                                label="City" variant="outlined"
                                validators={['required', 'isCharacter']} errorMessages={['City is required', 'Character only']}
                                value={userAccountInfo.cityName || ''} onChange={changeHandlerAccountInfo('cityName')} />
                            </Col>
                            <Col sm={12} lg={6}>
                            <TextValidator id="stateName" className={classes.textField}
                                label="State" variant="outlined"
                                validators={['required', 'isCharacter']} errorMessages={['State is required', 'Character only']}
                                value={userAccountInfo.stateName || ''} onChange={changeHandlerAccountInfo('stateName')} />
                            </Col>
                            <Col sm={12} lg={6}>
                            <TextValidator id="zip" className={classes.textField}
                                label="Zip" variant="outlined"
                                validators={['required', 'isNumber']} errorMessages={['Zip is required', 'Number only']}
                                value={userAccountInfo.zip || ''} onChange={changeHandlerAccountInfo('zip')} />
                            </Col>
                            <Col sm={12} lg={6}>
                            <TextValidator id="newPass" className={classes.textField} autoComplete="off"
                                type="password" label="New Password" variant="outlined"
                                value={userAccountInfo.newPass || ''} onChange={changeHandlerAccountInfo('newPass')} />
                            </Col>
                            <Col sm={12} lg={6}>
                            <TextValidator id="confirmPass" className={classes.textField} autoComplete="off"
                                type="password" label="Confirm Password" variant="outlined"
                                validators={['isPasswordMatch']} errorMessages={['The entered passwords do not match.']}
                                value={userAccountInfo.confirmPass || ''} onChange={changeHandlerAccountInfo('confirmPass')} />
                            </Col>
                            <Col className="account-info-button-container" sm={12}>
                                <ButtonCustom  className='accountInfo button primary' variant="contained" type='submit'>Save</ButtonCustom>
                            </Col>
                        </Row>             
                    </ValidatorForm>
                </Col>
            </Row>
        </Container>
  );
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        isAdminAuthenticated: state.auth.adminToken !== null,
        authRedirectPath: state.auth.authRedirectPath,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUserInfoUpdate: (userObj) => dispatch(actions.userAccountInfoUpdate(userObj)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountInfo);
