import * as actions from './actions';
import { http } from './../../common/services/http.service';
import localStore from './../../common/services/localstorage.service';
import { stringifyJSON, parseJSON } from './../../common/utils/utility';
import {
	USER_INFO,
	AUTH_TOKEN,
	ADMIN_TOKEN,
	AUTH_EXPIRATION,
	DEFAULT_EXPIRATION_SEC,
	USER_ROLE_SUPPLIER
} from './../../common/constants';
import { AUTH_API } from './../../common/configs/path.config';
import { showMessage } from './message-notification';

export const authStart = () => {
	return {
		type: actions.AUTH_START,
	};
};

export const authSuccess = (token, adminToken, userInfo, originalURL) => {
	return {
		type: actions.AUTH_SUCCESS,
		idToken: token,
		adminToken: adminToken,
		userInfo: userInfo,
		originalURL: originalURL,
	};
};

export const authFail = error => {
	return {
		type: actions.AUTH_FAIL,
		error: error,
	};
};

export const createUserStart = () => {
	return {
		type: actions.CREATE_USER_START,
	};
};

export const createUserSuccess = () => {
	return {
		type: actions.CREATE_USER_SUCCESS,
	};
};

export const createUserFail = error => {
	return {
		type: actions.CREATE_USER_FAIL,
		error: error,
	};
};

export const recoverPasswordStart = () => {
	return {
		type: actions.RECOVER_PASSWORD_START,
	};
};

export const recoverPasswordSuccess = () => {
	return {
		type: actions.RECOVER_PASSWORD_SUCCESS,
	};
};

export const recoverPasswordFail = error => {
	return {
		type: actions.RECOVER_PASSWORD_FAIL,
		error: error,
	};
};

export const logout = () => {
	localStore.remove(AUTH_TOKEN);
	localStore.remove(ADMIN_TOKEN);
	localStore.remove(AUTH_EXPIRATION);
	localStore.remove(USER_INFO);

	return {
		type: actions.AUTH_LOGOUT,
	};
};

export const checkAuthTimeout = expirationTime => {
	return dispatch => {
		setTimeout(() => {
			dispatch(logout());
		}, expirationTime * 1000);
	};
};

export const auth = (email, password, originalURL) => {
	return dispatch => {
		dispatch(authStart());
		const authData = {
			UserName: email,
			Password: password,
		};
		const url = AUTH_API.LOGIN;
		http
			.post(url, authData)
			.then(response => {
				const res = Object.assign({}, response.data, {
					expiresIn: DEFAULT_EXPIRATION_SEC
				});
				const expirationDate = new Date(
					new Date().getTime() + res.expiresIn * 1000
				);

				localStore.set(AUTH_EXPIRATION, expirationDate);
				localStore.set(USER_INFO, stringifyJSON(res));

				if (res.userRoleId === USER_ROLE_SUPPLIER) {
					localStore.set(AUTH_TOKEN, res.token);
					dispatch(authSuccess(res.token, null, res, originalURL)); 
				}
				else {
					localStore.set(ADMIN_TOKEN, res.token);
					dispatch(authSuccess(null, res.token, res, originalURL));
				}
				dispatch(checkAuthTimeout(res.expiresIn));
			})
			.catch((err) => {
				const errMsg = 'Something went wrong';
				dispatch(authFail(err?.response?.data?.error));
				dispatch(showMessage(err?.response?.data?.error || errMsg));
			});
	};
};

export const setAuthRedirectPath = path => {
	return {
		type: actions.SET_AUTH_REDIRECT_PATH,
		path: path,
	};
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStore.get(AUTH_TOKEN);
        const adminToken = localStore.get(ADMIN_TOKEN);
        if (!token && !adminToken) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStore.get(AUTH_EXPIRATION));
            if (expirationDate <= new Date()) {
             dispatch(logout());
            } else {
             const userInfo = parseJSON(localStore.get(USER_INFO)) || '';
             dispatch(authSuccess(token, adminToken, userInfo));
             dispatch(
                 checkAuthTimeout(
                     (expirationDate.getTime() - new Date().getTime()) / 1000
                 )
             );
            }
        }
    };
};

export const createUser = (userData) => {
	const url = AUTH_API.CREATE_USER;
	return http.post(url, userData);
};

export const recoverPassword = (email) =>{
	return dispatch => {
		dispatch(recoverPasswordStart());
		const userData = {
			EmailId: email
		};
		const url = AUTH_API.RECOVER_PASSWORD;
		http
			.post(url, userData)
			.then(response => {
				dispatch(recoverPasswordSuccess());
				dispatch(showMessage('Request submitted, please check your email', 'success'))
			})
			.catch(err => {
				const errMsg = 'Something went wrong';
				dispatch(recoverPasswordFail(err?.response?.data?.error));
				dispatch(showMessage(err?.response?.data?.error || errMsg));
			});
	};
}
