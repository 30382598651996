import React, { useEffect, useState } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import ButtonCustom from '../../../common/components/ui/button/button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { connect, useSelector } from 'react-redux';
import { STATUS_APPROVED, STATUS_PENDING, STATUS_REJECTED } from '../../../common/constants';
import MaterialTable from 'material-table';
import { formatDate } from './../../../common/utils/utility';
import * as actions from '../../../store/actions/index';
import { updateMasterData } from '../../../store/actions/admin';

const AppSettings = (props) => {

    return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">App Settings</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    <Row>
                        <Col sm={12} md={3} lg={3} >
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
};

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateMasterDataStart: () => dispatch(actions.updateMasterDataStart()),
        updateMasterDataSuccess: () => dispatch(actions.updateMasterDataSuccess()),
        updateMasterDataFail: () => dispatch(actions.updateMasterDataFail()),
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppSettings);