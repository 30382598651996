import { API_BASE_PATH} from './../constants';

export const AUTH_API = {
	LOGIN: `${API_BASE_PATH}Login/SignIn`,
	CREATE_USER: `${API_BASE_PATH}Login/SignUp`,
	RECOVER_PASSWORD: `${API_BASE_PATH}Login/ForgotPassword`,
};

export const SUPPLIER_API = {
	GET_PRODUCTS: `${API_BASE_PATH}NetSuite/GetProductCatalogNetSuiteData?id=`,
	PRODUCT_DETAILS: `${API_BASE_PATH}Product/EditProduct`,
	GET_PRODUCT_REQUESTS: `${API_BASE_PATH}Product/GetProductRequest?supplierId=`,
	GET_NOTIFICATIONS: `${API_BASE_PATH}Product/GetSupplierNotification?supplierId=`,
	GET_PRODUCT_REQUEST_BY_ID: `${API_BASE_PATH}Product/GetProductRequestById?requestId=`,
	GET_MASTERDATA: `${API_BASE_PATH}MasterData/GetMasterData`,
	UPLOAD_FILE: `${API_BASE_PATH}Document/InsertDocument`,
	GET_DOCUMENTS_BY_PRODUCT_ID: `${API_BASE_PATH}Document/GetDocumentsByProductId?productId=`,
	GET_PURCHASE_ORDERS: `${API_BASE_PATH}NetSuite/GetPurchaseOrderNetSuiteData?supplierId=`,
	GET_PURCHASE_ORDER_DETAIL: `${API_BASE_PATH}NetSuite/GetPurchaseOrderDetailNetSuiteData?supplierId=`,
	UPDATE_PURCHASE_ORDER_DETAIL: `${API_BASE_PATH}NetSuite/UpdatePurchaseOrderDetailNetSuiteData?supplierId=`,
};

export const POWER_BI_API = {
	GET_POWER_BI_EMBED: `${API_BASE_PATH}EmbedInfo/GetPowerBIEmbedInfo`
}

export const UPDATE_USER_API = {
	UPDATE_DETAILS: `${API_BASE_PATH}Account/UpdateSingleUser`
};

export const ADMIN_API = {
	GET_PANDING_USER: `${API_BASE_PATH}Account/GetAllUser?status=false`,
	GET_SUPPLER_LIST: `${API_BASE_PATH}NetSuite/GetSupplierNetSuiteData`,
	GET_ALLSUPPLER_LIST: `${API_BASE_PATH}Account/GetAllUser?status=true`,
	UPDATE_SUPPLIER: `${API_BASE_PATH}Account/UpdateApproveAccount`,
	GET_PRODUCT_REQUEST_BY_ID: `${API_BASE_PATH}Product/GetProductRequestById?requestId=`,
	GET_ALL_PRODUCTS_LIST: `${API_BASE_PATH}Product/GetAllProductRequest`,
	GET_USER_ROLE: `${API_BASE_PATH}Account/GetAllUserRole`,
	EDIT_USER_ACCOUNT: `${API_BASE_PATH}Account/UpdateAccountUser`,
	DELETE_USER: `${API_BASE_PATH}Account/DeleteUser?id=`,
	UPDATE_PRODUCT: `${API_BASE_PATH}Product/UpdateProductRequestStatus`,
	GET_ALL_DOCUMENTS: `${API_BASE_PATH}Document/GetAllDocument`,
	GET_SUPPLIER_DOCUMENTS: `${API_BASE_PATH}Document/GetDocumentsBySupplierId?supplierId=`,
	GET_PDF_DOCUMENTS: `${API_BASE_PATH}Document/GetDocumentById?documentId=`,
	GET_MASTERDATA: `${API_BASE_PATH}MasterData/GetMasterData`,
	UPDATE_MASTERDATA: `${API_BASE_PATH}MasterData/UpdateMasterData`,
	GET_USER_LOGIN_HISTORY: `${API_BASE_PATH}Account/GetUserLoginHistory?userName=`
};
