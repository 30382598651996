import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import ProductDetailImage from "../../../assets/images/pcd.PNG";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ButtonCustom from '../../../common/components/ui/button/button';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { MenuItem } from '@material-ui/core';
import { STATUS_APPROVED, STATUS_PENDING, STATUS_REJECTED, IMAGE_BASE, NETSUITE_IMAGE_DIRECTORY } from '../../../common/constants';
import TextField from '@material-ui/core/TextField';
import { formatDate } from './../../../common/utils/utility';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MultipleSelectChip from './../../../common/components/ui/multipleSelect/MultipleSelectChip';
import { ADMIN_API } from '../../../common/configs/path.config';
import { http } from '../../../common/services/http.service';

const PendingRequestDetails = (props) => {
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [itemDetails, setItemDetails] = useState(props.location.state);
    const requestURL = ADMIN_API.GET_PRODUCT_REQUEST_BY_ID + props.location.state.productRequestId;

    useEffect(() => {
        http.get(requestURL)
            .then((res) => {
                setItemDetails(res.data)
            });
    }, []);

    const allProducts = useSelector(state => state.admin.allProducts);
    const [hide, setIsOpened] = useState(true);

    let containerTypeOptions = [];
    let categoryOptions = [];
    let particleSizeOptions = [];
    let flavorOptions = [];
    let medicationTypeOptions = [];
    let odorOptions = [];
    let potencyOptions = [];
    let solubilityOptions = [];
    let speciesOptions = [];
    let medicatedLicenseRequiredOptions = [];
    let masterData = [];
    masterData = useSelector((state) => state.masterData.masterData);

    if (masterData != null) {
        containerTypeOptions = masterData.filter(masterOption => masterOption.dataclass.includes('ContainerType'));
        categoryOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Category'));
        particleSizeOptions = masterData.filter(masterOption => masterOption.dataclass.includes('ParticleSize'));
        flavorOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Flavor'));
        medicationTypeOptions = masterData.filter(masterOption => masterOption.dataclass.includes('MedicationType'));
        odorOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Odor'));
        potencyOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Potency'));
        solubilityOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Solubility'));
        speciesOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Species'));
        medicatedLicenseRequiredOptions = masterData.filter(masterOption => masterOption.dataclass.includes('MedicatedLicenseRequired'));
    }

	let speciesMultiValOriginal = [];
	if (itemDetails.originalSpecies) {
        speciesMultiValOriginal = itemDetails.originalSpecies.split(',').map((speciesID) => speciesOptions.filter(
			(masterOption) => masterOption.id === speciesID)[0].name
		)
    }
    let speciesMultiVal = [];
    if (itemDetails.species) {
        speciesMultiVal = itemDetails.species.split(',').map((speciesID) => speciesOptions.filter(
            (masterOption) => masterOption.id === speciesID)[0].name
        )
    }

    const showComment = async (event, isApproved) => {
        try {
            setIsOpened(hide => false);
        }
        catch (err) {
            const errMsg = 'Something went wrong';
            props.showMessage(errMsg);
        }
    }

    const hideComment = async (event, isApproved) => {
        try {
            setIsOpened(hide => true);
        }
        catch (err) {
            const errMsg = 'Something went wrong';
            props.showMessage(errMsg);
        }
    }
     
    const itemSave = async (event, isApproved) => {
        if (event) event.preventDefault();
        const updateData = {
            productRequestId: itemDetails.productRequestId,
            status: isApproved ? STATUS_APPROVED : STATUS_REJECTED,
            id: userInfo.id,
            denyComment: document.getElementById("Comment").value
        };
        try {
            props.updateEditProductStart();
            const result = await actions.updateProduct(updateData);
            const productsList = allProducts.map((item) => {
                if (item.productRequestId === updateData.productRequestId) {
                    item.status = updateData.status;
                }
                return item;
            });
            props.updateEditProductSuccess(productsList);
            props.showMessage('Request completed', 'success');
            props.history.goBack();
        }
        catch (err) {
            const errMsg = 'Something went wrong';
            props.updateEditProductFail(err?.response?.data?.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        }
    }

    const onCancel = (event) => {
        if (event) event.preventDefault();
        props.history.goBack();
    }

    let imgOriginal = "";
    if (itemDetails.placeholderOriginal) {
        imgOriginal = IMAGE_BASE + NETSUITE_IMAGE_DIRECTORY + itemDetails.placeholderOriginal;//Original Image
    }
    else {
        imgOriginal = IMAGE_BASE + itemDetails.placeholder//Placeholder Image
    }

    let img = "";
    if (itemDetails.images) {
        if (itemDetails.images.length && itemDetails.images[0].isInNetSuite) {
            img = IMAGE_BASE + NETSUITE_IMAGE_DIRECTORY + itemDetails.images[0].imageName;//Original Image
        }
        else if (itemDetails.images.length && !itemDetails.images[0].isInNetSuite) {
            img = "data:" + itemDetails.images[0].imageMimeType + ";base64," + itemDetails.images[0].imageBase64;//Uploaded Image
        }
        else {
            img = IMAGE_BASE + itemDetails.placeholder//Placeholder Image
        }
    }

    function valueChanged(itemChanged) {
        if (itemChanged) {
            return "itemChanged";
        }
        return "itemNotChanged";
    }

    const openSpecSheet = () => {
        let currentDomain = window.location.host;
        let domain = 'https://3395596-sb1.extforms.netsuite.com';
        let restOfURL = '/app/site/hosting/scriptlet.nl?deploy=customdeploy_nb_sl_item_get_file&script=customscript_nb_sl_item_get_file';
        let pid = itemDetails.productId;
        let fieldid = 'custitem_nb_ecom_spec_sheet'
        let compid = '3395596_SB1';
        let h = 'e28fea6e955e7a7c82cb';

        if (currentDomain == 'supplierportal.nutrablend.com') {
            domain = 'https://3395596.extforms.netsuite.com';
            compid = '3395596';
            h = '3d0b67a3e053ba5839a7';
        }

        window.open(domain + restOfURL + "&pid=" + pid + "&fieldid=" + fieldid + "&compid=" + compid + "&h=" + h, '_blank').focus();
    }

    return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Approve/Deny Change Request</h2>
                </Col>
            </Row>
            <Row className="">
                <Col className="text-sm-right">
                    <br/>
                    <ButtonCustom className="button" variant="contained" onClick={openSpecSheet}>View Spec Sheet</ButtonCustom>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    <ValidatorForm className='product-details-form' onSubmit={() => { }} noValidate autoComplete="off" >
                        <Row className="product-details productDetailImages">
                            <Col className="pending-request-details-original" sm={12} md={6} lg={6}>
                                <Row>
                                    <Col className="pending-request-details-header-container">
                                        <h5>Original</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row className="pending-request-details-image-container-padding">
                                            <Col className="product-details-image-container" sm={12}>
                                                <Image src={imgOriginal} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextValidator disabled id="itemName"
                                            label="Item Number" variant="outlined"
                                            value={itemDetails.itemName || ''} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <TextValidator disabled id="originalStoreDisplayName"
                                            label="Original Display Name" variant="outlined"
                                            value={itemDetails.originalStoreDisplayName || ''} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <TextField disabled id="originalDescription"
                                            label="Original Description" variant="outlined"
                                            value={itemDetails.originalDescription || ''}
                                            multiline rowsMax={4} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <TextValidator disabled id="originalContainerType" select
                                            label="Original Container Type" variant="outlined"
                                            value={itemDetails.originalContainerType || ''} >
                                            {containerTypeOptions.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextValidator>
                                    </Col>
								</Row>
								<Row>
									<Col>
										<TextValidator disabled id="originalCategory" select
											label="Original Category" variant="outlined"
											value={itemDetails.originalCategory}
										>
											{categoryOptions.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</TextValidator>
									</Col>
								</Row>
                                <Row>
                                    <Col>
                                        <TextValidator disabled id="originalPackage"
                                            label="Original Package(lbs)" variant="outlined"
                                            value={itemDetails.originalPackage || ''} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextValidator disabled id="bagsPerPallet"
                                            label="Original Bags Per Pallet" variant="outlined"
                                            value={itemDetails.originalBagsPerPallet || ''} />
                                    </Col>
                                </Row>
								<Row>
                                    <Col>
                                        <TextValidator disabled id="originalMedicatedLicenseRequired" select
                                            label="Original Medicated License Type" variant="outlined"
                                            value={itemDetails.originalMedicatedLicenseRequired}
                                        >
                                            {medicatedLicenseRequiredOptions.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextValidator>
									</Col>
								</Row>
                                <Row>
                                    <Col>
                                        <FormControlLabel id="isDiscontinuedOriginal"
                                            className="CheckboxLabel"
                                            variant="outlined"
                                            label="Original Item Discontinued"
                                            control={<Checkbox checked={itemDetails.itemDiscontinuedOriginal}
                                                value={itemDetails.itemDiscontinuedOriginal}
                                                disabled
                                                    id="itemDiscontinued" />} />
                                    </Col>
								</Row>
								<Row>
									<Col>
										<TextValidator disabled id="appearance"
											label="Appearance" variant="outlined"
											value={itemDetails.originalAppearance || ''} />
									</Col>
								</Row>
								<Row>
									<Col>
										<TextValidator disabled id="particleSize" select
											label="Particle Size" variant="outlined"
											value={itemDetails.originalParticleSize}
										>
											{particleSizeOptions.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</TextValidator>
									</Col>
								</Row>
								<Row>
									<Col>
										<TextValidator disabled id="bulkDensity"
											label="Bulk Density (g/cm3)" variant="outlined"
											value={itemDetails.originalBulkDensity || ''} />
									</Col>
								</Row>
								<Row>
									<Col>
										<TextValidator disabled id="flavor" select
											label="Flavor" variant="outlined"
											value={itemDetails.originalFlavor}
										>
											{flavorOptions.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</TextValidator>
									</Col>
								</Row>
								<Row>
									<Col>
										<TextValidator disabled id="medicationType" select
											label="Medication Type" variant="outlined"
											value={itemDetails.originalMedicationType}
										>
											{medicationTypeOptions.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</TextValidator>
									</Col>
								</Row>
								<Row>
									<Col>
										<TextValidator disabled id="color"
											label="Color" variant="outlined"
											value={itemDetails.originalColor || ''} />
									</Col>
								</Row>
								<Row>
									<Col>
										<TextValidator disabled id="odor" select
											label="Odor" variant="outlined"
											value={itemDetails.originalOdor}
										>
											{odorOptions.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</TextValidator>
									</Col>
								</Row>
								<Row>
									<Col>
										<TextValidator disabled id="potency" select
											label="Potency" variant="outlined"
											value={itemDetails.originalPotency}
										>
											{potencyOptions.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</TextValidator>
									</Col>
								</Row>
								<Row>
									<Col>
										<TextValidator disabled id="solubility" select
											label="Solubility" variant="outlined"
											value={itemDetails.originalSolubility}
										>
											{solubilityOptions.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</TextValidator>
									</Col>
								</Row>
								<Row>
                                    <Col>
                                        <div className="multiSelect">
										    <MultipleSelectChip
											    label="Species"
                                                multiSelectValue={speciesMultiValOriginal}
											    options={speciesOptions}
                                                    disabled />
                                        </div>
									</Col>
								</Row>
                                {/*<Row>
									<Col>
										<TextField
											id="feedingInstructions"
											disabled
											multiline
											rowsMax={15}
											value={itemDetails.originalFeedingInstructions || ''}
											variant="outlined"
											label="Feeding Instructions"
										/>
									</Col>
								</Row>*/}
                            </Col>
                            <Col className="pending-request-details-updated" sm={12} md={6} lg={6}>
                                <Row>
                                    <Col className="pending-request-details-header-container">
                                        <h5>Requested Changes</h5>
                                    </Col>
                                </Row>
                                <Row className={valueChanged(itemDetails.isImageUpdated)}>
                                    <Col>
                                        <Row className="pending-request-details-image-container-padding">
                                            <Col className="product-details-image-container" sm={12}>
                                                <Image src={img} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row><Row>
                                    <Col className={valueChanged(itemDetails.itemNameChanged)}>
                                        <TextValidator disabled id="itemName"
                                            label="Item Number" variant="outlined"
                                            value={itemDetails.itemName || ''} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.displayNameChanged)}>
                                        <TextValidator disabled id="storeDisplayName"
                                            label="Display Name" variant="outlined"
                                            value={itemDetails.storeDisplayName || ''} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.descriptionChanged)}>
                                        <TextField disabled id="description"
                                            label="Description" variant="outlined"
                                            value={itemDetails.description || ''}
                                            multiline rowsMax={4} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className={valueChanged(itemDetails.containerTypeChanged)}>
                                        <TextValidator disabled id="containerType" select
                                            label="Container Type" variant="outlined"
                                            value={itemDetails.containerType || ''} >
                                            {containerTypeOptions.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextValidator>
                                    </Col>
								</Row>

								<Row>
									<Col className={valueChanged(itemDetails.categoryChanged)}>
										<TextValidator disabled id="category" select
											label="Category" variant="outlined"
											value={itemDetails.category}
										>
											{categoryOptions.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.name}
												</MenuItem>
										))}
										</TextValidator>
									</Col>
								</Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.packageChanged)}>
                                        <TextValidator disabled id="package"
                                            label="Package(lbs)" variant="outlined"
                                            value={itemDetails.package || ''} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.bagsPerPalletChanged)}>
                                        <TextValidator disabled id="bagsPerPallet"
                                            label="Bags Per Pallet" variant="outlined"
                                            value={itemDetails.bagsPerPallet || ''} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.medicatedLicenseRequiredChanged)}>
                                        <TextValidator disabled id="medicatedLicenseRequired" select
                                            label="Medicated License Type" variant="outlined"
                                            value={itemDetails.medicatedLicenseRequired}
                                        >
                                            {medicatedLicenseRequiredOptions.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextValidator>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.itemDiscontinuedChanged)}>
                                        <FormControlLabel id="isDiscontinued"
                                            className="CheckboxLabel"
                                            variant="outlined"
                                            label="Item Discontinued"
                                            control={<Checkbox checked={itemDetails.itemDiscontinued}
                                                value={itemDetails.itemDiscontinued}
                                                disabled
                                                id="itemDiscontinued" />} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.appearanceChanged)}>
                                        <TextValidator disabled id="appearance"
                                            label="Appearance" variant="outlined"
                                            value={itemDetails.appearance || ''} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.particleSizeChanged)}>
                                        <TextValidator disabled id="particleSize" select
                                            label="Particle Size" variant="outlined"
                                            value={itemDetails.particleSize}
                                        >
                                            {particleSizeOptions.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextValidator>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.bulkDensityChanged)}>
                                        <TextValidator disabled id="bulkDensity"
                                            label="Bulk Density" variant="outlined"
                                            value={itemDetails.bulkDensity || ''} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.flavorChanged)}>
                                        <TextValidator disabled id="flavor" select
                                            label="Flavor" variant="outlined"
                                            value={itemDetails.flavor}
                                        >
                                            {flavorOptions.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextValidator>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.medicationTypeChanged)}>
                                        <TextValidator disabled id="medicationType" select
                                            label="Medication Type" variant="outlined"
                                            value={itemDetails.medicationType}
                                        >
                                            {medicationTypeOptions.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextValidator>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.colorChanged)}>
                                        <TextValidator disabled id="color"
                                            label="Color" variant="outlined"
                                            value={itemDetails.color || ''} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.odorChanged)}>
                                        <TextValidator disabled id="odor" select
                                            label="Odor" variant="outlined"
                                            value={itemDetails.odor}
                                        >
                                            {odorOptions.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextValidator>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.potencyChanged)}>
                                        <TextValidator disabled id="potency" select
                                            label="Potency" variant="outlined"
                                            value={itemDetails.potency}
                                        >
                                            {potencyOptions.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextValidator>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.solubilityChanged)}>
                                        <TextValidator disabled id="solubility" select
                                            label="Solubility" variant="outlined"
                                            value={itemDetails.solubility}
                                        >
                                            {solubilityOptions.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextValidator>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={valueChanged(itemDetails.speciesChanged)}>
                                        <div className="multiSelect">
                                            <MultipleSelectChip
                                                label="Species"
                                                multiSelectValue={speciesMultiVal}
                                                options={speciesOptions}
                                                    disabled />
                                        </div>
                                    </Col>
                                </Row>
                                {/*<Row>
                                    <Col className={valueChanged(itemDetails.feedingInstructionsChanged)}>
                                        <TextField
                                            id="feedingInstructions"
                                            disabled
                                            multiline
                                            rowsMax={15}
                                            value={itemDetails.feedingInstructions || ''}
                                            variant="outlined"
                                            label="Feeding Instructions"
                                        />
                                    </Col>
                                </Row>*/}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextValidator disabled id="requestedOn"
                                    label="Requested On" variant="outlined"
                                    value={formatDate(itemDetails.modifiedTimestamp) || ''} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextValidator disabled id="requestedBy"
                                    label="Requested By" variant="outlined"
                                    value={itemDetails.modifiedBy || ''} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextField
                                    id="Comment"
                                    defaultValue={itemDetails.denyComment || ''}
                                    hidden={hide}                                   
                                    multiline
                                    maxRows={4}
                                    variant="outlined"
                                    label="Comment"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pending-request-detail-buttons">
                                {itemDetails.status === STATUS_PENDING && 
                                    <React.Fragment>
                                        <ButtonCustom hidden={!hide} className='button success' variant="contained" onClick={(eve) => { itemSave(eve, true); }}>Approve</ButtonCustom>
                                        <ButtonCustom hidden={!hide} className='button danger' variant="contained" onClick={(eve) => { showComment(eve, false); }}>Deny</ButtonCustom>
                                        <ButtonCustom hidden={hide} className='button danger' variant="contained" onClick={(eve) => { itemSave(eve, false); }}>Submit</ButtonCustom>
                                        <ButtonCustom hidden={hide} className='button secondary' variant="contained" onClick={(eve) => { hideComment(eve, false); }}>Cancel</ButtonCustom>
                                    </React.Fragment>
                                }
                                <ButtonCustom hidden={!hide} className='button secondary' variant="contained" onClick={(eve) => { onCancel(eve); }}>Cancel</ButtonCustom>
                            </Col>
                        </Row>
                    </ValidatorForm>
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEditProductStart: () => dispatch(actions.updateEditProductStart()),
        updateEditProductSuccess: (products) => dispatch(actions.updateEditProductSuccess(products)),
        updateEditProductFail: () => dispatch(actions.updateEditProductFail()),
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PendingRequestDetails);

