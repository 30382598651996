import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ButtonCustom from '../../common/components/ui/button/button';
import { CONTAINER_DATA, IMAGE_BASE, NETSUITE_IMAGE_DIRECTORY } from '../../common/constants';
import { MenuItem } from '@material-ui/core';
import { accessProductCatalog } from './../../common/utils/utility';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MultipleSelectChip from './../../common/components/ui/multipleSelect/MultipleSelectChip';
import { formatDate } from './../../common/utils/utility';
import { SUPPLIER_API } from '../../common/configs/path.config';
import { http } from '../../common/services/http.service';

const RequestDetails = (props) => {
	const userInfo = useSelector((state) => state.auth.userInfo);
	const [itemDetails, setItemDetails] = useState(props.location.state);
	const requestURL = SUPPLIER_API.GET_PRODUCT_REQUEST_BY_ID + props.location.state.productRequestId;

	useEffect(() => {
		http.get(requestURL)
			.then((res) => {
				setItemDetails(res.data)
			});
	}, []);

	var hide = true;

	let containerTypeOptions = [];
	let categoryOptions = [];
	let particleSizeOptions = [];
	let flavorOptions = [];
	let medicationTypeOptions = [];
	let odorOptions = [];
	let potencyOptions = [];
	let solubilityOptions = [];
	let speciesOptions = [];
	let medicatedLicenseRequiredOptions = [];
	let masterData = [];
	masterData = useSelector((state) => state.masterData.masterData);

	if (masterData != null) {
		containerTypeOptions = masterData.filter(masterOption => masterOption.dataclass.includes('ContainerType'));
		categoryOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Category'));
		particleSizeOptions = masterData.filter(masterOption => masterOption.dataclass.includes('ParticleSize'));
		flavorOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Flavor'));
		medicationTypeOptions = masterData.filter(masterOption => masterOption.dataclass.includes('MedicationType'));
		odorOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Odor'));
		potencyOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Potency'));
		solubilityOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Solubility'));
		speciesOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Species'));
		medicatedLicenseRequiredOptions = masterData.filter(masterOption => masterOption.dataclass.includes('MedicatedLicenseRequired'));
	}

	let multiVal = [];
	if (itemDetails.species) {
		multiVal = itemDetails.species.split(',').map((speciesID) => masterData.filter(
			(masterOption) => masterOption.dataclass == 'Species' && masterOption.id === speciesID)[0].name
		)
	}

	if (itemDetails.status === "REJECTED") {
		hide = false;
	}

	const onCancel = (event) => {
		if (event) event.preventDefault();
		props.history.goBack();
	}

	const displayImage = () => {
		if (itemDetails.images) {
			if (itemDetails.images.length) {
				if (itemDetails.images[0].imageBase64) {
					return "data:" + itemDetails.images[0].imageMimeType + ";base64," + itemDetails.images[0].imageBase64;//Display uploaded image
				}
				else if (itemDetails.images[0].imageName) {
					return IMAGE_BASE + NETSUITE_IMAGE_DIRECTORY + itemDetails.images[0].imageName;//image loaded directly from NS
				}
			}
			else {
				return IMAGE_BASE + itemDetails.placeholder//no image set for product, use placeholder instead
			}
		}
	}

	return (
		<Container>
			{accessProductCatalog(userInfo)}
			<Row className="page-header-container">
				<Col>
					<h2 className="page-header">Change Request</h2>
				</Col>
			</Row>
			<Row className="page-content-container">
				<Col className="page-content-container-inner">
					<ValidatorForm className='product-details-form' onSubmit={onCancel} noValidate autoComplete="off" >
						<Row className="product-details">
							<Col className=" d-flex flex-column align-items-center" sm={12} md={4} lg={5}>
								<Row>
									<Col className="product-details-image-container">
										<Image src={displayImage()} />
									</Col>
								</Row>
							</Col>
							<Col sm={12} md={8} lg={7}>
								<Row>
									<Col>
										<TextValidator disabled id="itemName"
											label="Item Name" variant="outlined"
											value={itemDetails.itemName || ''} />
									</Col>
								</Row>

								<Row>
									<Col>
										<TextValidator disabled id="storeDisplayName"
											label="Display Name" variant="outlined"
											value={itemDetails.storeDisplayName || ''} />
									</Col>
								</Row>

								<Row>
									<Col>
										<TextValidator disabled id="description"
											label="Description" variant="outlined"
											value={itemDetails.description || ''} />
									</Col>
								</Row>

								<Row>
									<Col>
										<TextValidator disabled id="category" select
											label="Category" variant="outlined"
											value={itemDetails.category}
										>
											{categoryOptions.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</TextValidator>
									</Col>
								</Row>

								<Row>
									<Col>
										<TextValidator disabled id="containerType" select
											label="Container Type" variant="outlined"
											value={itemDetails.containerType || ''} >
											{containerTypeOptions.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</TextValidator>
									</Col>
								</Row>

								<Row>
									<Col>
										<TextValidator disabled id="package"
											label="Package(lbs)" variant="outlined"
											value={itemDetails.package || ''} />
									</Col>
								</Row>

								<Row>
									<Col>
										<TextValidator disabled id="bagsPerPallet"
											label="Bags Per Pallet" variant="outlined"
											value={itemDetails.bagsPerPallet || ''} />
									</Col>
								</Row>
								<Row>
									<Col>
										<TextValidator disabled id="medicatedLicenseRequired" select
											label="Medicated License Type" variant="outlined"
											value={itemDetails.medicatedLicenseRequired || ''} >
											{medicatedLicenseRequiredOptions.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</TextValidator>
									</Col>
								</Row>
								<Row>
									<Col>
										<FormControlLabel id="itemDiscontinued"
											className="CheckboxLabel"
											variant="outlined"
											label="Item Discontinued"
											control={<Checkbox checked={itemDetails.itemDiscontinued}
												value={itemDetails.itemDiscontinued}
												disabled
												id="itemDiscontinued" />} />
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="product-details">
							<Col sm={12} md={12} lg={12}>
								<Row>
									<Col>
										<Row>
											<h3 className="section-header">Physical Characteristics</h3>
										</Row>
										<Row>
											<Col>
												<TextValidator disabled id="appearance"
													label="Appearance" variant="outlined"
													value={itemDetails.appearance || ''} />
											</Col>
										</Row>
										<Row>
											<Col sm={12} md={6} lg={3}>
												<TextValidator disabled id="particleSize" select
													label="Particle Size" variant="outlined"
													value={itemDetails.particleSize}
												>
													{particleSizeOptions.map((option) => (
														<MenuItem key={option.id} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</TextValidator>

											</Col>
											<Col sm={12} md={6} lg={3}>
												<TextValidator disabled id="bulkDensity"
													label="Bulk Density (g/cm3)" variant="outlined"
													value={itemDetails.bulkDensity || ''} />

											</Col>
											<Col sm={12} md={6} lg={3}>
												<TextValidator disabled id="flavor" select
													label="Flavor" variant="outlined"
													value={itemDetails.flavor}
												>
													{flavorOptions.map((option) => (
														<MenuItem key={option.id} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</TextValidator>
											</Col>
											<Col sm={12} md={6} lg={3}>
												<TextValidator disabled id="medicationType" select
													label="Medication Type" variant="outlined"
													value={itemDetails.medicationType}
												>
													{medicationTypeOptions.map((option) => (
														<MenuItem key={option.id} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</TextValidator>
											</Col>
											<Col sm={12} md={6} lg={3}>
												<TextValidator disabled id="color"
													label="Color" variant="outlined"
													value={itemDetails.color || ''} />

											</Col>
											<Col sm={12} md={6} lg={3}>
												<TextValidator disabled id="odor" select
													label="Odor" variant="outlined"
													value={itemDetails.odor}
												>
													{odorOptions.map((option) => (
														<MenuItem key={option.id} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</TextValidator>
											</Col>
											<Col sm={12} md={6} lg={3}>
												<TextValidator disabled id="potency" select
													label="Potency" variant="outlined"
													value={itemDetails.potency}
												>
													{potencyOptions.map((option) => (
														<MenuItem key={option.id} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</TextValidator>
											</Col>
											<Col sm={12} md={6} lg={3}>
												<TextValidator disabled id="solubility" select
													label="Solubility" variant="outlined"
													value={itemDetails.solubility}
												>
													{solubilityOptions.map((option) => (
														<MenuItem key={option.id} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</TextValidator>

											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col>
										<Row>
											<h3 className="section-header">Species</h3>
											<Col>
												<div className="multiSelect">
													<MultipleSelectChip
														label="Species"
														multiSelectValue={multiVal}
														options={speciesOptions}
														disabled />
												</div>
											</Col>
										</Row>
									</Col>
								</Row>
								{/*<Row>
									<Col>
										<Row>
											<h3 className="section-header">Feeding Instructions</h3>
											<Col>
												<TextField
													id="feedingInstructions"
													disabled
													multiline
													rowsMax={15}
													value={itemDetails.feedingInstructions || ''}
													variant="outlined"
													label="Feeding Instructions"
												/>
											</Col>
										</Row>
									</Col>
								</Row>*/}
							</Col>
						</Row>
						<Row className="product-details">
							<Col sm={12} md={6} lg={5}></Col>
							<Col sm={12} md={6} lg={7}>
								<Row>
									<Col>
										<TextValidator disabled id="requestedOn"
											label="Requested On" variant="outlined"
											value={formatDate(itemDetails.modifiedTimestamp)} />
									</Col>
								</Row>

								<Row>
									<Col>
										<TextValidator disabled id="requestedBy"
											label="Requested By" variant="outlined"
											value={itemDetails.modifiedBy || ''} />
									</Col>
								</Row>

								<Row>
									<Col>
										<TextValidator disabled hidden={hide} id="denyComment"
											label="Comment" variant="outlined"
											value={itemDetails.denyComment || ''} />
									</Col>
								</Row>

								<Row>
									<Col className="product-detail-edit-and-submit-button-container">
										<ButtonCustom data-testid="custom-element" className='button primary' variant="contained" onClick={() => { onCancel(); }}>
											Back
										</ButtonCustom>
									</Col>
								</Row>
							</Col>
						</Row>
					</ValidatorForm>
				</Col>
			</Row>
		</Container>
	);
}

export default RequestDetails;