import React from 'react';
import * as _ from 'lodash';
import { format } from 'date-fns';
import { Redirect } from 'react-router-dom';

const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties,
	};
};

const accessProductCatalog = (userInfo) => {
	if (userInfo) {
		if (!userInfo.accessProductCatalog) {
			return (<Redirect to="/home" />);
		}
	}
	return <></>
}

const accessSupplierAnalytics = (userInfo) => {
	if (userInfo) {
		if (!userInfo.accessSupplierAnalytics) {
			return (<Redirect to="/home" />);
		}
	}
	return <></>
}
const accessSalesOut = (userInfo) => {
	if (userInfo) {
		if (!userInfo.accessSalesOut) {
			return (<Redirect to="/home" />);
		}
	}
	return <></>
}
const accessInventory = (userInfo) => {
	if (userInfo) {
		if (!userInfo.accessInventory) {
			return (<Redirect to="/home" />);
		}
	}
	return <></>
}
const accessTerritoryManagement = (userInfo) => {
	if (userInfo) {
		if (!userInfo.accessTerritoryManagement) {
			return (<Redirect to="/home" />);
		}
	}
	return <></>
}
const accessPurchaseOrderManagement = (userInfo) => {
	if (userInfo) {
		if (!userInfo.accessPurchaseOrderManagement) {
			return (<Redirect to="/home" />);
		}
	}
	return <></>
}

const formatDate = (date) => {
	if (date) {
		var t = date.split(/[-T:]/);
		var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
		return format(d, 'M/dd/yyyy h:mm a');
	}
	return "";
};

const mergeArray = (
	oldArr,
	updateArr,
	uniqueKey
) => {
	const arr = _.unionBy(oldArr, updateArr, uniqueKey);
	return arr;
};

const stringifyJSON = (value) => {
	return JSON.stringify(value);
};
const parseJSON = (value) => {
	return JSON.parse(value);
};

const getRandomID = () => {
	return '_' + Math.random().toString(36).substr(2, 9);
};

const getQueryVariable = (queryString, variable) => {
	const query = queryString.substring(1);
	const vars = query.split('&');
	let value = '';
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=');
		if (pair[0] === variable) {
			value = pair[1];
		}
	}
	return value;
};

const decodeJWT = (token) => {
	return JSON.parse(atob(token.split('.')[1]));
};

const updateRefrence = (data) => {
	return _.cloneDeep(data);
};

const compareValues = (value1, value2) => {
	return _.isEqual(value1, value2);
};

export {
	updateObject,
	stringifyJSON,
	parseJSON,
	getRandomID,
	getQueryVariable,
	decodeJWT,
	mergeArray,
	updateRefrence,
	compareValues,
	formatDate,
	accessProductCatalog,
	accessSupplierAnalytics,
	accessSalesOut,
	accessInventory,
	accessTerritoryManagement,
	accessPurchaseOrderManagement
};
