import * as actions from '../actions/actions';
import { updateObject } from '../../common/utils/utility';

const initialState = {
	masterData: [],
    error: null,
	loading: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_MASTERDATA_START:
			return updateObject(state, {
				error: null,
				loading: true
			});
		case actions.GET_MASTERDATA_SUCCESS:
			return updateObject(state, {
				masterData: action.masterData.data.items,
				error: null,
				loading: false
			});
		case actions.GET_MASTERDATA_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false
			});
		case actions.UPDATE_MASTERDATA_START:
			return updateObject(state, {
				error: null,
				loading: true
			});
		case actions.UPDATE_MASTERDATA_SUCCESS:
			return updateObject(state, {
				error: null,
				loading: false
			});
		case actions.UPDATE_MASTERDATA_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false
			});
		default:
			return state;
	}
};
export default reducer;