import React, { useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import ProductDetailImage from "../../assets/images/pcd.PNG";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ButtonCustom from '../../common/components/ui/button/button';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import { CONTAINER_DATA, DOCUMENT_CATEORIES, IMAGE_BASE, NETSUITE_IMAGE_DIRECTORY } from '../../common/constants/constants';
import DialogModal from '../../common/components/ui/dialogModal/dialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MultipleSelectChip from './../../common/components/ui/multipleSelect/MultipleSelectChip';
import { accessProductCatalog } from './../../common/utils/utility';

const categories = DOCUMENT_CATEORIES;
const ProductDetails = (props) => {
    const [itemEditData, setItemEditData] = useState(props.location.state.data);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const isEdit = props.location.state.isEdit;
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
	const [showImageUploadModal, setShowImageUploadModal] = useState(false);
	const [showCustomPremixesCategoryModal, setShowCustomPremixesCategoryModal] = useState(false);
    const [uploadDocument, setUploadDocument] = useState({ category: categories[0].category });
    const [uploadImage, setUploadImage] = useState({});
	const [isNoFile, setIsNoFile] = useState(false);
	const [isFileSizeTooBig, setIsFileSizeTooBig] = useState(false);
	const [isWrongImageFormat, setIsWrongImageFormat] = useState(false);
    const [originalImage, setOriginalImage] = useState();
	const [hasUploadedImage, setHasUploadedImage] = useState(false);

	let itemCategoryToolTipHidden = true;
	if (props.location.state.data.custitem_nb_ecom_category == 22) {
		itemCategoryToolTipHidden = false;
	}

	let containerTypeOptions = [];
	let categoryOptions = [];
	let particleSizeOptions = [];
	let flavorOptions = [];
	let medicationTypeOptions = [];
	let odorOptions = [];
	let potencyOptions = [];
	let solubilityOptions = [];
	let speciesOptions = [];
	let medicatedLicenseRequiredOptions = [];
	let masterData = [];
	masterData = useSelector((state) => state.masterData.masterData);

	if (masterData != null) {
		containerTypeOptions = masterData.filter(masterOption => masterOption.dataclass.includes('ContainerType'));
		categoryOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Category'));
		particleSizeOptions = masterData.filter(masterOption => masterOption.dataclass.includes('ParticleSize'));
		flavorOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Flavor'));
		medicationTypeOptions = masterData.filter(masterOption => masterOption.dataclass.includes('MedicationType'));
		odorOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Odor'));
		potencyOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Potency'));
		solubilityOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Solubility'));
		speciesOptions = masterData.filter(masterOption => masterOption.dataclass.includes('Species'));
		medicatedLicenseRequiredOptions = masterData.filter(masterOption => masterOption.dataclass.includes('MedicatedLicenseRequired'));
	}

	const getSpeciesNameValues = (multiSelectVals) => {
		let speciesNames = [];
		if (multiSelectVals && speciesOptions.length) {
			speciesNames = multiSelectVals.split(',').map((speciesID) => speciesOptions.filter(
				(masterOption) => masterOption.id === speciesID)[0].name
			)
		}
		return speciesNames;
	}
	const [speciesMultiVal, setSpeciesMultiVal] = useState(getSpeciesNameValues(props.location.state.data.custitem_nb_ecom_species));


    const openDialog = () => {
        setShowDocumentUploadModal(true);
    }

    const onFileChange = (event) => {

        setUploadDocument({
            ...uploadDocument,
            file: event.target.files[0]
        });
    }  

	const onUploadImageChange = (e) => {
		var image = e.target.files[0];
		var imageFileType = image.type;
		if (imageFileType.includes("jpg") || imageFileType.includes("jpeg") || imageFileType.includes("png")) {
			setIsWrongImageFormat(false);
		} else {
			setIsWrongImageFormat(true);
		}
		if (image.size > 1000000) {
			setIsFileSizeTooBig(true);
		} else {
			setIsFileSizeTooBig(false);
		}
		setUploadImage({
			file: image,
		});
    }

    const onUploadDocument = () => {
        if (!uploadDocument.file) {
            setIsNoFile(true);
        }
        else {
            setIsNoFile(false);
            const formData = new FormData();
            formData.append("ProductId", itemEditData.internalId);
            formData.append("ProductName", itemEditData.storeDisplayName);
            formData.append("SupplierId", userInfo.supplierId);
            formData.append("SupplierName", userInfo.supplierName);
            formData.append("DocumentCategory", uploadDocument.category);
            formData.append("DocumentName", uploadDocument.file.name);
            formData.append("DocumentImage", uploadDocument.file);
            formData.append("LoggedInUserName", userInfo.userName);
            props.onFileUpload(formData);
            handleCloseUploadDocumentModal();
        }
    }

    const handleCloseUploadDocumentModal = () => {
        setShowDocumentUploadModal(false);
    }

	const handleCloseUploadImageModal = () => {
        setShowImageUploadModal(false);
    }

	const handleCancelImageModal = () => {
		setUploadImage({});
		setHasUploadedImage(false);
		setIsFileSizeTooBig(false);
		handleCloseUploadImageModal();
	}
	const handleCloseCustomPremixesCategoryModal = () => {
		setShowCustomPremixesCategoryModal(false);
	}

    const changeHandlerDocumentCategory = (event) => {
        setUploadDocument({
            ...uploadDocument,
            category: event.target.value
        });
    }

    const handleImageReplace = (e) => {
        var reader = new FileReader();
        reader.readAsBinaryString(uploadImage.file);

        reader.onload = function () {
            setUploadImage({
				base64: btoa(reader.result),
				type: uploadImage.file.type.toLowerCase(),
                name: uploadImage.file.name
			});
            setShowImageUploadModal(false);
            setHasUploadedImage(true);
        }
    }

    const modalBodyDocumentUpload = (<div>
        <Col>
            <ValidatorForm onSubmit={() => { }} noValidate autoComplete="off" >
                <Row className="modal-choose-file-row">
                    <Col className='modal-choose-file-button-container'>
                        <ButtonCustom className="button primary" variant="contained" component="label">
                            {!uploadDocument?.file?.name && "Choose File"}
                            {uploadDocument?.file?.name && "Change File"}
                            <input
                                type="file"
                                accept=".pdf"
                                onChange={onFileChange}
                                hidden
                            />
                        </ButtonCustom>
                    </Col>
                    <Col className="upload-file-name-container">
                        {!uploadDocument?.file?.name &&
                            <p className="modal-validation-error">No document selected</p>
                        }
                        {uploadDocument?.file?.name &&
                            <p>{uploadImage?.file?.name}</p>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextValidator id="category" select autoComplete="off"
                            label="Category" variant="outlined"
                            validators={['required']} errorMessages={['Category is required']}
                            value={uploadDocument.category || categories[0].category} onChange={changeHandlerDocumentCategory}>
                            {categories.map((option) => (
                                <MenuItem key={option.category} value={option.category}>
                                    {option.category}
                                </MenuItem>
                            ))}
                        </TextValidator>
                    </Col>
                </Row>

                {isNoFile && !uploadDocument?.file?.name &&
                    (<Row>
                        <Col className="error-msg file-button">
                            Please select a file
                        </Col>
                    </Row>)}
            </ValidatorForm>
        </Col>
    </div>);

    const modalBodyImageUpload = (<div>
        <Col>
            <ValidatorForm onSubmit={() => { }} noValidate autoComplete="off" >
                <Row>
                    <Col>
                        <p>Submit a high resolution .jpg, .jpeg, or .png with dimensions no smaller than 600x600 pixels. Maximum file size: 1 mb.</p>
                    </Col>
                </Row>
                <Row className="modal-choose-file-row">
                    <Col className='modal-choose-file-button-container' sm={12} md={7} lg={6}>
                        <ButtonCustom className="button primary" variant="contained" component="label">
                            {!uploadImage?.file?.name && "Select Image"}
                            {uploadImage?.file?.name && "Change Image"}
                            <input
                                type="file"
                                accept=".png,.jpg,.jpeg"
                                onChange={onUploadImageChange}
                                hidden
                            />
                        </ButtonCustom>
                    </Col>
                    <Col className="upload-file-name-container" sm={12} md={5} lg={6}>
                        {!uploadImage?.file?.name &&
                            <p className="modal-validation-error">No image selected</p>
						}
						{(uploadImage?.file?.name && isWrongImageFormat) &&
							<p className="modal-validation-error">Incorrect file type.  Please select a .jpg, .jpeg, or .png image.</p>
						}
						{(uploadImage?.file?.name && isFileSizeTooBig) &&
							<p className="modal-validation-error">Image file size is too large. Please select an image under 1 mb.</p>
						}
						{uploadImage?.file?.name &&
							<p>{uploadImage?.file?.name}</p>							
                        }
                    </Col>
                </Row>
                {isNoFile && !uploadDocument?.file?.name &&
                    (<Row>
                        <Col className="error-msg file-button">
                            Please select a file
                        </Col>
					</Row>)}
            </ValidatorForm>
        </Col>
	</div>);

	const modalBodyCustomPremixesCategory = (<div className="customPremixesCategoryModal">
		<Col>
			<p>
				The custom premix category must be set by the Nutra Blend Quality Team. Please contact your Nutra Blend Representative to assist with this category change.
			</p>
		</Col>
	</div>);

	const categoryNotEditableToolTip = (<div>
		<p>Items in the 'custom premixes' category cannot be changed.</p>
	</div>);

    const tooltipContent = (<div>
        <p>Item descriptions should be limited to 1,000 characters (not including spaces) and include the following information:</p>
        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
            <p>Identify the product classification</p>
            <p>Identify the product purpose</p>
            <p>Identify the species the item is approved for</p>
            <p>If no SDS and/or Spec Sheet is available, identify the shelf life and/or feed rate of the product.</p>
            <p>Any compliance certifications (i.e. organic)</p>
        </div>
        <p>All product descriptions are reviewed by Nutra Blend and are subject to approval.</p>
    </div>);

	const itemSave = (event) => {
		if (event) event.preventDefault();
		let images = [];
		if (hasUploadedImage) {
			images = [{
				ImageBase64: uploadImage.base64,
				ImageName: uploadImage.name.toLowerCase(),
				ImageMimeType: uploadImage.type.toLowerCase(),
				IsInNetSuite: false
			}]
		}
		else if (itemEditData.images.length) {
			images = [{
				ImageName: itemEditData.images[0].fileName,
				IsInNetSuite: true
			}];
		}
		let speciesIDs = [];
		if (speciesMultiVal) {
			speciesIDs = speciesMultiVal.map((speciesName) => speciesOptions.filter(speciesOption => speciesOption.name === speciesName)[0].id);
		}
		if (!speciesIDs.length){
			speciesIDs = null;
		}

        const data = {
            ProductId: itemEditData.internalId,
            ItemName: itemEditData.name,
            DisplayName: itemEditData.displayName,
            Description: itemEditData.storeDescription,
			ContainerType: itemEditData.containerType && String(itemEditData.containerType),
			Category: itemEditData.custitem_nb_ecom_category && String(itemEditData.custitem_nb_ecom_category),
            Package: itemEditData.packageSize,
			BagsPerPallet: itemEditData.bagsPerPallet && Number(itemEditData.bagsPerPallet),
            CurrentLoggedInUserName: userInfo.userName,
            SupplierId: userInfo.supplierId,
			BagsPerLayer: itemEditData.bagsPerLayer && Number(itemEditData.bagsPerLayer),
            DetailedDescription: itemEditData.detailedDescription,
            Images: images,
            IsImageUpdated: hasUploadedImage,
			layersPerPallet: itemEditData.layersPerPallet && Number(itemEditData.layersPerPallet),
            message: itemEditData.message,
            storeDisplayName: itemEditData.storeDisplayName,
            SupplierName: userInfo.supplierName,
            Placeholder: itemEditData.placeHolder,
			PlaceholderOriginal: originalImage,
			MedicatedLicenseRequired: itemEditData.custitem_nb_ecom_medicated_license_typ && String(itemEditData.custitem_nb_ecom_medicated_license_typ),
			Appearance: itemEditData.custitem_nb_ecom_appearance,
			ParticleSize: itemEditData.custitem_nb_ecom_particle_size && String(itemEditData.custitem_nb_ecom_particle_size),
			BulkDensity: itemEditData.custitem_nb_ecom_bulk_density && String(itemEditData.custitem_nb_ecom_bulk_density),
			Flavor: itemEditData.custitem_nb_ecom_flavors && String(itemEditData.custitem_nb_ecom_flavors),
			MedicationType: itemEditData.custitem_nb_ecom_medication_type && String(itemEditData.custitem_nb_ecom_medication_type),
			Color: itemEditData.custitem_nb_ecom_color && String(itemEditData.custitem_nb_ecom_color),
			Odor: itemEditData.custitem_nb_ecom_odor && String(itemEditData.custitem_nb_ecom_odor),
			Potency: itemEditData.custitem_nb_ecom_potency && String(itemEditData.custitem_nb_ecom_potency),
			Solubility: itemEditData.custitem_nb_ecom_solubility && String(itemEditData.custitem_nb_ecom_solubility),
			Species: speciesIDs && String(speciesIDs),
			FeedingInstructions: itemEditData.feedingInstructions,
			ItemDiscontinued: itemEditData.itemDiscontinued
        };
        props.onProductDetailSave(data);
        props.history.goBack();//back from editing
		props.history.goBack();//back to product catalog
    }

	const changeHandlerCategory = id => (event) => {
		if (event.target.value == 22) {
			setItemEditData({
				...itemEditData,
				[id]: null
			})
			setShowCustomPremixesCategoryModal(true);
		}
		else {
			setItemEditData({
				...itemEditData,
				[id]: event.target.value
			})
		}
	}

	const changeHandlerItemEdit = id => (event) => {
        let value = event.target.value;
		if (event.target.type == "checkbox") {
            value = event.target.checked;
        }

        setItemEditData({
            ...itemEditData,
            [id]: value
        })
    }

    const handleImageDelete = () => {
        setUploadImage({});
        setHasUploadedImage(false);
    }

    const displayImage = () => {
        if (hasUploadedImage) {
            return "data:" + uploadImage.type + ";base64," + uploadImage.base64;//user has set an image to upload
        }
        else if (itemEditData.images.length) {
            return IMAGE_BASE + NETSUITE_IMAGE_DIRECTORY + itemEditData.images[0].fileName;//image loaded directly from NS
        }
        else {
            return IMAGE_BASE + itemEditData.placeHolder//no image set for product, use placeholder instead
        }
    }

    const setTheoriginalImage = () => {
        if (!originalImage) {
            if (itemEditData.images.length) {
                setOriginalImage(itemEditData.images[0].fileName);
            }
        }
    };

    setTheoriginalImage();

    return (
        <Container>
            {accessProductCatalog(userInfo)}
            <Row className="page-header-container">
                <Col>
                    {!isEdit &&
                        <h2 className="page-header">Product Details</h2>
                    }
                    {isEdit &&
                        <h2 className="page-header">Edit Product</h2>
                    }
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
					<Row className="product-details">
						<Col>
							<ValidatorForm className='product-details-form' onSubmit={itemSave} noValidate autoComplete="off" >
							<Row>
								<Col className=" d-flex flex-column align-items-center" sm={12} md={4} lg={5}>
									<Row>
										<Col className="product-details-image-container">
											<Image src={displayImage()} />
										</Col>
									</Row>
									<Row>
										<Col className="product-details-image-button-container">
											{isEdit &&
												(<Row>
													<Col>
													<ButtonCustom className="button secondary add-image-button" variant="contained" disabled={!isEdit} onClick={() => { setShowImageUploadModal(true) }}>
															Add/Change Image
														</ButtonCustom>
													</Col>
												</Row>)
											}
											{isEdit && hasUploadedImage &&
												(<Row>
													<Col>
														<ButtonCustom className="button secondary remove-image-button" variant="contained" disabled={!isEdit} onClick={handleImageDelete}>
															Remove Image
														</ButtonCustom>
													</Col>
												</Row>)
											}
										</Col>
									</Row>
									<Row>
										<Col className="product-detail-contact-info">
											<p>For questions contact:</p>
											<p>{itemEditData.contactName} - {itemEditData.contactEmail}</p>
										</Col>
									</Row>
									{isEdit &&
									<Row>
										<Col>
											<ButtonCustom className="button secondary" variant="contained" onClick={openDialog}>
												Add Document
											</ButtonCustom>
										</Col>
									</Row>
									}
									<Row>
										<Col>
											<ButtonCustom className="button secondary" variant="contained" onClick={() => { props.history.push('/home/e-store/' + itemEditData.internalId, itemEditData) }}>
												View on E-Store
											</ButtonCustom>
										</Col>
									</Row>
								</Col>
								<Col sm={12} md={8} lg={7}>
									<Row>
										<Col>
											<TextValidator disabled id="name"
												label="Number" variant="outlined"
												validators={['required']} errorMessages={['Name is required']}
												value={itemEditData.name || ''} onChange={changeHandlerItemEdit('name')} />
										</Col>
									</Row>
									<Row>
										<Col>
											<TextValidator disabled={!isEdit} id="storeDisplayName"
												label="Display Name" variant="outlined"
												validators={['required']} errorMessages={['Display Name is required']}
												value={itemEditData.storeDisplayName || ''} onChange={changeHandlerItemEdit('storeDisplayName')} />
										</Col>
									</Row>
									<Row>
										<Col>
											<TextField
												id="storeDescription"
												disabled={!isEdit}                                           
												multiline
												rowsMax={15}
												value={itemEditData.storeDescription || ''}
												onChange={changeHandlerItemEdit('storeDescription')}
												variant="outlined"
												label="Description"
												InputProps={{
													endAdornment:
														(<InputAdornment position="end">
															<Tooltip title={tooltipContent} arrow>
																<IconButton
																	aria-label="Description"
																	edge="end"
																>
																	<InfoIcon />
																</IconButton>
															</Tooltip>
														</InputAdornment>)
												}}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<TextValidator disabled={!isEdit} id="containerType" select
												label="Container Type" variant="outlined"
												validators={['required']} errorMessages={['Container Type is required']}
												value={itemEditData.containerType} onChange={changeHandlerItemEdit('containerType')}
												>
													<MenuItem key="" value="">Select Container Type</MenuItem>
												{containerTypeOptions.map((option) => (
													<MenuItem key={option.id} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</TextValidator>
										</Col>
										</Row>
										<Row>
											<Col>
												<TextValidator disabled={!isEdit || props.location.state.data.custitem_nb_ecom_category == 22}
													id="custitem_nb_ecom_category"
													select
													label="Category"
													variant="outlined"
													value={itemEditData.custitem_nb_ecom_category}
													onChange={changeHandlerCategory('custitem_nb_ecom_category')}
													InputProps={{
														endAdornment:
															(<InputAdornment hidden={itemCategoryToolTipHidden} position="end" style={{"margin-right":"30px"}} >
																	<Tooltip title={categoryNotEditableToolTip} arrow>
																		<IconButton
																			aria-label="Description"
																			edge="end"
																		>
																			<InfoIcon />
																		</IconButton>
																	</Tooltip>
																</InputAdornment>)
													}}
												>
													<MenuItem key="" value="">Select Category</MenuItem>
													{categoryOptions.map((option) => (
														<MenuItem key={option.id} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</TextValidator>
											</Col>
										</Row>
									<Row>
										<Col>
											<TextValidator disabled={!isEdit} id="packageSize"
												label="Package(lbs)" variant="outlined"
												validators={['required']} errorMessages={['Package Size is required']}
												value={itemEditData.packageSize || ''} onChange={changeHandlerItemEdit('packageSize')} />
										</Col>
									</Row>
									<Row>
										<Col>
												<TextValidator disabled={!isEdit} id="bagsPerPallet"
												label="Bags Per Pallet" variant="outlined"
												validators={['required']} errorMessages={['Bags Per Pallet is required']}
												value={itemEditData.bagsPerPallet || ''} onChange={changeHandlerItemEdit('bagsPerPallet')} />
										</Col>
									</Row>
									<Row>
										<Col>
												<TextValidator disabled={!isEdit} id="custitem_nb_ecom_medicated_license_typ" select
												label="Medicated License Type" variant="outlined"
													value={itemEditData.custitem_nb_ecom_medicated_license_typ} onChange={changeHandlerItemEdit('custitem_nb_ecom_medicated_license_typ')}
												>
													<MenuItem key="" value="">Select Medicated License Type</MenuItem>
												{medicatedLicenseRequiredOptions.map((option) => (
													<MenuItem key={option.id} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</TextValidator>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormControlLabel id="itemDiscontinued"
												className="CheckboxLabel"
												variant="outlined"
												label="Item Discontinued"
												control={<Checkbox onChange={changeHandlerItemEdit('itemDiscontinued')}
															checked={itemEditData.itemDiscontinued}
															value={itemEditData.itemDiscontinued}
															disabled={!isEdit}
															id="itemDiscontinued" />} />
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col sm={12} md={12} lg={12}>
									<Row>
										<Col>
											<Row>
												<h3 className="section-header">Physical Characteristics</h3>
											</Row>
											<Row>
												<Col>
													<TextValidator disabled={!isEdit} id="custitem_nb_ecom_appearance"
														label="Appearance" variant="outlined"
															value={itemEditData.custitem_nb_ecom_appearance || ''} onChange={changeHandlerItemEdit('custitem_nb_ecom_appearance')} />
												</Col>
											</Row>
											<Row>
												<Col sm={12} md={6} lg={3}>
														<TextValidator disabled={!isEdit} id="custitem_nb_ecom_particle_size" select
															label="Particle Size" variant="outlined"
															value={itemEditData.custitem_nb_ecom_particle_size} onChange={changeHandlerItemEdit('custitem_nb_ecom_particle_size')}
														>
															<MenuItem key="" value="">Select Particle Size</MenuItem>
															{particleSizeOptions.map((option) => (
																<MenuItem key={option.id} value={option.id}>
																	{option.name}
																</MenuItem>
															))}
														</TextValidator>
												
												</Col>
												<Col sm={12} md={6} lg={3}>
														<TextValidator disabled={!isEdit} id="custitem_nb_ecom_bulk_density"
														label="Bulk Density (g/cm3)" variant="outlined"
															value={itemEditData.custitem_nb_ecom_bulk_density || ''} onChange={changeHandlerItemEdit('custitem_nb_ecom_bulk_density')} />

												</Col>
												<Col sm={12} md={6} lg={3}>
														<TextValidator disabled={!isEdit} id="custitem_nb_ecom_flavors" select
															label="Flavor" variant="outlined"
															value={itemEditData.custitem_nb_ecom_flavors} onChange={changeHandlerItemEdit('custitem_nb_ecom_flavors')}
														>
															<MenuItem key="" value="">Select Flavor</MenuItem>
															{flavorOptions.map((option) => (
																<MenuItem key={option.id} value={option.id}>
																	{option.name}
																</MenuItem>
															))}
														</TextValidator>
												</Col>
												<Col sm={12} md={6} lg={3}>
														<TextValidator disabled={!isEdit} id="custitem_nb_ecom_medication_type" select
															label="Medication Type" variant="outlined"
															value={itemEditData.custitem_nb_ecom_medication_type} onChange={changeHandlerItemEdit('custitem_nb_ecom_medication_type')}
														>
															<MenuItem key="" value="">Select Medication Type</MenuItem>
															{medicationTypeOptions.map((option) => (
																<MenuItem key={option.id} value={option.id}>
																	{option.name}
																</MenuItem>
															))}
														</TextValidator>
												</Col>
												<Col sm={12} md={6} lg={3}>
														<TextValidator disabled={!isEdit} id="custitem_nb_ecom_color"
														label="Color" variant="outlined"
															value={itemEditData.custitem_nb_ecom_color || ''} onChange={changeHandlerItemEdit('custitem_nb_ecom_color')} />

												</Col>
												<Col sm={12} md={6} lg={3}>
														<TextValidator disabled={!isEdit} id="custitem_nb_ecom_odor" select
															label="Odor" variant="outlined"
															value={itemEditData.custitem_nb_ecom_odor} onChange={changeHandlerItemEdit('custitem_nb_ecom_odor')}
														>
															<MenuItem key="" value="">Select Odor</MenuItem>
															{odorOptions.map((option) => (
																<MenuItem key={option.id} value={option.id}>
																	{option.name}
																</MenuItem>
															))}
														</TextValidator>
												</Col>
												<Col sm={12} md={6} lg={3}>
														<TextValidator disabled={!isEdit} id="custitem_nb_ecom_potency" select
															label="Potency" variant="outlined"
															value={itemEditData.custitem_nb_ecom_potency} onChange={changeHandlerItemEdit('custitem_nb_ecom_potency')}
														>
															<MenuItem key="" value="">Select Potency</MenuItem>
															{potencyOptions.map((option) => (
																<MenuItem key={option.id} value={option.id}>
																	{option.name}
																</MenuItem>
															))}
														</TextValidator>
												</Col>
												<Col sm={12} md={6} lg={3}>
														<TextValidator disabled={!isEdit} id="custitem_nb_ecom_solubility" select
															label="Solubility" variant="outlined"
															value={itemEditData.custitem_nb_ecom_solubility} onChange={changeHandlerItemEdit('custitem_nb_ecom_solubility')}
														>
															<MenuItem key="" value="">Select Solubility</MenuItem>
															{solubilityOptions.map((option) => (
																<MenuItem key={option.id} value={option.id}>
																	{option.name}
																</MenuItem>
															))}
														</TextValidator>

												</Col>
											</Row>
										</Col>
									</Row>
									<Row>
										<Col>
											<Row>
												<h3 className="section-header">Species</h3>
													<Col>
														<div className="multiSelect">
															<MultipleSelectChip
																label="Species (Select all that apply)"
																multiSelectValue={speciesMultiVal}
																setMultiSelectValue={setSpeciesMultiVal}
																options={speciesOptions}
																	disabled={!isEdit} />
														</div>
												</Col>
											</Row>
										</Col>
									</Row>
									{/*<Row>
										<Col>
											<Row>
												<h3 className="section-header">Feeding Instructions</h3>
												<Col>
													<TextField
														id="feedingInstructions"
														disabled={!isEdit}
														multiline
														rowsMax={15}
														value={itemEditData.feedingInstructions || ''}
														onChange={changeHandlerItemEdit('feedingInstructions')}
														variant="outlined" 
														label="Feeding Instructions"
													/>
												</Col>
											</Row>
										</Col>
									</Row>*/}

									<Row>
										<Col className="product-detail-edit-and-submit-button-container">
											<ButtonCustom className='button secondary' variant="contained" onClick={props.history.goBack}>{(isEdit) ? "Cancel":"Back"}</ButtonCustom>
											{isEdit &&
												(<ButtonCustom className='button primary product-details-save' variant="contained" type='submit'>
													Submit Update
													</ButtonCustom>)
											} 
											{!isEdit &&
												(<ButtonCustom className="button primary" variant="contained" onClick={() => { props.history.push('/home/product/' + itemEditData.internalId, { data: itemEditData, isEdit: true }) }}>
													Edit Item
												</ButtonCustom>)
											}
										</Col>
									</Row>
									</Col>
								</Row>
							</ValidatorForm>
						</Col>
                    </Row>
                    <DialogModal open={showDocumentUploadModal} header='Upload Document' body={modalBodyDocumentUpload}
                        actionButtons={[
                            <ButtonCustom key="cancelbutton" className='button secondary modal-button' onClick={handleCloseUploadDocumentModal}> Cancel
                            </ButtonCustom>,
                            <ButtonCustom key="uploadbutton" className='button primary modal-button' onClick={() => onUploadDocument()}> Upload
                            </ButtonCustom>
                        ]}>
                    </DialogModal>
                    <DialogModal open={showImageUploadModal} header='Upload Image' body={modalBodyImageUpload}
                        actionButtons={[
							<ButtonCustom key="cancelbutton" className='button secondary modal-button reverse' onClick={handleCancelImageModal}> Cancel
                            </ButtonCustom>,
							<ButtonCustom key="uploadbutton" className='button primary modal-button' onClick={handleImageReplace} hidden={!uploadImage?.file?.name || isFileSizeTooBig || isWrongImageFormat}> Upload
                            </ButtonCustom>
                        ]}>
					</DialogModal>
					<DialogModal open={showCustomPremixesCategoryModal} header='Custom Premixes Category' body={modalBodyCustomPremixesCategory}
						actionButtons={[
							<ButtonCustom key="okbutton" className='button primary modal-button' onClick={handleCloseCustomPremixesCategoryModal}> Ok
							</ButtonCustom>
						]}>
					</DialogModal>
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        isAdminAuthenticated: state.auth.adminToken !== null,
		authRedirectPath: state.auth.authRedirectPath,
		masterData: state.masterData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onProductDetailSave: (item) => dispatch(actions.productDetailSave(item)),
        onFileUpload: (file) => dispatch(actions.uploadFile(file))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetails);
