import * as actions from '../actions/actions';
import { updateObject } from '../../common/utils/utility';

const initialState = {
	supplierList: [],
	error: null,
	loading: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_SUPPLIERS_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.GET_SUPPLIERS_SUCCESS:
			return updateObject(state, {
				supplierList: action.supplierList.data,
				error: null,
				loading: false,
			});
		case actions.GET_SUPPLIERS_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		default:
			return state;
	}
};

export default reducer;
