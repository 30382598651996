import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';
import { getPurchaseOrders } from '../../store/actions/purchaseOrders';
import ReactPaginate from 'react-paginate';
import MaterialTable from 'material-table';
import { STATUS_PENDING } from '../../common/constants';
import { formatDate, accessPurchaseOrderManagement } from './../../common/utils/utility';
import { format } from 'date-fns';
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import ButtonCustom from './../../common/components/ui/button/button';

const PurchaseOrderManagement = (props) => {
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [loading, setLoading] = useState(true);
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);
    const [visiblePurchaseOrderList, setVisiblePurchaseOrderList] = useState([]);

    const defaultFilterStatus = 'All'
    const defaultSearchText = ''
    const [searchValue, setSearchValue] = useState(defaultSearchText);
    const [statusFilterValue, setStatusFilterValue] = useState(defaultFilterStatus);
    const [statusFilterList, setStatusFilterList] = useState([]);

    const getPurchaseOrderData = () => {
        getPurchaseOrders(userInfo.supplierId).then(response => {
            setLoading(false);

            let dataSortedByCreatedDate = response.data?.result.sort((a, b) => a.custrecord_nbpoa_po_trandate.split('/').reverse().join().localeCompare(b.custrecord_nbpoa_po_trandate.split('/').reverse().join()));
            let statusFilterList = getUniqueStatusValues(response.data?.result);
            statusFilterList = statusFilterList.sort((a, b) => a.localeCompare(b));

            setStatusFilterList(statusFilterList);
            setPurchaseOrderList(dataSortedByCreatedDate);
        }).catch((err) => {
            const errMsg = 'Something went wrong';
            props.showMessage(err?.response?.data?.error || errMsg);
        });
    };

    useEffect(() => {
        if (userInfo) {
            getPurchaseOrderData();
        }
    }, [userInfo]);

    useEffect(() => {
        filterPurchaseOrders(purchaseOrderList, searchValue, statusFilterValue)
    }, [purchaseOrderList, searchValue, statusFilterValue]);

    const getUniqueStatusValues = (purchaseOrderData) => {
        const uniqueVals = [defaultFilterStatus];
        purchaseOrderData.map(po => {
            if (uniqueVals.indexOf(po.combinedStatus) === -1) {
                uniqueVals.push(po.combinedStatus)
            }
        });
        return uniqueVals;
    }

    const filterPurchaseOrders = (purchaseOrders, searchText, statusSelection) => {
        let visiblePOs = purchaseOrders;
        if (statusSelection != defaultFilterStatus) {
            visiblePOs = visiblePOs?.filter(po => statusSelection === defaultFilterStatus || po.combinedStatus.includes(statusSelection));
        }
        if (searchText != defaultSearchText) {
            visiblePOs = visiblePOs?.filter(po => po.custrecord_nbpoa_po_tranid.includes(searchText));
        }        

        setVisiblePurchaseOrderList(visiblePOs)
    }

    const onSearch = ({ target: { value } }) => {
        setSearchValue(value);
    }

    const onStatusSelect = ({ target: { value } }) => {
        setStatusFilterValue(value);
    }

    const onResetFilters = () => {
        setSearchValue(defaultSearchText);
        setStatusFilterValue(defaultFilterStatus);            
    }

    const renderTable = () => {
        return (
            <React.Fragment>
                <Col>
                    {
                        loading ? (
                            <div className="loadingContainer"></div>
                        ) : (
                            <MaterialTable
                                columns={[
                                        { title: 'Order Number', field: 'custrecord_nbpoa_po_tranid' },
                                        { title: 'Status', field: 'combinedStatus' },
                                        {
                                            title: 'Date Created', field: 'custrecord_nbpoa_po_trandate', render: rowData => {
                                                return rowData.custrecord_nbpoa_po_trandate
                                            },
                                        },
                                        {
                                            title: 'Pickup Date', field: 'custrecord_nbpoa_po_pickup_date', render: rowData => {
                                                return rowData.custrecord_nbpoa_po_pickup_date
                                            },
                                        },
                                        {
                                            title: 'Received Date', field: 'custrecord_nbpoa_po_receipt_date', render: rowData => {
                                                return rowData.custrecord_nbpoa_po_receipt_date
                                            }, 
                                        },
                                        { title: 'Total Weight', field: 'custrecord_nbpoa_cumulative_weight' },
                                ]}
                                    data={visiblePurchaseOrderList}
                                    onRowClick={(event, rowData) => { props.history.push('/home/PurchaseOrderDetail/' + rowData.custrecord_nbpoa_po, { data: rowData, isEdit: false }) }}
                                options={{
                                    filtering: false,
                                    actionsColumnIndex: -1,
                                    search: false,
                                    paging: true,
                                    toolbar: false,
                                    pageSize: 10,
                                    headerStyle: {
                                        backgroundColor: 'gray',
                                        color: '#FFF'
                                    }
                                }}
                            />
                        )
                    }
                </Col>
            </React.Fragment>
        );
    }

    return (
        <Container>
            {accessPurchaseOrderManagement(userInfo)}
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Order Management</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    <Row className="po-management-filter-section">
                        <Col lg={4}>
                            <TextField id="Search" label="Search Order #" placeHolder="Search Order #"
                                variant="outlined" value={searchValue}
                                onChange={(eve) => { onSearch(eve) }} />
                        </Col>
                        <Col lg={4}>
                            <FormControl variant="outlined">
                                <InputLabel>Status</InputLabel>
                                <Select
                                    id="status"
                                    value={statusFilterValue}
                                    onChange={(eve) => { onStatusSelect(eve) }}
                                    label="Status">
                                    {
                                        statusFilterList.map((listItem) => 
                                            <MenuItem value={listItem}>{listItem}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Col>
                        <Col>
                            <ButtonCustom id='searchReset' className='button secondary' variant="contained" onClick={onResetFilters}>Reset</ButtonCustom>
                        </Col>
                    </Row>
                    <Row>
                        {renderTable()}
                    </Row>
                </Col>
            </Row>
        </Container>
    )
};

const mapStateToProps = state => {
    return {
        userInfo: state.auth.userInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseOrderManagement);