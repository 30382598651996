const API_BASE_PATH = '/';

const USER_INFO = 'USER_INFO';
const AUTH_TOKEN = 'AUTH_TOKEN';
const ADMIN_TOKEN = 'ADMIN_TOKEN';
const AUTH_EXPIRATION = 'AUTH_EXPIRATION';
const REFRESH_TOKEN = 'REFRESH_TOKEN';

const DEFAULT_EXPIRATION_SEC = 60 * 60 * 24;

const USER_ROLE_SUPPLIER = 'Supplier';
const CONTAINER_DATA = [
    {
        name: 'Bags',
        internalId: 1
    },
    {
        name: 'Block',
        internalId: 23
    },
    {
        name: 'Box',
        internalId: 25
    },
    {
        name: 'Bulk',
        internalId: 2
    },
    {
        name: 'Bulk-IPP',
        internalId: 3
    },
    {
        name: 'BulkLiq',
        internalId: 17
    },
    {
        name: 'BulkLiq-IPP',
        internalId: 4
    },
    {
        name: 'Case',
        internalId: 5
    },
    {
        name: 'Case-gram',
        internalId: 6
    },
    {
        name: 'CCL-PKG',
        internalId: 7
    },
    {
        name: 'Drum',
        internalId: 19
    },
    {
        name: 'Drum-Liq',
        internalId: 8
    },
    {
        name: 'Each',
        internalId: 24
    },
    {
        name: 'IB',
        internalId: 20
    },
    {
        name: 'LiqNonBulk',
        internalId: 18
    },
    {
        name: 'Liquid',
        internalId: 9
    },
    {
        name: 'MBulk',
        internalId: 26
    },
    {
        name: 'Pallet',
        internalId: 22
    },
    {
        name: 'Pkg',
        internalId: 10
    },
    {
        name: 'PkgTote',
        internalId: 11
    },
    {
        name: 'RHTote',
        internalId: 12
    },
    {
        name: 'Tote',
        internalId: 13
    },
    {
        name: 'Tote-IPP',
        internalId: 14
    },
    {
        name: 'Tote-non bulk',
        internalId: 15
    },
    {
        name: 'ToteBulk',
        internalId: 21
    },
    {
        name: 'Weigh',
        internalId: 16
    },
];

const STATUS_PENDING = 'PENDING';
const STATUS_APPROVED = 'APPROVED';
const STATUS_REJECTED = 'REJECTED';

const DOCUMENT_CATEORIES = [{ category: 'SPEC' }, { category: 'SDS' }, { category: 'LABEL' }, { category: 'OTHER' }];
const SORT_BY = [{ id: 'storeDisplayName', value: 'Item Name' },
                 { id: 'internalId', value: 'Item Number' }];
const WEBSTORE = [{ id: 'all', value: 'All' }, { id: 'onwebstore', value: 'On E-Store' },
                 { id: 'offwebstore', value: 'Off E-Store' }];

const DOCUMENT_SPEC = 'SPEC';
const DOCUMENT_SDS = 'SDS';
let IMAGE_BASE = "";
if (window.location.hostname === "supplierportal.nutrablend.com") {
    IMAGE_BASE = "https://onlineorder.nutrablend.com";
}
else {
    IMAGE_BASE = "https://devonlineorder.nutrablend.net";
}
const NETSUITE_IMAGE_DIRECTORY = "/SSP Applications/NetSuite Inc. - SCA 2019.2/Development/img/Item Images/";

export {
	API_BASE_PATH,
	USER_INFO,
	AUTH_TOKEN,
	ADMIN_TOKEN,
	AUTH_EXPIRATION,
	DEFAULT_EXPIRATION_SEC,
	REFRESH_TOKEN,
    USER_ROLE_SUPPLIER,
    CONTAINER_DATA,
    STATUS_PENDING,
    STATUS_APPROVED,
    STATUS_REJECTED,
    DOCUMENT_CATEORIES,
    DOCUMENT_SPEC,
    DOCUMENT_SDS,
    IMAGE_BASE,
    NETSUITE_IMAGE_DIRECTORY,
    SORT_BY,
    WEBSTORE
};
