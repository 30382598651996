import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import logo from '../../assets/images/NB_logo.jpg';
import './login.css';
import { makeStyles } from '@material-ui/core/styles';
//import Checkbox from '@material-ui/core/Checkbox';
import CheckboxValidatorElement from '../../common/components/ui/checkbox/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ButtonCustom from '../../common/components/ui/button/button';
import DialogModal from '../../common/components/ui/dialogModal/dialog';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { createUser } from '../../store/actions/auth';
import { Row, Col } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

// styling for Textfield
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    textField: {
        margin: '5px',
        width: '100%'
    }
}));


function Login(props) {
    const [signIn, setSignIn] = useState(1);
    const [forgotPassword, setForgotPassword] = useState(0);
    const [signUp, setSignUp] = useState(0);
    const [dialogStateTermsAndConditions, setDialogStateTermsAndConditions] = useState(false);
    const [dialogStateProductCatalogTerms, setDialogStateProductCatalogTerms] = useState(false);
    // validation
    const [signInData, setValues] = useState({ userName: '', password: '' });
    const [forgetPswdData, setForGetValues] = useState({ forgetPassword: '' });
    const initialData = {
        username: '',
        email: '',
        name: '',
        phonenum: '',
        company: '',
        title: '',
        addr1: '',
        addr2: '',
        city: '',
        state: '',
        zip: '',
        productcatalog: false,
        supplieranalytics: false,
        baselevelreporting: false,
        territorymanagement: false,
        purchaseordermanagement: false,
        termsandconditions: false,
        newpass: '',
        confirmpass: ''
    };
    const [signUpData, setSignUpData] = useState(initialData);
    const origURL = props.originalURL;

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== signUpData.newpass) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('isCharacter', (value) => {
            if (!value.replace(/[^A-Za-z]/ig, '')) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('isTermsAndConditions', (value) => {
            if (value === false) {
                return false;
            } 
            return true;
        });

    }, [signUpData.newpass, signUpData.confirmpass, signUpData.city, signUpData.state, signUpData.name, signUpData.termsandconditions]);

    // validation function
    const signInHandleSubmit = (event) => {
        if (event) event.preventDefault();
        props.onAuth(signInData.userName, signInData.password, props.originalURL);
    };
    const changeHandlerSignIn = id => (event) => {
        if (id == 'userName') {
            if (event.currentTarget.value.includes(" ")) {
                event.currentTarget.value = event.currentTarget.value.replace(/\s/g, "");
            }
        }
        setValues({
            ...signInData,
            [id]: event.target.value
        })
    }

    // forget password validation
    const changeHandlerForgetPswd = id => (event) => {
        setForGetValues({
            ...forgetPswdData,
            [id]: event.target.value
        })
    }

    const forGetHandleSubmit = (event) => {
        if (event) event.preventDefault();
        props.onRecoverPswd(forgetPswdData.forgetPassword);
    };

    // sign up validation
    const changeHandlerSignUp = id => (event) => {
        let value = event.target.value;
        if (id == 'username') {
            if (value.includes(" ")) {
                value = value.replace(/\s/g, "");
            }
        }

        if (event.target.type == "checkbox") {
            value = event.target.checked;
        }

        setSignUpData({
            ...signUpData,
            [id]: value
        });
    }

    const userSignUpSubmit = async (event) => {
        if (event) event.preventDefault();
        const signUpObj = {
            UserName: signUpData.username,
            OpenID: signUpData.username,
            Password: signUpData.newpass,
            Email: signUpData.email,
            FullName: signUpData.name,
            PhoneNumber: signUpData.phonenum,
            Company: signUpData.company,
            Title: signUpData.title,
            AddressLine1: signUpData.addr1,
            AddressLine2: signUpData.addr2,
            City: signUpData.city,
            State: signUpData.state,
            Zip: signUpData.zip,
            AccessProductCatalog: signUpData.productcatalog,
            AccessTerritoryManagement: signUpData.territorymanagement,
            AccessPurchaseOrderManagement: signUpData.purchaseordermanagement,
            AccessSupplierAnalytics: signUpData.supplieranalytics,
            AccessSalesOut: signUpData.baselevelreporting,
            AccessInventory: false,
            TermsAndConditions: signUpData.termsandconditions
        }
        try {
            props.createUserStart();
            const result = await createUser(signUpObj);
            setSignUpData(initialData);
            props.createUserSuccess();
            props.showMessage('Request submitted', 'success');
            cancelHandler();
        }
        catch (err) {
            const errMsg = 'Something went wrong';
            props.createUserFail(err?.response?.data?.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        }
    }

    // show hide link
    const signUpHandler = () => {
        setSignIn(0);
        setForgotPassword(0);
        setSignUp(1);
    }

    const forgotPasswordHandler = () => {
        setSignIn(0);
        setForgotPassword(1);
        setSignUp(0);
    }

    const cancelHandler = () => {
        setSignIn(1);
        setForgotPassword(0);
        setSignUp(0);
    }

    const changeHandlerPrdouctCatalogTerms = (event) => {
        if (dialogStateProductCatalogTerms == false) {
            setDialogStateProductCatalogTerms(true);
        }
    }

    const handleProductCatalogTermsModalClose = (event) => {
        setSignUpData({
            ...signUpData,
            "productcatalog": false
        });
        var test = signUpData;
        setDialogStateProductCatalogTerms(false);
    }

    const handleProductCatalogTermsModalAgree = (event) => {
        setSignUpData({
            ...signUpData,
            "productcatalog": true
        });
        var test = signUpData;
        setDialogStateProductCatalogTerms(false);
    }

    const modalBodyProductCatalogTerms = (<div className="productCatalogTermsModal">
        <Col>
            <br/>
            <p>
                I certify all products are manufactured according to FDA's CGMP Regulations for Feeds (Parts 210 & 225) and all requirements of applicable federal and state laws. Our company has written procedures and quality assurance programs to help ensure our feeds do not contain any contaminants or harmful substances that would exceed FDA/EPA tolerances.
            </p>
        </Col>
    </div>);

    const handleTermsAndConditionsModalClose = () => {
        setDialogStateTermsAndConditions(false);
    }

    const modalBodyTermsAndConditions = (<div className="termsAndConditionsModal">
        <Col>
            <p>
                By accessing or using this website you accept and agree to be bound and abide by the Terms of Use located <a href="https://www.landolakesinc.com/terms-of-use" target="_blank">here</a>.
            </p>
            <p>
                The information and data provided on this website are confidential and proprietary to Nutra-Blend. You may not disclose the contents of this website to anyone else without the express, written consent of Nutra-Blend.
            </p>
            <p>
                The information and data provided on this website are for informational purposes only. Nutra Blend makes no warranty whatsoever with respect to the contents of this website and your reliance on the information or data contained in the website is at your own risk.
            </p>
        </Col>
    </div>);

    const termsAndConditionsLabel = () => {
        return (
            <>
                I agree to the <a style={{ 'text-decoration': 'underline' }} onClick={() => { setDialogStateTermsAndConditions(true) }}>terms and conditions</a>.
            </>
        );
    }

    const baseLevelReportingAccessToolTip = (
        <>
            Base Level Reporting
            <InputAdornment position="end" className="checkboxTooltip">
                <Tooltip title={<div><p>Check the box to access your company's Sales Out report.</p></div>} arrow>
                    <IconButton
                        aria-label="Description"
                        edge="end"
                    >
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        </>
    );

    const insightEngineReportingAccessToolTip = (
        <>
            Insight Engine
            <InputAdornment position="end" className="checkboxTooltip">
                <Tooltip title={<div><p>Check the box to access the Insight Engine the real-time data analytics tool. A subscription is required to access this portion of the Supplier Portal.</p></div>} arrow>
                    <IconButton
                        aria-label="Description"
                        edge="end"
                    >
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        </>
    );    

    const productCatalogAccessToolTip = (
        <>
            Manage Product Catalog
            <InputAdornment position="end" className="checkboxTooltip">
                <Tooltip title={<div><p>Check the box to access Product Catalog management which allows you to edit the company's product catalog information as it reflects on the Nutra Blend E-Store.</p></div>} arrow>
                    <IconButton
                        aria-label="Description"
                        edge="end"
                    >
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        </>
    );    

    const territoryManagementAccessToolTip = (
        <>
            Manage Product Catalog
            <InputAdornment position="end" className="checkboxTooltip">
                <Tooltip title={<div><p>Check the box to access the Territory Management tool that allows you to manage your company's  territory assignnments to segment the Insight Engine data and manage your company directory.</p></div>} arrow>
                    <IconButton
                        aria-label="Description"
                        edge="end"
                    >
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        </>
    );

    const purchaseOrderManagementAccessToolTip = (
        <>
            Order Management
            <InputAdornment position="end" className="checkboxTooltip">
                <Tooltip title={<div><p>Check the box to access Order Management which allows you to confirm and edit Nutra Blend's purchase order details.</p></div>} arrow>
                    <IconButton
                        aria-label="Description"
                        edge="end"
                    >
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        </>
    );

    const classes = useStyles();
    if (signIn) {
        return <>
            <div className='homeLogin'>
                <div>
                    <img src={logo} className="App-logo" alt="logo" width='100%' height="auto" />
                    <ValidatorForm className={classes.root} noValidate autoComplete="off" onSubmit={signInHandleSubmit}>
                        <TextValidator className={classes.textField} id="userName"
                            label="Username or Email" variant="outlined"
                            validators={['required']} errorMessages={['The username or email is required']}
                            value={signInData.userName || ''} onChange={changeHandlerSignIn('userName')} />

                        <TextValidator className={classes.textField} id="password"
                            type="password" label="Password"
                            validators={['required']} errorMessages={['The password is required']}
                            variant="outlined" value={signInData.password || ''}
                            onChange={changeHandlerSignIn('password')} />

                        <div className='signInClass'>
                            <ButtonCustom className='signIn button primary' variant="contained" type='submit'>
                                Sign in
                            </ButtonCustom>
                            <div className='linkClass'>
                                <a href="#" onClick={signUpHandler}>Sign-up</a>
                                <a href="#" onClick={forgotPasswordHandler}>Forgot Password</a>
                            </div>
                        </div>
                    </ValidatorForm >
                </div>
            </div>
        </>
    }
    // Forgot password code
    if (forgotPassword) {
        return <>
            <div className='homeLogin' if={forgotPassword}>
                <div>
                    <img src={logo} className="App-logo" alt="logo" width='100%' height="auto" />
                    <ValidatorForm className={classes.root} noValidate autoComplete="off" onSubmit={forGetHandleSubmit}>
                        <h6>Don't worry; just enter your email address and we will send you an email to update your password.</h6>
                        <TextValidator className={classes.textField} id="forgetPassword"
                            label="Email" variant="outlined"
                            validators={['required', 'isEmail']} errorMessages={['Please enter an email address', 'Email address is not valid']}
                            value={forgetPswdData.forgetPassword || ''} onChange={changeHandlerForgetPswd('forgetPassword')} />
                        <div className='signInClass'>
                            <ButtonCustom id="resetPassword" variant="outlined" className="signUp button primary" type='submit'>
                                Recover Password
                            </ButtonCustom>
                            <a href="#" onClick={cancelHandler}>Cancel</a>
                        </div>
                    </ValidatorForm >
                </div>
            </div>
        </>
    }
    if (signUp) {
        return <>
            <div className='homeLogin'>
                <div style={{ width: "850px" }}>
                    <img src={logo} style={{ paddingLeft: "7%" }} className="App-logo" alt="logo" width='90%' height="auto" />
                    <ValidatorForm className={classes.root} noValidate autoComplete="off" onSubmit={userSignUpSubmit}>
                        <Row>
                            <Col>
                                <TextValidator onSubmit={userSignUpSubmit} id="username" className={classes.textField}
                                    label="Username" variant="outlined" name="username"
                                    validators={['required', 'minStringLength:8']} errorMessages={['The username is required', 'Minimum 8 characters are required']}
                                    value={signUpData.username || ''} onChange={changeHandlerSignUp('username')} />
                            </Col>
                            <Col>
                                <TextValidator id="email" className={classes.textField}
                                    label="Email*" variant="outlined" name="email"
                                    validators={['required', 'isEmail']}
                                    errorMessages={['Email is required', 'Email is not valid']}
                                    value={signUpData.email || ''} onChange={changeHandlerSignUp('email')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextValidator id="name" className={classes.textField}
                                    label="Name*" variant="outlined"
                                    validators={['required', 'isCharacter']}
                                    errorMessages={['Username is required', 'Character only']}
                                    value={signUpData.name || ''} onChange={changeHandlerSignUp('name')} />
                            </Col>
                            <Col>
                                <TextValidator id="phonenum" className={classes.textField}
                                    label="Phone Number*" variant="outlined"
                                    validators={['required', 'isNumber']} errorMessages={['Phone Number is required', 'Number only']}
                                    value={signUpData.phonenum || ''} onChange={changeHandlerSignUp('phonenum')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextValidator id="addr1" className={classes.textField}
                                    label="Address Line 1*" variant="outlined"
                                    validators={['required']} errorMessages={['Address Line 1 is required']}
                                    value={signUpData.addr1 || ''} onChange={changeHandlerSignUp('addr1')} />
                            </Col>
                            <Col>
                                <TextValidator id="addr2" className={classes.textField}
                                    label="Address Line 2" variant="outlined"
                                    value={signUpData.addr2 || ''} onChange={changeHandlerSignUp('addr2')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextValidator id="city" className={classes.textField}
                                    label="City*" variant="outlined"
                                    validators={['required', 'isCharacter']} errorMessages={['City is required', 'Character only']}
                                    value={signUpData.city || ''} onChange={changeHandlerSignUp('city')} />
                            </Col>
                            <Col>
                                <TextValidator id="state" className={classes.textField}
                                    label="State*" variant="outlined"
                                    validators={['required', 'isCharacter']} errorMessages={['State is required', 'Character only']}
                                    value={signUpData.state || ''} onChange={changeHandlerSignUp('state')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextValidator id="zip" className={classes.textField}
                                    label="Zip*" variant="outlined"
                                    validators={['required', 'isNumber']} errorMessages={['Zip is required', 'Number only']}
                                    value={signUpData.zip || ''} onChange={changeHandlerSignUp('zip')} />
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextValidator id="company" className={classes.textField}
                                    label="Company*" variant="outlined"
                                    validators={['required', 'isCharacter']} errorMessages={['Company is required', 'Character only']}
                                    value={signUpData.company || ''} onChange={changeHandlerSignUp('company')} />
                            </Col>
                            <Col>
                                <TextValidator id="title" className={classes.textField}
                                    label="Title*" variant="outlined"
                                    validators={['required', 'isCharacter']} errorMessages={['Title is required', 'Character only']}
                                    value={signUpData.title || ''} onChange={changeHandlerSignUp('title')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <h3>Access Request</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <FormControlLabel
                                    id="baseLevelReportingLabel"
                                    className="CheckboxLabel"
                                    variant="outlined"
                                    control={<CheckboxValidatorElement
                                        onChange={changeHandlerSignUp('baselevelreporting')}
                                        checked={signUpData.baselevelreporting}
                                        value={signUpData.baselevelreporting}
                                        label={baseLevelReportingAccessToolTip}
                                        id="baselevelreporting"
                                        />}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <FormControlLabel
                                    id="supplierAnalyticsLabel"
                                    className="CheckboxLabel"
                                    variant="outlined"
                                    control={<CheckboxValidatorElement
                                        onChange={changeHandlerSignUp('supplieranalytics')}
                                        checked={signUpData.supplieranalytics}
                                        value={signUpData.supplieranalytics}
                                        label={insightEngineReportingAccessToolTip}
                                        id="supplieranalytics" />}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <FormControlLabel
                                    id="productCatalogLabel"
                                    className="CheckboxLabel"
                                    variant="outlined"
                                    control={<CheckboxValidatorElement
                                        onClick={() => { changeHandlerPrdouctCatalogTerms('productcatalog') }}
                                        checked={signUpData.productcatalog}
                                        value={signUpData.productcatalog}
                                        label={productCatalogAccessToolTip}
                                        id="productcatalog"
                                    />}
                                />
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col sm={12} md={12} lg={12}>
                                <FormControlLabel
                                    id="territoryManagementLabel"
                                    className="CheckboxLabel"
                                    variant="outlined"
                                    control={<CheckboxValidatorElement
                                        onChange={changeHandlerSignUp('territorymanagement')}
                                        checked={signUpData.territorymanagement}
                                        value={signUpData.territorymanagement}
                                        label={territoryManagementAccessToolTip}
                                        id="territorymanagement"
                                    />}
                                />
                            </Col>
                        </Row>*/}
                       <Row>
                            <Col sm={12} md={12} lg={12}>
                                <FormControlLabel
                                    id="purchaseOrderLabel"
                                    className="CheckboxLabel"
                                    variant="outlined"
                                    control={<CheckboxValidatorElement
                                        onChange={changeHandlerSignUp('purchaseordermanagement')}
                                        checked={signUpData.purchaseordermanagement}
                                        value={signUpData.purchaseordermanagement}
                                        label={purchaseOrderManagementAccessToolTip}
                                        id="purchaseordermanagement"
                                    />}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextValidator id="newpass" className={classes.textField}
                                    type="password" label="New Password*" variant="outlined"
                                    validators={['required']} errorMessages={['The password cannot be empty.']}
                                    value={signUpData.newpass || ''} onChange={changeHandlerSignUp('newpass')} />
                            </Col>
                            <Col>
                                <TextValidator id="confirmpass" className={classes.textField}
                                    type="password" label="Confirm Password*" variant="outlined"
                                    validators={['isPasswordMatch', 'required']} errorMessages={['The entered passwords do not match.', 'The password cannot be empty.']}
                                    value={signUpData.confirmpass || ''} onChange={changeHandlerSignUp('confirmpass')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <p>
                                    <FormControlLabel id="termsAndConditionsLabel" className="CheckboxLabel" variant="outlined" control={<CheckboxValidatorElement validators={['isTermsAndConditions']} errorMessages={['You must agree to the terms and conditions.']} onChange={changeHandlerSignUp('termsandconditions')} checked={signUpData.termsandconditions} value={signUpData.termsandconditions} label={termsAndConditionsLabel()} id="termsandconditions" />} />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>* required fields</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='signUpClass'>
                                    <a href="#" onClick={cancelHandler}>Cancel</a>
                                    <ButtonCustom className='signUp button primary' variant="contained" type='submit'>
                                        Register
                                    </ButtonCustom>
                                </div>
                            </Col>
                        </Row>
                    </ValidatorForm>
                    <DialogModal open={dialogStateTermsAndConditions} header='Terms And Conditions' body={modalBodyTermsAndConditions}
                        actionButtons={[
                            <ButtonCustom key="cancelbutton" className='button primary modal-button' onClick={handleTermsAndConditionsModalClose}> Close
                            </ButtonCustom>
                        ]}>
                    </DialogModal>
                    <DialogModal open={dialogStateProductCatalogTerms} header='Product Catalog Access Agreement' body={modalBodyProductCatalogTerms}
                        actionButtons={[
                            <ButtonCustom key="cancelbutton" className='button primary modal-button' onClick={handleProductCatalogTermsModalClose}> Cancel
                            </ButtonCustom>,
                            <ButtonCustom key="agreeButton" className='button primary modal-button' onClick={handleProductCatalogTermsModalAgree}> I Agree
                            </ButtonCustom>
                        ]}>
                    </DialogModal>
                </div>
            </div>
        </>
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        isAdminAuthenticated: state.auth.adminToken !== null,
        authRedirectPath: state.auth.authRedirectPath,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password, originalURL) => dispatch(actions.auth(email, password, originalURL)),
        onRecoverPswd: (email) => dispatch(actions.recoverPassword(email)),
        createUserStart: () => dispatch(actions.createUserStart()),
        createUserSuccess: () => dispatch(actions.createUserSuccess()),
        createUserFail: () => dispatch(actions.createUserFail()),
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
