import * as actions from './actions';
import { SUPPLIER_API } from '../../common/configs/path.config';
import { http } from '../../common/services/http.service';
import { showMessage } from './message-notification';

export const getProductsStart = () => {
	return {
		type: actions.GET_PRODUCTS_START,
	};
};

export const getProductsSuccess = (products) => {
	return {
		type: actions.GET_PRODUCTS_SUCCESS,
		products: products,
	};
};

export const getProductsFail = error => {
	return {
		type: actions.GET_PRODUCTS_FAIL,
		error: error,
	};
};

export const saveProductDetailStart = () => {
	return {
		type: actions.PRODUCT_DETAILS_START,
	};
};

export const saveProductDetailSuccess = () => {
	return {
		type: actions.PRODUCT_DETAILS_SUCCESS,
	};
};

export const saveProductDetailFail = error => {
	return {
		type: actions.PRODUCT_DETAILS_FAIL,
		error: error,
	};
};

export const getProductRequestsStart = () => {
	return {
		type: actions.GET_PRODUCTS_REQUEST_START,
	};
};

export const getProductRequestsSuccess = (productRequests) => {
	return {
		type: actions.GET_PRODUCTS_REQUEST_SUCCESS,
		productRequests: productRequests,
	};
};

export const getProductRequestsFail = error => {
	return {
		type: actions.GET_PRODUCTS_REQUEST_FAIL,
		error: error,
	};
};

export const getNotificationsStart = () => {
	return {
		type: actions.GET_NOTIFICATIONS_START,
	};
};

export const getNotificationsSuccess = (notifications) => {
	return {
		type: actions.GET_NOTIFICATIONS_SUCCESS,
		notifications: notifications,
	};
};

export const getNotificationsFail = error => {
	return {
		type: actions.GET_NOTIFICATIONS_FAIL,
		error: error,
	};
};

export const uploadFileStart = () => {
	return {
		type: actions.UPLOAD_FILE_START,
	};
};

export const uploadFileSuccess = () => {
	return {
		type: actions.UPLOAD_FILE_SUCCESS,
	};
};

export const uploadFileFail = error => {
	return {
		type: actions.UPLOAD_FILE_FAIL,
		error: error,
	};
};

export const getDocumentsByProductStart = () => {
	return {
		type: actions.GET_DOCUMENTS_BY_PRODUCT_START,
	};
};

export const getDocumentsByProductSuccess = () => {
	return {
		type: actions.GET_DOCUMENTS_BY_PRODUCT_SUCCESS,
	};
};

export const getDocumentsByProductFail = error => {
	return {
		type: actions.GET_DOCUMENTS_BY_PRODUCT_FAIL,
		error: error,
	};
};

export const getProducts = (supplierId) => {
	return dispatch => {
		dispatch(getProductsStart());
		const url = SUPPLIER_API.GET_PRODUCTS + supplierId;
		http
			.get(url)
			.then(response => {
				dispatch(getProductsSuccess(response));
			})
			.catch(err => {
				const errMsg = 'Something went wrong';
				dispatch(getProductsFail(err?.response?.data?.error));
				dispatch(showMessage(err?.response?.data?.error || errMsg));
			});
	};
}

export const productDetailSave = (item) => {
	return dispatch => {
		dispatch(saveProductDetailStart());
		const url = SUPPLIER_API.PRODUCT_DETAILS;
		http
			.post(url, item)
			.then(response => {
				dispatch(saveProductDetailSuccess());
				dispatch(showMessage('Request submitted', 'success'))
			})
			.catch(err => {
				const errMsg = 'Something went wrong';
				dispatch(saveProductDetailFail(err?.response?.data?.error));
				dispatch(showMessage(err?.response?.data?.error || errMsg));
			});
	};
};

export const getProductRequests = (supplierId) => {
	const url = SUPPLIER_API.GET_PRODUCT_REQUESTS + supplierId;
	return http.get(url);
};

export const getNotifications = (supplierId) => {
	return dispatch => {
		dispatch(getNotificationsStart());
		const url = SUPPLIER_API.GET_NOTIFICATIONS + supplierId;
		http
			.get(url)
			.then(response => {
				dispatch(getNotificationsSuccess(response));
			})
			.catch(err => {
				const errMsg = 'Something went wrong';
				dispatch(getNotificationsFail(err?.response?.data?.error));
				dispatch(showMessage(err?.response?.data?.error || errMsg));
			});
	};
}

export const uploadFile = (item) => {
	return dispatch => {
		dispatch(uploadFileStart());
		const url = SUPPLIER_API.UPLOAD_FILE;
		http
			.post(url, item)
			.then(response => {
				dispatch(uploadFileSuccess());
				dispatch(showMessage('File uploaded successfully', 'success'))
			})
			.catch(err => {
				const errMsg = 'Something went wrong';
				dispatch(uploadFileFail(err?.response?.data?.error));
				dispatch(showMessage(err?.response?.data?.error || errMsg));
			});
	};
};

export const getDocumentsByProduct = (productId) => {
	const url = SUPPLIER_API.GET_DOCUMENTS_BY_PRODUCT_ID + productId;
	return http.get(url);
};