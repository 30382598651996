import { Route, BrowserRouter, withRouter, NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Navbar, Nav, Form, NavDropdown } from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import { connect, useSelector } from "react-redux";
import ItemRequests from "./itemRequests/itemRequests";
import UserAccounts from "./userAccounts/userAccounts";
import PendingRequests from "./pendingRequests/pendingRequests";
import userAccountDetails from "./userAccountDetails/userAccountDetails";
import userManagement from "./userManagement/userManagement";
import pendingRequestDetails from "./pendingRequestDetails/pendingRequestDetails";
import userManagementDetails from './userManagementDetails/userManagementDetails';
import documentationDetails from './documentationDetails/documentationDetails';
import AppSettings from "./appSettings/appSettings";

function Admin(props) {

    const userInfo = useSelector((state) => state.auth.userInfo);

    useEffect(() => {
        if (userInfo) {
            props.getSuppliers();
            props.getAllProductRequests();
            props.getMasterData();
        }
    }, [userInfo]);

    function useScrollDirection() {
        const [scrollDirection, setScrollDirection] = useState(null);

        useEffect(() => {
            let lastScrollY = window.pageYOffset;

            const updateScrollDirection = () => {
                const scrollY = window.pageYOffset;
                const direction = scrollY > lastScrollY ? "down" : "up";
                if (direction !== scrollDirection && (scrollY - lastScrollY > 3 || scrollY - lastScrollY < -3)) {
                    setScrollDirection(direction);
                }
                lastScrollY = scrollY > 0 ? scrollY : 0;
            };
            window.addEventListener("scroll", updateScrollDirection); // add event listener
            return () => {
                window.removeEventListener("scroll", updateScrollDirection); // clean up
            }
        }, [scrollDirection]);

        return scrollDirection;
    };

    function Header() {
        const scrollDirection = useScrollDirection();

        return (
            <Navbar bg="light" className={`top-nav ${scrollDirection === "down" ? "nav-hide" : "nav-show"}`} sticky="top" collapseOnSelect expand="custom">
                <div className="top-nav-top">
                    <Navbar.Brand href="/admin">
                        <br />
                    </Navbar.Brand>
                    <Nav className="mr-auto mobile-hide">
                        <Nav className="site-title">Supplier Portal</Nav>
                    </Nav>
                    <Navbar.Toggle />
                    <NavDropdown className="supplier-name mobile-hide" title={userInfo?.fullName}>
                        <NavDropdown.Item onClick={() => { props.onLogout(); }}>SIGN OUT</NavDropdown.Item>
                    </NavDropdown>
                </div>
                <Navbar.Collapse variant="dark" className="bottom-nav">
                    <Nav>
                        <Nav.Link as={NavLink} to="/admin/approveItemRequests">CHANGE REQUESTS</Nav.Link>
                        <NavDropdown title="USERS" bg="dark">
                            <NavDropdown.Item href="/admin/userManagement">USER MANAGEMENT</NavDropdown.Item>
                            <NavDropdown.Item href="/admin/approveUserAccounts">APPROVE USER ACCOUNTS</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={NavLink} to="/admin/documentation">DOCUMENTATION</Nav.Link>
                        <NavDropdown title="ACCOUNT" className="desktop-hide">
                            <NavDropdown.Item onClick={() => { props.onLogout(); }}>SIGN OUT</NavDropdown.Item>
                        </NavDropdown>
                        {/*<Nav.Link as={NavLink} to="/admin/appSettings">APP SETTINGS</Nav.Link>*/}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>     
        );
    };

    return (
        <BrowserRouter>
            {Header()}
            <div className="content-container-outer">
                <div className="content-container">
                    <Route exact path="/admin" component={PendingRequests} />
                    <Route path="/admin/approveItemRequests" component={ItemRequests} />
                    <Route path="/admin/approveUserAccounts" component={UserAccounts} />
                    <Route path="/admin/userAccountDetails/:id" component={userAccountDetails} />
                    <Route path="/admin/userManagement" component={userManagement} />
                    <Route path="/admin/documentation" component={documentationDetails} />
                    <Route path="/admin/pendingRequestDetails/:id" component={pendingRequestDetails} />
                    <Route path="/admin/userManagementDetails/:id" component={userManagementDetails} />
                    <Route path="/admin/appSettings" component={AppSettings} />
                </div>
            </div>
        </BrowserRouter>
    );
}

const mapStateToProps = state => {
	return {
		userInfo: state.auth.userInfo,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onLogout: () => dispatch(actions.logout()),
        getSuppliers: () => dispatch(actions.getSuppliers()),
        getAllProductRequests: () => dispatch(actions.getAllProductRequests()),
        getMasterData: () => dispatch(actions.getMasterData())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Admin));