import 'date-fns';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';
import { getPurchaseOrderDetail, purchaseOrderSave } from '../../store/actions/purchaseOrders';
import MaterialTable from 'material-table';
import { accessPurchaseOrderManagement } from './../../common/utils/utility';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ButtonCustom from '../../common/components/ui/button/button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DialogModal from '../../common/components/ui/dialogModal/dialog';
import { updatedDiff } from 'deep-object-diff';
import { SUPPLIER_API } from '../../common/configs/path.config';

const initialPO = {
    "custrecord_nbpoa_po": "00000",
    "custrecord_nbpoa_pu_info": "pu_info",
    "custrecord_nbpoa_pu_address": "pu_address",
    "custrecord_nbpoa_po_pickup_date": "1/1/2001",
    "custrecord_nbpoa_po_receipt_date": "2/2/2002",
    "custrecord_nbpoa_ack_pickup_date": "3/3/2003",
    "custrecord_nbpoa_ack_receipt_date": "4/4/2004",
    "custrecord_nbpoa_comments": "comments",
    "custrecord_nbpoa_pickup_number": "pickup_number",
    "custrecord_nbpoa_status": "status",
    "custrecord_nbpoa_allow_updates": false,
    "custrecord_nbpoa_po_tranid": "0123456",
    "custrecord_nbpoa_vendor": "1234",
    "custrecord_nbpoa_po_ship_via": "po_ship_via",
    "addresseList": {0:'No Address Found'},
};

const PurchaseOrderDetails = (props) => {
    const userInfo = useSelector((state) => state.auth.userInfo);
    const purchaseOrderID = useParams();
    const [loading, setLoading] = useState(true);
    const [poNotFound, setPONotFound] = useState(false);
    const [showError, setShowError] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessWithoutChanges, setShowSuccessWithoutChanges] = useState(false);
    const [purchaseOrder, setPurchaseOrder] = useState(initialPO);
    const [purcahseOrderLoaded, setPurcahseOrderLoaded] = useState(false);
    const [uomList, setUomList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [showRejectConfirmationModal, setShowRejectConfirmationModal] = useState(false);
    const [showRejectionSuccess, setShowRejectionSuccess] = useState(false);
    const [allSelectedRows, setAllSelectedRows] = useState([]);
    const [userChanges, setUserChanges] = useState(false);
    const [ackPickupDate, setAckPickupDate] = useState(new Date());
    const [ackReceiptDate, setAckReceiptDate] = useState(new Date());

    const getPurchaseOrderData = (supplierID, poID) => {
        setLoading(true);
        getPurchaseOrderDetail(supplierID, poID).then(response => {
            setLoading(false);
            if (response.data.result?.id == null) {
                setPONotFound(true);
            } else {
                setDateValues(response.data.result.custrecord_nbpoa_ack_pickup_date,
                              response.data.result.custrecord_nbpoa_ack_receipt_date,
                              response.data.result.custrecord_nbpoa_po_pickup_date,
                              response.data.result.custrecord_nbpoa_po_receipt_date);
                configureUOMList(response.data.result.uomList);
                configureAddressList(response.data.result.addresseList);
                configurePurchaseOrderItemList(response.data.result.items);
                setPurcahseOrderLoaded(true);
                setPurchaseOrder(response.data.result);
            }
        }).catch((err) => {
            setPONotFound(true);
        });
    };

    useEffect(() => {
        if (userInfo?.supplierId && purchaseOrderID.purchaseOrderID && !purcahseOrderLoaded) {
            getPurchaseOrderData(userInfo.supplierId, purchaseOrderID.purchaseOrderID);
        }
    }, [userInfo, purchaseOrderID]);

    const updatePurchaseOrderData = (supplierID, purchaseOrderData) => {
        setLoading(true);
        purchaseOrderSave(supplierID, purchaseOrderData).then(response => {
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setShowError(err.Message);
        });
    };

    const configureUOMList = (uomData) => {
        var obj = uomData.reduce(function (acc, cur, i) {
            if (cur.id == '') {
                acc['lb_is_blank_in_netsuite'] = cur.name;
            }
            else {
                acc[cur.id] = cur.name;
            }
            return acc;
        }, {});
        setUomList(obj);
    }

    const configurePurchaseOrderItemList = (items) => {
        items.forEach((item, index) => {
            if (item.custrecord_nbpoal_ack_uom == '') {
                /* The value for lb is a blank string in NS, this does not work well with front end controls which act like it is a null, so a temp string is used here, then converted back to blank when saving to NS */
                item.custrecord_nbpoal_ack_uom = 'lb_is_blank_in_netsuite';
            }
        });

        setItemList(items);
    }

    const configureAddressList = (addressData) => {
       /*var obj = addressData.sort((a, b) => a.addrtext > b.addrtext ? 1 : -1).reduce(function (acc, cur, i) {
           //acc[cur.internalid] = cur.addrtext.replace("\n", ", ");
           acc[cur.internalid] = cur.label;
            return acc;
        }, {});*/
        if (addressData) {
            setAddressList(addressData);
        }
    }

    const setDateValues = (ackPickupDate, ackReceiptDate, poPickupDate, poReceiptDate) => {
        let currentDate = format(Date.now(), "M/dd/yyyy");
        let newPickupDate;
        let newReceiptDate;
        if (ackPickupDate == '' || ackPickupDate == null) {
            if (poPickupDate == '' || poPickupDate == null) {
                newPickupDate = currentDate;
            } else {
                newPickupDate = poPickupDate;
            }
        }
        else {
            newPickupDate = ackPickupDate;
        }
        if (ackReceiptDate == '' || ackReceiptDate == null) {
            if (poReceiptDate == '' || poReceiptDate == null) {
                newReceiptDate = currentDate;
            } else {
                newReceiptDate = poReceiptDate;
            }
        }
        else {
            newReceiptDate = ackReceiptDate
        }
        setAckPickupDate(newPickupDate);
        setAckReceiptDate(newReceiptDate);
    }

    const isFieldDisabled = (fieldId, disabledList, allow_updates) => {
       if (!allow_updates) {
            return true;
        }
        let disabled = false;
        if (disabledList && fieldId) {
            if (disabledList.find((field) => field.includes(fieldId))) {
                disabled = true;  
            }
        }
        return disabled;
        return false;
    }

    const isFieldRequired = (fieldId, requiredList) => {
        let required = false;
        if (requiredList && fieldId) {
            if (requiredList.find((field) => field.includes(fieldId))) {
                required = true;
            }
        }
        return required;
    }

    const isButtonHidden = (allow_updates) => {
        if (allow_updates) {
            return false;
        } else {
            return true;
        }
    }

    const changeHandlerPurchaseOrder = id => (event) => {
        setUserChanges(true);

        let value = event.target.value;
        if (event.target.type == "checkbox") {
            value = event.target.checked;
        }

        setPurchaseOrder({
            ...purchaseOrder,
            [id]: value
        });
    }

    const changeHandlerPurchaseOrderItems = (purchaseOrderItems) => {
        setUserChanges(true);

        setItemList(purchaseOrderItems);
    }

    const changeHandlerPurchaseOrderAckPickupDate = (date) => {
        setUserChanges(true);
        setAckPickupDate(format(date, "M/dd/yyyy"));        
    }

    const changeHandlerPurchaseOrderAckReceiptDate = (date) => {
        setUserChanges(true);
        setAckReceiptDate(format(date, "M/dd/yyyy"));
    }

    const itemSave = (event) => {
        if (event) event.preventDefault();

        const items = itemList.map(item => {
            return {
                itemid: item.itemid,
                custrecord_nbpoal_item: item.custrecord_nbpoal_item,
                custrecord_nbpoal_line: item.custrecord_nbpoal_line,
                custrecord_nbpoal_ack_quantity: item.custrecord_nbpoal_ack_quantity,
                custrecord_nbpoal_ack_rate: item.custrecord_nbpoal_ack_rate,
                custrecord_nbpoal_ack_uom: (item.custrecord_nbpoal_ack_uom == 'lb_is_blank_in_netsuite') ? '' : item.custrecord_nbpoal_ack_uom
            }
        });

        const data = {
            id: purchaseOrder.id,
            custrecord_nbpoa_po: purchaseOrder.custrecord_nbpoa_po,
            custrecord_nbpoa_pu_info: purchaseOrder.custrecord_nbpoa_pu_info,
            custrecord_nbpoa_pu_address: purchaseOrder.custrecord_nbpoa_pu_address?.toString(),
            custrecord_nbpoa_ack_pickup_date: ackPickupDate,
            custrecord_nbpoa_ack_receipt_date: ackReceiptDate,
            custrecord_nbpoa_comments: purchaseOrder.custrecord_nbpoa_comments,
            custrecord_nbpoa_pickup_number: purchaseOrder.custrecord_nbpoa_pickup_number,
            custrecord_nbpoa_status: "2",
            custrecord_nbpoa_allow_updates: purchaseOrder.custrecord_nbpoa_allow_updates,
            custrecord_nbpoa_po_tranid: purchaseOrder.custrecord_nbpoa_po_tranid,
            custrecord_nbpoa_vendor: purchaseOrder.custrecord_nbpoa_vendor,
            items: items
        };

        updatePurchaseOrderData(userInfo.supplierId, data);
        if (userChanges) {
            setShowSuccess(true);
        } else {
            setShowSuccessWithoutChanges(true);
        }
    }

    const itemReject = (event) => {
        if (event) event.preventDefault();

        const data = {
            id: purchaseOrder.id,
            custrecord_nbpoa_po: purchaseOrder.custrecord_nbpoa_po,
            custrecord_nbpoa_comments: purchaseOrder.custrecord_nbpoa_comments,
            custrecord_nbpoa_pickup_number: purchaseOrder.custrecord_nbpoa_pickup_number,
            custrecord_nbpoa_status: "5",
            custrecord_nbpoa_po_tranid: purchaseOrder.custrecord_nbpoa_po_tranid,
            custrecord_nbpoa_vendor: purchaseOrder.custrecord_nbpoa_vendor
        };
        updatePurchaseOrderData(userInfo.supplierId, data);
        setShowRejectionSuccess(true);
    }

    const handleRejectButton = () => {
        setShowRejectConfirmationModal(true);
    }

    const handleCloseRejectConfirmationModal = () => {
        setShowRejectConfirmationModal(false);
    }

    const handleConfirmRejection = () => {
        itemReject();
    }

    const handleConfirmButton = () => {
        itemSave();
    }

    const handleViewAllButton = () => {
        props.history.push("/home/PurchaseOrderManagement");
    }

    const renderLoadingContainer = () => {
        return (
            <div className="loadingContainer"></div>
        );
    };

    const renderPONotFound = () => {
        return (
            <div className="poNotFound">
                <h2>Order not found</h2>
            </div>
        );
    };
    
    const modalBodyRejectionConfirmation = (
        <div>
            <Col>
                <p>Are you sure you want to reject this order?</p>
            </Col>
        </div>
    );

    const renderPOForm = () => {
        return (
            <div className="poForm">
                <ValidatorForm onSubmit={handleConfirmButton} noValidate autoComplete="off" >
                    <Row className="justify-content-center">
                        <Col className="">
                            <Row>
                                <Col>
                                    <h3 className="section-header">Order # {purchaseOrder.custrecord_nbpoa_po_tranid || ''} </h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextValidator id="custrecord_nbpoa_pickup_number"
                                        required={isFieldRequired("custrecord_nbpoa_pickup_number", purchaseOrder.requiredFields)}
                                        disabled={isFieldDisabled("custrecord_nbpoa_pickup_number", purchaseOrder.nonEditable, purchaseOrder.custrecord_nbpoa_allow_updates)}
                                        label="Pickup Number (If there is no PU #, please input the Nutra Blend PO #)"
                                        variant="outlined"
                                        errorMessages={['Pickup Number is required']}
                                        value={purchaseOrder.custrecord_nbpoa_pickup_number || ''}
                                        onChange={changeHandlerPurchaseOrder('custrecord_nbpoa_pickup_number')}
                                        
                                        />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {<TextValidator id="custrecord_nbpoa_po_status"
                                        label="Order Status"
                                        variant="outlined"
                                        disabled
                                        value={purchaseOrder.custrecord_nbpoa_po_status || ''}
                                        />}
                                </Col>
                                <Col>
                                    {<TextValidator id="custrecord_nbpoa_status"
                                        label="Acknowledgement Status"
                                        variant="outlined"
                                        disabled
                                        value={purchaseOrder.custrecord_nbpoa_status || ''}
                                    />}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h3 className="section-header">Dates </h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            id="custrecord_nbpoa_ack_pickup_date"
                                            required={isFieldRequired("custrecord_nbpoa_ack_pickup_date", purchaseOrder.requiredFields)}
                                            disabled={isFieldDisabled("custrecord_nbpoa_ack_pickup_date", purchaseOrder.nonEditable, purchaseOrder.custrecord_nbpoa_allow_updates)}
                                            label="Pickup Date"
                                            value={ackPickupDate}
                                            disablePast
                                            onChange={(date) => { changeHandlerPurchaseOrderAckPickupDate(date) }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Col>
                                <Col>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            id="custrecord_nbpoa_ack_receipt_date"
                                            required={isFieldRequired("custrecord_nbpoa_ack_receipt_date", purchaseOrder.requiredFields)}
                                            disabled={isFieldDisabled("custrecord_nbpoa_ack_receipt_date", purchaseOrder.nonEditable, purchaseOrder.custrecord_nbpoa_allow_updates)}                                            
                                            label="Receipt Date"
                                            value={ackReceiptDate}
                                            onChange={(date) => { changeHandlerPurchaseOrderAckReceiptDate(date) }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h3 className="section-header">Pickup Address</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextValidator
                                        id="custrecord_nbpoa_pu_address"
                                        select
                                        label="Pickup Address"
                                        variant="outlined"
                                        required={isFieldRequired("custrecord_nbpoa_pu_address", purchaseOrder.requiredFields)}
                                        disabled={isFieldDisabled("custrecord_nbpoa_pu_address", purchaseOrder.nonEditable, purchaseOrder.custrecord_nbpoa_allow_updates)}
                                        value={purchaseOrder.custrecord_nbpoa_pu_address} onChange={changeHandlerPurchaseOrder('custrecord_nbpoa_pu_address')}
                                    >
                                        <MenuItem key="" value="">Select Pickup Address</MenuItem>
                                        {addressList && addressList.map((option) => (
                                                    <MenuItem key={option.internalid} value={option.internalid}>
                                                        {option.addrtext}
                                                    </MenuItem>))
                                        }
                                    </TextValidator>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h3 className="section-header">Items</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <MaterialTable
                                        columns={[
                                            { title: 'Item Number', field: 'custrecord_nbpoal_item', editable: false },
                                            { title: 'Name', field: 'displayname', editable: false },
                                            { title: 'Price', field: 'custrecord_nbpoal_ack_rate' , type: 'numeric', align: 'left' },
                                            { title: 'Unit Of Measure', field: 'custrecord_nbpoal_ack_uom', lookup: uomList, editable: false },
                                            { title: 'Quantity', field: 'custrecord_nbpoal_ack_quantity', type: 'numeric', align: 'left' },
                                        ]}
                                        data={itemList && itemList.sort((a, b) => a.custrecord_nbpoal_line - b.custrecord_nbpoal_line)}
                                        options={{
                                            filtering: false,
                                            actionsColumnIndex: -1,
                                            search: false,
                                            sorting: false,
                                            paging: false,
                                            toolbar: false,
                                            pageSize: 20,
                                            headerStyle: {
                                                backgroundColor: 'gray',
                                                color: '#FFF'
                                            },
                                        }}
                                        onSelectionChange={(rows) => {
                                            setAllSelectedRows([...rows]);
                                        }}
                                        editable={{
                                            isEditable: (rowData) => !isFieldDisabled("items", purchaseOrder.nonEditable, purchaseOrder.custrecord_nbpoa_allow_updates),
                                            onRowUpdate: (newData, oldData) => new Promise((resolve) => {
                                                const dataUpdateArray = [...itemList];
                                                if (allSelectedRows.length && allSelectedRows.includes(oldData)) {// if there is multiple checkboxes selected
                                                    const updatedData = updatedDiff(oldData, newData);
                                                    allSelectedRows.map((item) => {
                                                        return Object.assign(dataUpdateArray[item.tableData.id], updatedData);
                                                    });
                                                } else {// if there is no selected checkbox, you are editing a non-checked row
                                                    dataUpdateArray[oldData.tableData.id] = newData;
                                                }
                                                changeHandlerPurchaseOrderItems(dataUpdateArray);
                                                resolve();
                                            }),
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h3 className="section-header">Comments</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="custrecord_nbpoa_comments"
                                        required={isFieldRequired("custrecord_nbpoa_comments", purchaseOrder.requiredFields)}
                                        disabled={isFieldDisabled("custrecord_nbpoa_comments", purchaseOrder.nonEditable, purchaseOrder.custrecord_nbpoa_allow_updates)} 
                                        multiline
                                        rowsMax={15}
                                        value={purchaseOrder.custrecord_nbpoa_comments || ''}
                                        onChange={changeHandlerPurchaseOrder('custrecord_nbpoa_comments')}    
                                        variant="outlined"
                                        label="Comments"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="product-detail-edit-and-submit-button-container">
                                    <ButtonCustom className='button secondary' variant="contained" onClick={handleViewAllButton}>
                                        View All Orders
                                    </ButtonCustom>
                                    <ButtonCustom className='button primary' variant="contained" onClick={handleRejectButton} hide={isButtonHidden(purchaseOrder.custrecord_nbpoa_allow_updates)}>
                                        Reject
                                    </ButtonCustom>
                                    <ButtonCustom className='button primary' variant="contained" type='submit' hide={isButtonHidden(purchaseOrder.custrecord_nbpoa_allow_updates)}>
                                        {(userChanges || !purchaseOrder.showConfirmWoChangesButton) ? "Confirm" : "Confirm Without Changes" }
                                    </ButtonCustom>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ValidatorForm>
                <DialogModal open={showRejectConfirmationModal} header='Confirm Rejection' body={modalBodyRejectionConfirmation}
                    actionButtons={[
                        <ButtonCustom key="rejectbutton" className='button secondary modal-button' onClick={handleCloseRejectConfirmationModal}> Back
                        </ButtonCustom>,
                        <ButtonCustom key="uploadbutton" className='button primary modal-button' onClick={() => handleConfirmRejection()}> Reject
                        </ButtonCustom>
                    ]}>
                </DialogModal>
            </div>
        );
    };

    const renderShowError = (errorMessage) => {
        return (
            <div className="">s
                <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                        <h2>There wasn an error processing this request.</h2>\
                        <p>{errorMessage}</p>
                        <br />
                        <Row>
                            <Col className="product-detail-edit-and-submit-button-container">
                                <ButtonCustom className='button primary' variant="contained" onClick={handleViewAllButton}>
                                    View All Orders
                                </ButtonCustom>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    };

    const renderSubmitSuccess = () => {
        return (
            <div className="">
                <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                        <h5>Thank you for submitting updates.  Your Nutra Blend buyer will review and respond accordingly.</h5>
                        <br />

                        <Row>
                            <Col className="product-detail-edit-and-submit-button-container">
                                <ButtonCustom className='button primary' variant="contained" onClick={handleViewAllButton}>
                                    View All Orders
                                </ButtonCustom>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    };

    const renderSubmitWithoutChangesSuccess = () => {
        return (            
            <div className="">
                <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                        <h5>Thank you for accepting the order without changes.  The order has been released in the Nutra Blend system for pick up/delivery.  If you have accepted this order in error, or need to make changes, please contact your Nutra Blend buyer.</h5>
                        <br />

                        <Row>
                            <Col className="product-detail-edit-and-submit-button-container">
                                <ButtonCustom className='button primary' variant="contained" onClick={handleViewAllButton}>
                                    View All Orders
                                </ButtonCustom>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    };

    const renderRejectionSuccess = () => {
        return (
            <div className="">
                <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                        <h5>The order has been rejected.</h5>
                        <br />

                        <Row>
                            <Col className="product-detail-edit-and-submit-button-container">
                                <ButtonCustom className='button primary' variant="contained" onClick={handleViewAllButton}>
                                    View All Orders
                                </ButtonCustom>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <Container>
            {accessPurchaseOrderManagement(userInfo)}
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Order</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner purchase-order-detail">
                    {
                        loading ? (
                            renderLoadingContainer()
                        ) : (
                            (showError != null && showError != '') ? (
                                renderShowError()
                            ) : (
                                showSuccess ? (
                                    renderSubmitSuccess()
                                ) : (
                                    showSuccessWithoutChanges ? (
                                        renderSubmitWithoutChangesSuccess()
                                    ) : (
                                        showRejectionSuccess ? (
                                            renderRejectionSuccess()
                                        ) : (
                                            poNotFound ? (
                                                renderPONotFound()
                                            ) : (
                                                renderPOForm()
                                            )
                                        )    
                                    )
                                )
                            )
                        )
                    }
                </Col>
            </Row>
        </Container>
    )
};

const mapStateToProps = state => {
    return {
        userInfo: state.auth.userInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseOrderDetails);