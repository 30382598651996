import * as actions from './actions';
import { UPDATE_USER_API } from '../../common/configs/path.config';
import { http } from '../../common/services/http.service';
import { showMessage } from './message-notification';

export const updateAccountInfoStart = () => {
	return {
		type: actions.USER_ACCOUNT_DETAILS_START,
	};
};

export const updateAccountInfoSuccess = () => {
	return {
		type: actions.USER_ACCOUNT_DETAILS_SUCCESS,
	};
};

export const updateAccountInfoFail = error => {
	return {
		type: actions.USER_ACCOUNT_DETAILS_FAIL,
		error: error,
	};
};

export const updateUserInfoAfterSuccess = (data) => {
	const updatedObj = {
		id: data.Id,
		userName: data.UserName,
		openId: data.OpenID,
		fullName: data.FullName,
		email: data.Email,
		phoneNumber: data.PhoneNumber,
		officeNumber: data.OfficeNumber,
		addressLine1: data.AddressLine1,
		addressLine2: data.AddressLine2,
		city: data.City,
		state: data.State,
		zip: data.Zip,
		language: null,
		timeZone: null,
    }
	return {
		type: actions.AUTH_UPDATE_USERINFO,
		updatedInfo: updatedObj
	};
};

export const userAccountInfoUpdate = (details) => {
	return dispatch => {
		dispatch(updateAccountInfoStart());
		const url = UPDATE_USER_API.UPDATE_DETAILS;
		http
			.put(url, details)
			.then(response => {
				dispatch(updateAccountInfoSuccess());
				dispatch(updateUserInfoAfterSuccess(details));
				dispatch(showMessage('Account Information Updated', 'success'))
			})
			.catch(err => {
				const errMsg = 'Something went wrong';
				dispatch(updateAccountInfoFail(err?.response?.data?.error));
				dispatch(showMessage(err?.response?.data?.error || errMsg));
			});
	};
};
