import { SHOW_MESSAGE, HIDE_MESSAGE } from './actions';

const hideMessage = () => {
	return {
		type: HIDE_MESSAGE,
	};
};

const showMessage = (
	msg,
	type = 'error',
	duration = 3000
) => {
	return {
		type: SHOW_MESSAGE,
		payload: { msg, type, duration },
	};
};

export { hideMessage, showMessage };
