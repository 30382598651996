import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function DialogModal({ open, header, actionButtons, children, body }) {
    return <>
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle  id="alert-dialog-title">{header}</DialogTitle>
                <DialogContent>
                    { children &&
                    <DialogContentText id="alert-dialog-description">
                        {children}
                    </DialogContentText>
                    }
                    {body}
                </DialogContent>
                <DialogActions key='1'>
                    {actionButtons}
                </DialogActions>
            </Dialog>
        </div>
    </>
}

export default DialogModal;