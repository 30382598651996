import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { STATUS_PENDING } from '../../../common/constants';
import MaterialTable from 'material-table';
import { formatDate } from './../../../common/utils/utility';

const PendingRequests = (props) => {
    const allProducts = useSelector(state => state.admin.allProducts);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        setProducts(allProducts.filter(item => item.status === STATUS_PENDING));
    }, [allProducts]);

    const renderRows = () => {
        const list = products || [];
        return (
            <React.Fragment>
                <MaterialTable
                    columns={[
                        { title: 'Display Name', field: 'storeDisplayName' },
                        {
                            title: 'Requested On', field: 'createdTimestamp',
                            render: row => <span>{formatDate(row["createdTimestamp"])}</span>
                        },
                        { title: 'Requested By', field: 'createdBy' },
                        { title: 'Supplier Name', field: 'supplierName' },
                        
                    ]}
                    data={list}
                    onRowClick={(event, rowData) => { props.history.push('/admin/pendingRequestDetails/' + rowData.productRequestId, rowData) }}
                    options={{
                        filtering: false,
                        actionsColumnIndex: -1,
                        search: false,
                        paging: true,
                        toolbar: false,
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: 'gray',
                            color: '#FFF'
                        },
                    }}
                />

            </React.Fragment>
        );
    }

    return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Pending Change Requests</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    {renderRows()}
                </Col>
            </Row>
        </Container>
    )
};

export default PendingRequests;