import React, { useState, useEffect } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import ButtonCustom from '../../../common/components/ui/button/button';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import * as actions from '../../../store/actions/index';
import { getAllSupplierList } from '../../../store/actions/admin';
import { deleteAccount } from '../../../store/actions/admin';
import MaterialTable from 'material-table';
import DialogModal from '../../../common/components/ui/dialogModal/dialog';
import { STATUS_PENDING } from '../../../common/constants';
import { formatDate } from './../../../common/utils/utility';

const UserManagement = (props) => {
    const [searchData, setSearchData] = useState({
        displayName: '',
        userName: '',
        role: '',
    });
    const [allSupplier, setallSupplier] = useState([]);
    const [allSupplierList, setAllSupplierList] = useState([]);
    
    const [pageValue, setPageValue] = useState({
        offset: 0,
        perPage: 6,
        currentPage: 0,
        pageCount: 0
    });

    const [supplierId, setSupplierId] = useState('');
    const [dialogState, setDialogState] = useState(false);

    const handleSearchDateChange = id => (event) => {
        setSearchData({
            ...searchData,
            [id]: event.target.value
        })
    }

    const getAllSupplier = () => {
        props.getAllSupplierStart();
        getAllSupplierList().then(response => {
            console.log(response.data);
            const sorted = response.data.sort((a, b) => b['fullName'] - a['fullName']);
            console.log(sorted);
            setallSupplier(sorted);
            setAllSupplierList(sorted);
            props.getAllSupplierSuccess(sorted);
        }).catch((err) => {
            const errMsg = 'Something went wrong';
            props.getAllSupplierFail(err.response.data.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        });
    };
    const openDailog = (id) => {
        setDialogState(true);
        setSupplierId(id);
    }
    const handleClose = () => {
        setDialogState(false);
        setSupplierId('');
    }
    const handleDelete = async () => {
        try {
            props.deleteAccountStart();
            await deleteAccount(supplierId);
            props.deleteAccountSuccess();
            props.showMessage('User Deleted', 'success');
            getAllSupplier();
            setDialogState(false);
            setSupplierId('');
        }
        catch (err) {
            const errMsg = 'Something went wrong';
            props.deleteAccountFail(err?.response?.data?.error);
            props.showMessage(err?.response?.data?.error || errMsg);
            setDialogState(false);
            setSupplierId('');
        }
        
    }

    const searchResult = () => {
        let list = [...allSupplierList];
        if (searchData.displayName) {
            const name = searchData.displayName.toLowerCase();
            list = list.filter(item => item.fullName?.toLowerCase().indexOf(name) > -1);
        } if (searchData.userName) {
            const login = searchData.userName.toLowerCase();
            list = list.filter(item => item.userName?.toLowerCase().indexOf(login) > -1);
        } if (searchData.role) {
            const role = searchData.role.toLowerCase();
            list = list.filter(item => item.userRoleId?.toLowerCase().indexOf(role) > -1);
        }
        setallSupplier(list);
    }

    const resetList = () => {
        setSearchData({
            displayName: '',
            userName: '',
            role: ''
        });
        setallSupplier(allSupplierList);
    }

    useEffect(() => {
        getAllSupplier();
    }, []);

    useEffect(() => {
        setPageValue({
            offset: 0,
            perPage: 6,
            currentPage: 0,
            pageCount: Math.ceil(allSupplier?.length / pageValue.perPage)
        });
    }, [allSupplier]);

    const renderRows = () => {
        const list = allSupplier || [];
        const listSorted = list.sort();
        listSorted.map((item, index) => item.status === STATUS_PENDING && listSorted.unshift(listSorted.splice(index, 1)[0]));
        return (
            <React.Fragment>
                <MaterialTable
                    columns={[
                        { title: 'Full name', field: 'fullName' },
                        { title: 'Company Name', field: 'supplierName' },
                        { title: 'Roles', field: 'userRoleId' },
                        {
                            title: 'Last login', field: 'lastLogin',
                            render: row => <span>{formatDate(row["lastLogin"])}</span>
                        },
                        {
                            title: 'Active', field: 'isActive',
                            render: row => <span>{row["isActive"] == true ? 'Yes' : 'No'}</span>
                        },
                       
                    ]}
                    data={listSorted}
                    onRowClick={(event, rowData) => { props.history.push('/admin/userManagementDetails/' + rowData.id, { rowData }) }}
                    options={{
                        filtering: false,
                        search: false,
                        paging: true,
                        toolbar: false,
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: 'gray',
                            color: '#FFF'
                        },
                    }}
                />

            </React.Fragment>
        );
    }

    return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">User Management</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    <Row>
                        <Col sm={12} md={8} lg={9}>
                            <form noValidate autoComplete="off">
                                <Row>
                                    <Col sm={12} md={4} lg={4}>
                                        <TextField id="displayName" label="Display Name"
                                            variant="outlined" value={searchData.displayName || ''}
                                            onChange={handleSearchDateChange('displayName')} />
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                        <TextField id="userName" label="User Name"
                                            variant="outlined" value={searchData.userName || ''}
                                            onChange={handleSearchDateChange('userName')} />
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                        <TextField id="role" label="Role"
                                            variant="outlined" value={searchData.role || ''}
                                            onChange={handleSearchDateChange('role')} />
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                        <Col sm={12} md={4} lg={3}>
                            <ButtonCustom id='searchId' className='button primary' variant="contained" onClick={searchResult}>Search</ButtonCustom>
                            <ButtonCustom id='searchReset' className='button secondary' variant="contained" onClick={resetList}>Reset</ButtonCustom>
                        </Col>
                        <Col>
                            {renderRows()}
                        </Col>
                        <DialogModal open={dialogState} header='Confirmation'
                            actionButtons={[
                                <ButtonCustom className='button secondary' onClick={() => handleDelete()}> Ok
                                </ButtonCustom>,
                                <ButtonCustom className='button success' onClick={handleClose}> Cancel
                                </ButtonCustom>
                            ]}>
                        Are you sure you want to delete this?
                        </DialogModal>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
};
const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getAllSupplierStart: () => dispatch(actions.getAllSupplierStart()),
        getAllSupplierSuccess: (data) => dispatch(actions.getAllSupplierSuccess(data)),
        getAllSupplierFail: () => dispatch(actions.getAllSupplierFail()),
        deleteAccountStart: () => dispatch(actions.deleteAccountStart()),
        deleteAccountSuccess: () => dispatch(actions.deleteAccountSuccess()),
        deleteAccountFail: () => dispatch(actions.deleteAccountFail()),
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserManagement);
