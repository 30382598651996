import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import authReducer from './reducers/auth';
import supplierReducer from './reducers/supplier';
import messageReducer from './reducers/message-notification';
import productsReducer from './reducers/products';
import userAccountInfoReducer from './reducers/userAccountInfo';
import adminReducer from './reducers/admin';
import masterDataReducer from './reducers/masterData';

const rootReducer = combineReducers({
	auth: authReducer,
    suppliers: supplierReducer,
    message: messageReducer,
    products: productsReducer,
    userAccountInfo: userAccountInfoReducer,
    admin: adminReducer,
    masterData: masterDataReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(thunk))
);

export { store };