import { SHOW_MESSAGE, HIDE_MESSAGE } from '../actions/actions';
import { updateObject } from '../../common/utils/utility';

const initialState = {
	showMessage: false,
	message: '',
	type: '',
	duration: 3000,
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_MESSAGE:
			return updateObject(state, {
				message: action.payload?.msg,
				type: action.payload?.type,
				showMessage: true,
				duration: action.payload?.duration,
			});
		case HIDE_MESSAGE:
			return updateObject(state, {
				message: '',
				type: '',
				showMessage: false,
				duration: 3000,
			});
		default:
			return state;
	}
};
export default reducer;
