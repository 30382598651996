import * as actions from '../actions/actions';
import { updateObject } from '../../common/utils/utility';

const initialState = {
	error: null,
	loading: false,
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.USER_ACCOUNT_DETAILS_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.USER_ACCOUNT_DETAILS_SUCCESS:
			return updateObject(state, {
				products: action.products,
				error: null,
				loading: false,
			});
		case actions.USER_ACCOUNT_DETAILS_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		default:
			return state;
	}
};
export default reducer;
