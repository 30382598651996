import * as actions from '../actions/actions';
import { updateObject } from '../../common/utils/utility';

const initialState = {
	products: [],
	productRequests: [],
	notifications: [],
    error: null,
	loading: false,
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_PRODUCTS_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.GET_PRODUCTS_SUCCESS:
			return updateObject(state, {
				products: action.products.data,
				error: null,
				loading: false,
			});
		case actions.GET_PRODUCTS_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case actions.PRODUCT_DETAILS_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.PRODUCT_DETAILS_SUCCESS:
			return updateObject(state, {
				error: null,
				loading: false,
			});
		case actions.PRODUCT_DETAILS_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case actions.GET_PRODUCTS_REQUEST_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.GET_PRODUCTS_REQUEST_SUCCESS:
			return updateObject(state, {
				productRequests: action.productRequests,
				error: null,
				loading: false,
			});
		case actions.GET_PRODUCTS_REQUEST_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case actions.GET_NOTIFICATIONS_START:
			return updateObject(state, {
				error: null,
			});
		case actions.GET_NOTIFICATIONS_SUCCESS:
			return updateObject(state, {
				notifications: action.notifications.data,
				error: null,
			});
		case actions.GET_NOTIFICATIONS_FAIL:
			return updateObject(state, {
				error: action.error,
			});
		case actions.UPLOAD_FILE_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.UPLOAD_FILE_SUCCESS:
			return updateObject(state, {
				error: null,
				loading: false,
			});
		case actions.UPLOAD_FILE_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case actions.GET_DOCUMENTS_BY_PRODUCT_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.GET_DOCUMENTS_BY_PRODUCT_SUCCESS:
			return updateObject(state, {
				error: null,
				loading: false,
			});
		case actions.GET_DOCUMENTS_BY_PRODUCT_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		default:
			return state;
	}
};
export default reducer;
