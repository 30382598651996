import * as actions from '../actions/actions';
import { updateObject } from '../../common/utils/utility';

const initialState = {
	pendingSupplier: [],
	allProducts: [],
	error: null,
	loading: false,
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_PENDING_SUPPLIER_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.GET_PENDING_SUPPLIER_SUCCESS:
			return updateObject(state, {
				pendingSupplier: action.data,
				error: null,
				loading: false,
			});
		case actions.GET_PENDING_SUPPLIER_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case actions.UPDATE_PENDING_SUPPLIER_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.UPDATE_PENDING_SUPPLIER_SUCCESS:
			return updateObject(state, {
				error: null,
				loading: false,
			});
		case actions.UPDATE_PENDING_SUPPLIER_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case actions.GET_ALLSUPPLIERS_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.GET_ALLSUPPLIERS_SUCCESS:
			return updateObject(state, {
				error: null,
				loading: false,
			});
		case actions.GET_ALLSUPPLIERS_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		
		case actions.GET_ALL_PRODUCTS_REQUEST_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.GET_ALL_PRODUCTS_REQUEST_SUCCESS:
			return updateObject(state, {
				allProducts: action.allProducts,
				error: null,
				loading: false,
			});
		case actions.GET_ALL_PRODUCTS_REQUEST_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case actions.GET_USER_ROLE_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.GET_USER_ROLE_SUCCESS:
			return updateObject(state, {
				error: null,
				loading: false,
			});
		case actions.GET_USER_ROLE_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case actions.EDIT_USER_ACCOUNT_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.EDIT_USER_ACCOUNT_SUCCESS:
			return updateObject(state, {
				error: null,
				loading: false,
			});
		case actions.EDIT_USER_ACCOUNT_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case actions.EDIT_PRODUCT_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.EDIT_PRODUCT_SUCCESS:
			return updateObject(state, {
				error: null,
				loading: false,
			});
		case actions.EDIT_PRODUCT_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case actions.DELETE_USER_ACCOUNT_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.DELETE_USER_ACCOUNT_SUCCESS:
			return updateObject(state, {
				error: null,
				loading: false,
			});
		case actions.DELETE_USER_ACCOUNT_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
			case actions.GET_ALLDOCUMENT_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.GET_ALLDOCUMENT_SUCCESS:
			return updateObject(state, {
				error: null,
				loading: false,
			});
		case actions.GET_ALLDOCUMENT_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
			case actions.GET_SUPPLIERDOCUMENT_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case actions.GET_SUPPLIERDOCUMENT_SUCCESS:
			return updateObject(state, {
				error: null,
				loading: false,
			});
		case actions.GET_SUPPLIERDOCUMENT_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
			case actions.GET_PDFDOCUMENT_START:
				return updateObject(state, {
					error: null,
					loading: true,
				});
			case actions.GET_PDFDOCUMENT_SUCCESS:
				return updateObject(state, {
					error: null,
					loading: false,
				});
			case actions.GET_PDFDOCUMENT_FAIL:
				return updateObject(state, {
					error: action.error,
					loading: false,
				});
		default:
			return state;
	}
};
export default reducer;