import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { getAllDocumentList, pdfDocument } from '../../../store/actions/admin';
import MaterialTable from 'material-table';
import { formatDate } from './../../../common/utils/utility';


const DocumentationDetails = (props) => {
    const [allDocumentList, setAllDocumentList] = useState([]);

    const getAllDocuments = () => {
        props.getAllDocumentStart();
        getAllDocumentList().then(response => {
            setAllDocumentList(response.data);
            props.getAllDocumentSuccess(response.data);
        }).catch((err) => {
            const errMsg = 'Something went wrong';
            props.getAllDocumentFail(err.response.data.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        });
    };

    const openPDF = (id) => {
        props.getPDFDownloadByIDStart();
        pdfDocument(id).then(response => {
            if (response && response.data.documentImage) {
                const arrayBuffer = base64ToArrayBuffer(response.data.documentImage);
                createAndDownloadBlobFile(arrayBuffer, response.data.documentName);
                props.getPDFDownloadByIDSuccess(response.data);

            } else {
                props.showMessage('No Document Found');
                props.getPDFDownloadByIDSuccess(response.data);
            }
        }).catch((err) => {
            const errMsg = 'Something went wrong';
            props.getPDFDownloadByIDFail(err.response.data.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        });
    }

    const base64ToArrayBuffer = (base64) => {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    const createAndDownloadBlobFile = (body, filename) => {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    useEffect(() => {
        getAllDocuments();
    }, []);

    const renderRows = () => {
        const list = allDocumentList || [];
        return (
            <React.Fragment>
                <MaterialTable
                    columns={[
                        { title: 'Category', field: 'documentCategory' },
                        { title: 'Item name', field: 'productName' },
                        { title: 'Item No', field: 'itemNumber' },
                        { title: 'File name', field: 'documentName' },
                        { title: 'Submitted by', field: 'modifiedBy' },
                        { title: 'Supplier', field: 'supplierName' },
                        {
                            title: 'Date Uploaded', field: 'modifiedDateTime',
                            render: row => <span>{formatDate(row["modifiedDateTime"])}</span>
                        }

                    ]}
                    data={list}
                    actions={[
                        {
                            tooltip: 'Download All Selected Documents',
                            icon: 'download',
                            onClick: (evt, data) => {
                                data.forEach(element => openPDF(element.documentId))
                            }
                        }
                    ]}
                    options={{
                        selection: true,
                        filtering: false,
                        actionsColumnIndex: -1,
                        search: false,
                        paging: true,
                        toolbar: false,
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: 'gray',
                            color: '#FFF'
                        },
                    }}
                    title=""
                />

            </React.Fragment>
        );
    }

    return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Document Library</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    {renderRows()}
                </Col>
            </Row>
        </Container>
    )
};
const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getAllDocumentStart: () => dispatch(actions.getAllDocumentStart()),
        getAllDocumentSuccess: (data) => dispatch(actions.getAllDocumentSuccess(data)),
        getAllDocumentFail: () => dispatch(actions.getAllDocumentFail()),
        getPDFDownloadByIDStart: () => dispatch(actions.getPDFDownloadByIDStart()),
        getPDFDownloadByIDSuccess: (data) => dispatch(actions.getPDFDownloadByIDSuccess(data)),
        getPDFDownloadByIDFail: () => dispatch(actions.getPDFDownloadByIDFail()),
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentationDetails);
