import MaterialTable from 'material-table';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';
import { getSupplierDocumentList, pdfDocument } from '../../store/actions/admin';
import { formatDate } from './../../common/utils/utility';

const Documentation = (props) => {
    const userInfo = useSelector((state) => state.auth.userInfo);

    useEffect(() => {
        if (userInfo) {
            getSupplierDocuments(userInfo.supplierId);
        }
    }, [userInfo]);

    const [supplierDocumentList, setSupplierDocumentList] = useState([]);

    const getSupplierDocuments = (id) => {
        props.getSupplierDocumentStart();
        getSupplierDocumentList(id).then(response => {
            setSupplierDocumentList(response.data);
            props.getSupplierDocumentSuccess(response.data);
        }).catch((err) => {
            const errMsg = 'Something went wrong';
            props.getSupplierDocumentFail(err.response.data.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        });
    };

    const openPDF = (id) => {
        props.getPDFDownloadByIDStart();
        pdfDocument(id).then(response => {
            if (response && response.data.documentImage) {
                const arrayBuffer = base64ToArrayBuffer(response.data.documentImage);
                createAndDownloadBlobFile(arrayBuffer, response.data.documentName);
                props.getPDFDownloadByIDSuccess(response.data);

            } else {
                props.showMessage('No Document Found');
                props.getPDFDownloadByIDSuccess(response.data);
            }
        }).catch((err) => {
            const errMsg = 'Something went wrong';
            props.getPDFDownloadByIDFail(err.response.data.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        });
    }

    const base64ToArrayBuffer = (base64) => {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
      }

      const createAndDownloadBlobFile = (body, filename) => {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }

    const renderRows = () => {
        const list = supplierDocumentList || [];
        return (
            <React.Fragment>
                <MaterialTable
                    columns={[
                        { title: 'Category', field: 'documentCategory' },
                        { title: 'Item name', field: 'productName' },
                        { title: 'Item No', field: 'itemNumber' },
                        { title: 'File name', field: 'documentName' },
                        {
                            title: 'Date Uploaded', field: 'modifiedDateTime',
                            render: row => <span>{formatDate(row["modifiedDateTime"])}</span>
                        }
                    ]}
                    data={list}
                    actions={[
                        {
                            icon: 'visibility',
                            tooltip: 'View',
                            iconProps: { style: { color: "#28a745" } },
                            onClick: (event, rowData) => { openPDF(rowData.documentId) }
                        },

                    ]}
                    options={{
                        filtering: false,
                        actionsColumnIndex: -1,
                        search: false,
                        paging: true,
                        toolbar: false,
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: 'gray',
                            color: '#FFF'
                        },
                    }}
                />

            </React.Fragment>
        );
    }

    return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Documentation</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    {renderRows()}
                </Col>
            </Row>
        </Container>
    )
}
const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getSupplierDocumentStart: () => dispatch(actions.getSupplierDocumentStart()),
        getSupplierDocumentSuccess: (data) => dispatch(actions.getSupplierDocumentSuccess(data)),
        getSupplierDocumentFail: () => dispatch(actions.getSupplierDocumentFail()),
        getPDFDownloadByIDStart: () => dispatch(actions.getPDFDownloadByIDStart()),
        getPDFDownloadByIDSuccess: (data) => dispatch(actions.getPDFDownloadByIDSuccess(data)),
        getPDFDownloadByIDFail: () => dispatch(actions.getPDFDownloadByIDFail()),
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Documentation);