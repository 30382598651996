import React from 'react';
import './button.css';
import Button from '@material-ui/core/Button';
import { StylesProvider } from "@material-ui/core/styles";


function button(props) {
    return <>
        <StylesProvider injectFirst>
            <Button {...props}>{props.children}</Button>
        </StylesProvider>
    </>
}

export default button;