import * as actions from './actions';
import { ADMIN_API } from '../../common/configs/path.config';
import { http } from '../../common/services/http.service';
import { showMessage } from './message-notification';

export const GetSuppliersStart = () => {
	return {
		type: actions.GET_SUPPLIERS_START,
	};
};

export const GetSuppliersSuccess = (supplierData) => {
	return {
		type: actions.GET_SUPPLIERS_SUCCESS,
		supplierList: supplierData,
	};
};

export const GetSuppliersFail = error => {
	return {
		type: actions.GET_SUPPLIERS_FAIL,
		error: error,
	};
};

export const getSuppliers = () => {
	return dispatch => {
		dispatch(GetSuppliersStart());
		const url = ADMIN_API.GET_SUPPLER_LIST;
		http
			.get(url)
			.then(response => {
				dispatch(GetSuppliersSuccess(response));
			})
			.catch(err => {
				const errMsg = 'Something went wrong';
				dispatch(GetSuppliersFail(err?.response?.data?.error));
				dispatch(showMessage(err?.response?.data?.error || errMsg));
			});
	};
}
