import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, Form, FormControl, Nav, Tab } from 'react-bootstrap';
import Img from "../../assets/images/PC.PNG";
import ButtonCustom from '../../common/components/ui/button/button';
import { useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { Tooltip } from "@material-ui/core";
import MaterialTable from 'material-table';
import { IMAGE_BASE, SORT_BY, WEBSTORE, CONTAINER_DATA } from '../../common/constants/constants';
import { MenuItem } from '@material-ui/core';
import Select from "@material-ui/core/Select";
import FormControl1 from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { accessProductCatalog } from './../../common/utils/utility';

function ProductCatalog(props) {
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [loading, setLoading] = useState(true);

    let containerTypeOptions = [];
    let masterData = [];
	masterData = useSelector((state) => state.masterData.masterData);
    if (masterData != null) {
        containerTypeOptions = masterData.filter(masterOption => masterOption.dataclass.includes('ContainerType'));
    }

    const getContainerName = (containerId) => {
        if (containerId && masterData) {
            return containerTypeOptions.find((item) => {
                return item.id === containerId;
            })?.name;
        }
        return '';
    }

    let sortby = SORT_BY;
    let webstore = WEBSTORE;
    const [firstView, setfirstView] = useState(1);
    const [secondView, setsecondView] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [pageValue, setPageValue] = useState({
        offset: 0,
        perPage: 6,
        currentPage: 0,
        pageCount: 0
    });
    const [pageNumber, setPageNumber] = useState(0);
    const products = useSelector((state) => state.products.products);
    const [pageCount, setPageCount] = useState(Math.ceil(products?.length / pageValue.perPage));

    useEffect(() => {
        setPageValue({
            ...pageValue,
            pageCount
        })
    }, [pageCount]);

    const [list, setList] = useState([]);
    useEffect(() => {
        setList(products.slice());
        setLoading(false);
    }, [products]);

    // show hide link
    const setFirstViewHandler = () => {
        setfirstView(1);
        setsecondView(0);
    }

    const setSecondViewHandler = () => {
        setfirstView(0);
        setsecondView(1);
    }

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        const offset = selected * pageValue.perPage;
        setPageValue({
            ...pageValue,
            offset: offset,
            pageCount: pageCount,
            currentPage: selected
        });
    };

    const onSearch = ({ target: { value } }) => {
        setList(products?.filter(item => value === '' || item.storeDisplayName.toLowerCase().includes(value.toLowerCase()) || item.name.toLowerCase().includes(value.toLowerCase())) || []);
        handlePageClick({ selected: 0 });
        setSearchValue(value);
    }

    useEffect(() => {
        setPageCount(Math.ceil(list?.length / pageValue.perPage));
    }, [list]);

    //console.log(list);
    const onFilter = id => (event) => {
        let selectedValue = event.target.value;
        if (selectedValue == 'all') {
            setList(products || []);
            handlePageClick({ selected: 0 });
            //setSearchValue('all');
        } else if (selectedValue == 'onwebstore') {
            setList(products?.filter(item => item.onwebstore == true) || []);
            handlePageClick({ selected: 0 });
            //setSearchValue('onwebstore');
        } else if (selectedValue == 'offwebstore') {
            setList(products?.filter(item => item.onwebstore == false) || []);
            handlePageClick({ selected: 0 });
            //setSearchValue('offwebstore');
        }
    }

    const handleSearchDataChange = id => (event) => {
        const selectedSortBy = event.target.value;

        if (selectedSortBy == 'storeDisplayName') {
            list.sort((a, b) => a.storeDisplayName > b.storeDisplayName ? 1 : -1);
        }
        else if (selectedSortBy == 'internalId') {
            list.sort((a, b) => a.internalId > b.internalId ? 1 : -1);
        }

        const selected = 0;
        setPageNumber(selected);
        const offset = selected * pageValue.perPage;
        setPageValue({
            ...pageValue,
            offset: offset,
            pageCount: pageCount,
            currentPage: selected
        });
    }

    const renderTiles = () => {
        if (list && list.length > 0) {
            const sliceData = list.slice(pageValue.offset, pageValue.offset + pageValue.perPage);
            return sliceData.map((item, ind) => {
                let img = "";
                if (item.images != null && item.images.length > 0) {
                    img = IMAGE_BASE + "/SSP Applications/NetSuite Inc. - SCA 2019.2/Development/img/Item Images/" + item.images[0].fileName
                }
                else {
                    img = IMAGE_BASE + item.placeHolder
                }
                return (
                    <React.Fragment key={item.internalId}>
                        <Col sm={12} md={6} lg={4}>
                            {
                                loading ? (
                                    <div className="loadingContainer"></div>
                                ) : (
                                    <Container className="product-tile-container">
                                        <Row>
                                            <Col className="product-tile" onClick={() => { props.history.push('/home/product/' + item.internalId, { data: item, isEdit: false }) }}>
                                                <Row>
                                                    <Col className="product-tile-image-container">
                                                        <Image src={img} className={"img-fluid"} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h5 className="product-tile-name">{item.storeDisplayName}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="product-tile-label">
                                                        <span>Number:</span>
                                                    </Col>
                                                    <Col className="product-tile-detail">
                                                        <span>{item.name}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="product-tile-label">
                                                        <span>Container:</span>
                                                    </Col>
                                                    <Col className="product-tile-detail">
                                                        <span>{getContainerName(item.containerType)}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="product-tile-label">
                                                        <span>Weight (lbs):</span>
                                                    </Col>
                                                    <Col className="product-tile-detail">
                                                        <span>{item.packageSize}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                            }
                        </Col>
                    </React.Fragment >
                );
            });
        } else {
            return (<div className="loadingContainer"></div>);
        }
    }


    const renderRows = () => {
        const list = products?.filter(item => searchValue === '' || item.displayName.toLowerCase().includes(searchValue.toLowerCase())) || [];
        return (
            <React.Fragment>
                <Col>
                    {
                        loading ? (
                            <div className="loadingContainer"></div>
                        ) : (
                            <MaterialTable
                                columns={[
                                    {
                                        title: 'Image', field: 'images', render: rowData => {
                                            let img = "";
                                            if (rowData.images.length > 0) {
                                                img = IMAGE_BASE + "/SSP Applications/NetSuite Inc. - SCA 2019.2/Development/img/Item Images/" + rowData.images[0].fileName
                                            }
                                            else {
                                                img = IMAGE_BASE + rowData.placeHolder
                                            }
                                            return (<img
                                                style={{ height: '80px' }}
                                                src={img} />)
                                        },
                                    },
                                    { title: 'Product Name', field: 'storeDisplayName' },
                                    { title: 'Number', field: 'name' },
                                    { title: 'Weight (lbs)', field: 'packageSize' },
                                    {
                                        title: 'Container', field: 'containerType', render: rowData => {
                                            return getContainerName(rowData.containerType) 
                                        },
                                    },
                                ]}
                                data={list}
                                onRowClick={(event, rowData) => { props.history.push('/home/product/' + rowData.internalId, { data: rowData, isEdit: false }) }}
                                options={{
                                    filtering: false,
                                    actionsColumnIndex: -1,
                                    search: false,
                                    paging: true,
                                    toolbar: false,
                                    pageSize: 10,
                                    headerStyle: {
                                        backgroundColor: 'gray',
                                        color: '#FFF'
                                    },
                                }}
                                />
                            )
                    }
                </Col>
            </React.Fragment>
        );
    }

    function renderView(){
        if (firstView) {
            return renderTiles();
        } else {
            return renderRows();
        }
    }

    return (
        <Container>
            {accessProductCatalog(userInfo)}
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Product Catalog</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    <Row className="control-row">
                        <Col lg={4}>
                            <FormControl type="text" placeHolder="Search" className="search-input" value={searchValue} onChange={(eve) => { onSearch(eve) }} />
                        </Col>
                        <Col lg={3}>
                            <FormControl1 variant="outlined">
                                <InputLabel>Sort By</InputLabel>
                                <Select id="sortby" value={sortby.id} onChange={handleSearchDataChange('sortby')} label="sortby">
                                    {sortby.map((option) => (
                                        <MenuItem key={option.id} name={option.id} value={option.id}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl1>
                        </Col>
                        <Col lg={3}>
                            <FormControl1 variant="outlined">
                                <InputLabel>E-Store</InputLabel>
                                <Select id="webstore" value={webstore.id} onChange={onFilter('webstore')} label="E-Store">
                                    {webstore.map((option) => (
                                        <MenuItem key={option.id} name={option.id} value={option.id}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl1>
                        </Col>
                        <Col lg={2}>
                            <Tab.Container defaultActiveKey="first">
                                <Nav className="tabs product-catalog-view-tabs ml-auto">
                                    <Nav.Item>
                                        <Tooltip title="Grid view" placement="bottom"><Nav.Link onClick={setFirstViewHandler}><i className={`fa fa-th ${firstView ? "active" : ""}`} aria-hidden="true"></i></Nav.Link></Tooltip>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Tooltip title="List view" placement="bottom"><Nav.Link onClick={setSecondViewHandler}><i className={`fa fa-th-list ${secondView ? "active" : ""}`} aria-hidden="true"></i></Nav.Link></Tooltip>
                                    </Nav.Item>
                                </Nav>
                            </Tab.Container>
                        </Col>
                    </Row>
                    <Row>
                        {renderView()}
                    </Row>
                    {firstView == true &&
                    <Row>
                        <Col className="breadcrumbs-container">
                            <ReactPaginate
                                previousLabel={list.length ? "< Previous" : ''}
                                nextLabel={list.length ? "Next >" : ''}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageValue.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={(e) => { handlePageClick(e) }}
                                containerClassName={"breadcrumbs"}
                                subContainerClassName={"pages breadcrumbsinner"}
                                activeClassName={"active"}
                                forcePage={pageNumber}
                            />
                        </Col>
                    </Row>
                    }
                </Col>
            </Row>
        </Container>
    )
};

export default ProductCatalog;