import * as actions from './actions';
import { SUPPLIER_API, ADMIN_API } from '../../common/configs/path.config';
import { http } from '../../common/services/http.service';
import { showMessage } from './message-notification';

export const getMasterDataStart = () => {
	return {
		type: actions.GET_MASTERDATA_START,
	};
};

export const getMasterDataSuccess = (masterData) => {
	return {
		type: actions.GET_MASTERDATA_SUCCESS,
		masterData: masterData,
	};
};

export const getMasterDataFail = error => {
	return {
		type: actions.GET_MASTERDATA_FAIL,
		error: error,
	};
};

export const updateMasterDataStart = () => {
	return {
		type: actions.UPDATE_MASTERDATA_START,
	};
};

export const updateMasterDataSuccess = (masterData) => {
	return {
		type: actions.UPDATE_MASTERDATA_SUCCESS,		
		masterData: masterData
	};
};

export const updateMasterDataFail = error => {
	return {
		type: actions.UPDATE_MASTERDATA_FAIL,
		error: error,
	};
};

export const getMasterData = () => {
	return dispatch => {
		dispatch(getMasterDataStart());
		const url = SUPPLIER_API.GET_MASTERDATA;
		http
			.get(url)
			.then(response => {
				dispatch(getMasterDataSuccess(response));
			})
			.catch(err => {
				const errMsg = 'Something went wrong while retreiving master data';
				dispatch(getMasterDataFail(err?.response?.data?.error));
				dispatch(showMessage(err?.response?.data?.error || errMsg));
			});
	};
}

export const updateMasterData = () => {
	return dispatch => {
		dispatch(updateMasterDataStart());
		const url = ADMIN_API.UPDATE_MASTERDATA;
		http
			.post(url)
			.then(response => {
				dispatch(updateMasterDataSuccess(response));
			})
			.catch(err => {
				const errMsg = 'Something went wrong while updating master data';
				dispatch(updateMasterDataFail(err?.response?.data?.error));
				dispatch(showMessage(err?.response?.data?.error || errMsg));
			});
	};
}