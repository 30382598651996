import * as actions from './actions';
import { ADMIN_API } from '../../common/configs/path.config';
import { http } from '../../common/services/http.service';
import { showMessage } from './message-notification';

export const getPendingSupplierStart = () => {
	return {
		type: actions.GET_PENDING_SUPPLIER_START,
	};
};

export const getPendingSupplierSuccess = (pendingData) => {
	return {
		type: actions.GET_PENDING_SUPPLIER_SUCCESS,
		pendingSupplier: pendingData
	};
};

export const getPendingSupplierFail = error => {
	return {
		type: actions.GET_PENDING_SUPPLIER_FAIL,
		error: error,
	};
};

export const updatePendingSupplierStart = () => {
	return {
		type: actions.UPDATE_PENDING_SUPPLIER_START,
	};
};

export const updatePendingSupplierSuccess = () => {
	return {
		type: actions.UPDATE_PENDING_SUPPLIER_SUCCESS,
	};
};

export const updatePendingSupplierFail = error => {
	return {
		type: actions.UPDATE_PENDING_SUPPLIER_FAIL,
		error: error,
	};
};

export const getAllSupplierStart = () => {
	return {
		type: actions.GET_ALLSUPPLIERS_START,
	};
};

export const getAllSupplierSuccess = () => {
	return {
		type: actions.GET_ALLSUPPLIERS_SUCCESS,
	};
};

export const getAllSupplierFail = error => {
	return {
		type: actions.GET_ALLSUPPLIERS_FAIL,
		error: error,
	};
};

export const getAllProductRequestsStart = () => {
	return {
		type: actions.GET_ALL_PRODUCTS_REQUEST_START,
	};
};

export const getAllProductRequestsSuccess = (products) => {
	return {
		type: actions.GET_ALL_PRODUCTS_REQUEST_SUCCESS,
		allProducts: products
	};
};

export const getAllProductRequestsFail = error => {
	return {
		type: actions.GET_ALL_PRODUCTS_REQUEST_FAIL,
		error: error,
	};
};

export const getUserRoleStart = () => {
	return {
		type: actions.GET_USER_ROLE_START,
	};
};

export const getUserRoleSuccess = () => {
	return {
		type: actions.GET_USER_ROLE_SUCCESS,
	};
};

export const getUserRoleFail = error => {
	return {
		type: actions.GET_USER_ROLE_FAIL,
		error: error,
	};
};

export const updateEditAccountStart = () => {
	return {
		type: actions.EDIT_USER_ACCOUNT_START,
	};
};

export const updateEditAccountSuccess = () => {
	return {
		type: actions.EDIT_USER_ACCOUNT_SUCCESS,
	};
};

export const updateEditAccountFail = error => {
	return {
		type: actions.EDIT_USER_ACCOUNT_FAIL,
		error: error,
	};
};

export const deleteAccountStart = () => {
	return {
		type: actions.DELETE_USER_ACCOUNT_START,
	};
};

export const deleteAccountSuccess = () => {
	return {
		type: actions.DELETE_USER_ACCOUNT_SUCCESS,
	};
};

export const deleteAccountFail = error => {
	return {
		type: actions.DELETE_USER_ACCOUNT_FAIL,
		error: error,
	};
};

export const updateEditProductStart = () => {
	return {
		type: actions.EDIT_PRODUCT_START,
	};
};

export const updateEditProductSuccess = () => {
	return {
		type: actions.EDIT_PRODUCT_SUCCESS,
	};
};

export const updateEditProductFail = error => {
	return {
		type: actions.EDIT_PRODUCT_FAIL,
		error: error,
	};
};

export const getMasterDataStart = () => {
	return {
		type: actions.GET_MASTERDATA_START,
	};
};

export const getMasterDataSuccess = (masterData) => {
	return {
		type: actions.GET_MASTERDATA_SUCCESS,
		masterData: masterData.data.items,
	};
};

export const getMasterDataFail = error => {
	return {
		type: actions.GET_MASTERDATA_FAIL,
		error: error,
	};
};

export const updateMasterDataStart = () => {
	return {
		type: actions.UPDATE_MASTERDATA_START,
	};
};

export const updateMasterDataSuccess = (masterData) => {
	return {
		type: actions.UPDATE_MASTERDATA_SUCCESS,
		masterData: masterData.data.items,
	};
};

export const updateMasterDataFail = error => {
	return {
		type: actions.UPDATE_MASTERDATA_FAIL,
		error: error,
	};
};


export const getAllDocumentStart = () => {
	return {
		type: actions.GET_ALLDOCUMENT_START,
	};
};

export const getAllDocumentSuccess = () => {
	return {
		type: actions.GET_ALLDOCUMENT_SUCCESS,
	};
};

export const getAllDocumentFail = error => {
	return {
		type: actions.GET_ALLDOCUMENT_FAIL,
		error: error,
	};
};

export const getSupplierDocumentStart = () => {
	return {
		type: actions.GET_SUPPLIERDOCUMENT_START,
	};
};

export const getSupplierDocumentSuccess = () => {
	return {
		type: actions.GET_SUPPLIERDOCUMENT_SUCCESS,
	};
};

export const getSupplierDocumentFail = error => {
	return {
		type: actions.GET_SUPPLIERDOCUMENT_FAIL,
		error: error,
	};
};

export const getPDFDownloadByIDStart = () => {
	return {
		type: actions.GET_PDFDOCUMENT_START,
	};
};

export const getPDFDownloadByIDSuccess = () => {
	return {
		type: actions.GET_PDFDOCUMENT_SUCCESS,
	};
};

export const getPDFDownloadByIDFail = error => {
	return {
		type: actions.GET_PDFDOCUMENT_FAIL,
		error: error,
	};
};

export const getPendingSupplier = () => {
	const url = ADMIN_API.GET_PANDING_USER;
	return http.get(url);
}

export const updatePendingSupplier = (updatedDetails) => {
	const url = ADMIN_API.UPDATE_SUPPLIER;
	return http.put(url, updatedDetails);
};

export const getAllSupplierList = () => {
	const url = ADMIN_API.GET_ALLSUPPLER_LIST;
	return http.get(url);
}

export const getAllDocumentList = () => {
	const url = ADMIN_API.GET_ALL_DOCUMENTS;
	return http.get(url);
}

export const getSupplierDocumentList = (id) => {
	const url = ADMIN_API.GET_SUPPLIER_DOCUMENTS+ id;
	return http.get(url);
}

export const getAllProductRequests = () => {
	return dispatch => {
		dispatch(getAllProductRequestsStart());
		const url = ADMIN_API.GET_ALL_PRODUCTS_LIST;
		http
			.get(url)
			.then(response => {
				dispatch(getAllProductRequestsSuccess(response.data));
			})
			.catch(err => {
				const errMsg = 'Something went wrong';
				dispatch(getAllProductRequestsFail(err?.response?.data?.error));
				dispatch(showMessage(err?.response?.data?.error || errMsg));
			});
	};
}

export const getMasterData = () => {
	return dispatch => {
		dispatch(getMasterDataStart());
		const url = ADMIN_API.GET_MASTERDATA;
		http
			.get(url)
			.then(response => {
				dispatch(getMasterDataSuccess(response));
			})
			.catch(err => {
				const errMsg = 'Something went wrong';
				dispatch(getMasterDataFail(err?.response?.data?.error));
				dispatch(showMessage(err?.response?.data?.error || errMsg));
			});
	};
}

export const updateMasterData = () => {
	const url = ADMIN_API.UPDATE_MASTERDATA;
	return http.post(url);
};

export const getUserRole = () => {
	const url = ADMIN_API.GET_USER_ROLE;
	return http.get(url);
}

export const updateEditAccount = (updatedDetails) => {
	const url = ADMIN_API.EDIT_USER_ACCOUNT;
	return http.put(url, updatedDetails);
};

export const deleteAccount = (id) => {
	const url = ADMIN_API.DELETE_USER + id;
	return http.delete(url);
};

export const updateProduct = (updatedDetails) => {
	const url = ADMIN_API.UPDATE_PRODUCT;
	return http.put(url, updatedDetails);
};

export const pdfDocument = (id) => {
	const url = ADMIN_API.GET_PDF_DOCUMENTS + id;
	return http.get(url);
};