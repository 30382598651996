import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';
import { getProductRequests } from '../../store/actions/products';
import MaterialTable from 'material-table';
import { STATUS_PENDING } from '../../common/constants';
import { formatDate, accessTerritoryManagement } from './../../common/utils/utility';

const TerritoryManagement = (props) => {
    const userInfo = useSelector((state) => state.auth.userInfo);


    const addTerritory = () => {

    }



    return (
        <Container>
            {accessTerritoryManagement(userInfo)}
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Territory Management</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    {/*<ButtonCustom onClick={addTerritory}>Add Territory</ButtonCustom>
                    <ButtonCustom onClick={addTerritory}>Edit Territory</ButtonCustom>*/}
                </Col>
            </Row>
        </Container>
    )
};

const mapStateToProps = state => {
    return {
        userInfo: state.auth.userInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TerritoryManagement);